 
.GridViewbtn{
    position: relative !important;
    display: flex;
    bottom: 5px;
}
.DocExportBtn{
    position: absolute;
    right: 12px;
    top: 20px;
} 
.TblViewBtn{
    font-size: 10px !important;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #f1f1f1;
    color: #ccc !important;
    cursor: pointer;
} 
.TblViewAct{
    background-color: #fff !important;
    color: #34ace0 !important;
} 
.DocStoreTable .ant-table{
    height: 35vh !important;
}
.DocStoreTableV .ant-table{
    height: 38vh !important;
}
.DocViewTable{
    margin-top: -20px ;
}
/* Dov View Styles */

.DocviewCard{
    background: #f0f5f5 !important;
} 
.highLight{
    color: #34ace0 !important;
} 
.DocViewHead{
    font-size: 0.75rem;
}
.DocViewHead span{
    float: right;
}
.DocViewBody{
    font-size: 0.75rem;
    font-weight: bold;
}
.pTag{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.DocGridName{
    font-size: 0.688rem;
    font-weight: bold;
    margin: 0px;
    height: 40px;
    /* padding-top: 15px; */
}
.DocGridCnt{
    color: #337ab7 !important;
    cursor: pointer !important;
    font-size: 2.188rem !important;
    margin: 0px;
    line-height: 60px;
}
.DocCircleDiv{
    text-align: -webkit-center;
}  