.upglist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
}
.uvlist{
    width: 7rem;
    height: 5rem;
    display: flex;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
}
.inuvlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #82CAFA;
}
.font13{
    font-size: 0.8rem;
}
.ptext{
    color:#82CAFA;
    font-size: 14px;
    font-weight: 600;
}
.spantxt{
    color:#777;
    font-size: 14px;
    font-weight: 600;
    margin-left: 1rem;
}
.whitecolor{
    color: #fff !important;
}
.whitecolor > .MuiIconButton-label {
    color: #fff !important;
}
.umlist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}
/* card */
 
.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #82CAFA;;
    border-radius: 0 4px 0 32px;
}
.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}
.card1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #82CAFA;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.card1:hover:before {
    transform: scale(21);
}
.card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.card1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
/* module */
.inmlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #AF7AC5;
}
.cardm1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.cardm1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #AF7AC5;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardm1:hover:before {
    transform: scale(21);
}
.cardm1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.cardm1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.cardm1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
.gom1-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #AF7AC5;;
    border-radius: 0 4px 0 32px;
}
/* sec module */
.usbmlist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}
.insbmlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #2ECC71;
}
.cardsbm1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.cardsbm1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #2ECC71;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardsbm1:hover:before {
    transform: scale(21);
}
.cardsbm1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.cardsbm1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.cardsbm1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
.gosbm1-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #2ECC71;;
    border-radius: 0 4px 0 32px;
}
/* third module */
.utmlist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}
.intmlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #DE3163;
}
.cardtm1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.cardtm1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #DE3163;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardtm1:hover:before {
    transform: scale(21);
}
.cardtm1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.cardtm1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.cardtm1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
.gotm1-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #DE3163;
    border-radius: 0 4px 0 32px;
}
.modArrowbtn{
    text-align: right;
    padding: 5px;
}
.umodDivBody {
    padding: 0 0 0 0px;
    height: 63vh;
    
}
.grplistActive{
background: #82CAFA;
color: #fff;
}
.modliActive{
    background: #AF7AC5;
    color: #fff;
}
.submodliAct{
    background: #2ECC71;
    color: #fff;
}
.thirdmodliAct{
    background: #DE3163;
    color: #fff;
}
.width100{
    width: 100%;
}
.floatwid{
    float: right;
    width: 50% !important;
}
.popdivm{
    width: 9rem;
    display: flex;
}
.font13p{
    font-size: 13px;
    line-height: 2.8;
}
.popdivm:hover{
    background-color:#e8e8e8;
 }
 .popElem{
     padding: 0.6rem;
 }
 .menupopdes > .MuiPaper-elevation8 {
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149) !important;
    background-color: #fff;
    border-radius: 8px !important;
 }