.dashcardb {
  padding: 1%;
  height: 85vh;
  display: flex;
  align-items: center;
}
.dgridcont {
            margin: 0 !important;
            width: 100% !important;
            padding: 0% 10% !important;
}
.dgridI {
            padding: '1% 2% 1% 0%' !important;
}
.dgridIsub1 {
            padding: '0% 1% 1%' !important;
}
.dgridIsub2 {
            padding: '1% 1% 0%' !important;
}