.L2DropDownDivOpen{
  border-radius: 10px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: rgba(223,225,229,0) !important;
  box-shadow: 0 1px 6px rgba(32,33,36,.28) !important;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.dropicon{
  float: right;
  color: #777;
  /* position: relative; */
  top: -30px;
}
.dclosePadd {
  padding-right: 2.5rem !important;
}
.dcloseright {
  right: 1.5rem !important;
}
.L2DropDownDiv{
  background: #fff;
  display: flex;
  border: none;
  box-shadow: none;
  height: 35px;
  /* width: 690px; */
  /* border-radius: 10px; */
  border-bottom: 2px solid #4892F9;
  z-index: 3;
  height: 40px;
  margin: 0 auto;
  margin-top: 5px;
}
.L2DropDownInnerDiv{
  flex: 1;
  display: flex;
  padding: 12px 4px 0 2px;
}
.L2DropDownDivOpen .L2DropDownInnerDiv{
  padding-left: 10px;
}
.L2DropDownInputDiv{
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.L2DropDownInputInnerDiv{
  color: transparent;
  flex: 100%;
  white-space: pre;
  height: 34px;
  font: 16px arial,sans-serif;
  color: rgba(0,0,0,.87);
  line-height: 34px;
}
.L2DropDownInputText{
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.87);
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100%;
  -webkit-tap-highlight-color: transparent;
  margin-top: -37px;
  height: 34px;
  font-size: 0.75rem;

  font: 12px arial,sans-serif;    
  line-height: 34px;    
  
}
.L2DropDownList{
  width: 100%;
  text-align: left;
  margin-top: -1px;
  z-index: 989;
  cursor: default;
}

@media screen and (min-width:960px) {
  .L2DropDownList{
    position: absolute;
    width: 40%;
  }
}
.L2DropDownListDiv{ 

  background: #fff;
  box-shadow: 0 4px 6px rgba(32,33,36,.28);
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 0; 
  padding-bottom: 4px;
  overflow: hidden;
}
.L2DropDownListBorder{
  border-top: 1px solid #e8eaed;
  margin: 0 14px;
  padding-bottom: 4px;
}
.L2DropDownListUL{
  flex: auto;
  padding: 0px;
}
.L2DropDownListUL li{
  border-bottom: 1px solid #e3e4e9;
}
.L2DropDownListLI{
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  padding: 0;
}
.L2DropDownListLIDiv{
  flex: auto;
  display: flex;
  font-size: 0.88rem;
  margin: 0 20px;
  align-items: center;
  margin: 0 20px 0 14px;
}
.FilterClearIcon{
  font-size: 12px !important;
  color: red;
  cursor: pointer;
  /* position: absolute; */
  right: 10px;
  top: 10px;
  /* margin: 5px 8px 0 0 ; */
}

.L2DropDownInputClearDiv{
  position: absolute;
  right: 0px;
  top: 5px;
}
.loginInputClearDiv{
  position: absolute;
  right: 5px;
  top: 10px;
}
/* Password Visible icon */
.passwordVisibleIcon{
  position: absolute;
  top: 25%;
  right: 7%;
  font-size: 20px !important;
  color: #4892F9;
  cursor: pointer;
}

/* Floating Css */
.floating-label { 
  position:relative; 
  /* margin-bottom: 2%;     */
}
.floating-input , .floating-select {
  font-size:0.73rem;
  padding:0px 2px;
  display:block;
  width:100%;
  height:35px;
  background-color: transparent;
  border:none;
  padding-right: 18px;
  border-bottom: 2px solid #337ab7;
}
.floating-input-password{
  padding-right: 20% !important; 
}
.errorinput{
  border-bottom: 2px solid red !important;
}
.successinput{
  border-bottom: 2px solid #337ab7 !important;
}
.logininput{
  border-bottom: 0;
  box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
  padding: 4%;
  /* border-left: 3px solid #33234E !important; */

}
.logininput:focus{
  border-bottom: 0px solid #fff !important; 
  border-left: 3px solid #33234E !important;
}
.loginactive {
  border-left: 3px solid #33234E !important;
}
/* .logininputborder{
  border-left: 3px solid #33234E !important;

} */

.loginlabel{
  top:10px !important;
  left:15px !important;
  text-align: left;
}

.logininput:focus ~ label, .logininput:not(:placeholder-shown) ~ label {
  top:-15px !important;
  font-size:10px;
  color:#33234E !important; 
}

.floating-input:focus , .floating-select:focus {
  outline:none;
  border-bottom:2px solid #337ab7; 
}
.float-label {
  color:#999; 
  font-size:12px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:1px;
  top:1rem;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}
.flexend{
  display: flex;
    align-items: flex-end;
}

.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:-10px;
  font-size:10px;
  color:#337ab7;
  
}

.floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
  top:-18px;
  font-size:14px;
  color:#5264AE;
}

.floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
  width:50%;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/* .hidetext { -webkit-text-security: disc; } */
.dropdownCreateNew{
  color: #fff;
  text-decoration: underline;
  font-size: 0.625rem;
  cursor: pointer;
  margin-top: 2px;
}

/* Accordian in menu */
.foreignlableL {
  text-align: left;
  width: 50%;
  float: left;
  font-size: 0.7rem;
}

.foreignlableR {
text-align: end;
width: 50%;
float: right;
font-size: 0.7rem;
}

.foreignval:hover {
  text-decoration: underline;
  color:orange;
  cursor: pointer
}

.tooltitle {
position: relative;
display: inline-block;
}

.tooltitle:hover .tooltiptext {
visibility: visible;
opacity: 1;
}

.tooltitle .tooltiptext {
visibility: hidden;
background-color: #5f5d5de0;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 8px;
position: absolute;
z-index: 1;
opacity: 0;
transition: opacity 0.3s;
}

.tooltitle .tooltip-top::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #5f5d5de0 transparent transparent transparent;
}

.tooltip-right::after {
content: "";
position: absolute;
top: 50%;
left: 100%;
margin-top: -5px;
border-width: 5px;
border-style: solid;
border-color: transparent transparent transparent #555;
}

.tooltip-top {
bottom: 125%;
left: 50%;
margin-left: -30px;
}
.tooltip-right {
top: -5px;
bottom: auto;
right: 128%;
width: max-content;
}

/* Detail Link */
.foreignlableL {
  text-align: left;
  width: 50%;
  float: left;
  font-size: 0.7rem;
}

.foreignlableR {
text-align: end;
width: 50%;
float: right;
font-size: 0.7rem;
}

.foreignval:hover {
  text-decoration: underline;
  color:orange;
  cursor: pointer
}

/* AED */
.pretext{
  font-weight: 700;
    color: red;
    position: absolute;
    top: 0.15rem;
    font-size: 12px;
}
.paddingcurr{
  padding-left: 2rem;
}
.paddingcurrLeft{
  padding-left: 25px;
}