#floatButton{
    position: fixed;
    right: 5px;
    top: 50%;
    transition: 0.4s;
    z-index: 9999;
    cursor: pointer; 
} 
#ThemeSlide{
    width: 0px;
    cursor: pointer;
    height: 91%; 
    margin-top: 25px;
    position: fixed; 
    z-index: 100;
    top: 28px;
    right: 0px;
    background: #fff;
    overflow-x: hidden;
    transition: 0.4s;
    border-left: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
}

.Theme-Heading{
    text-align: left;
}
.Theme-Heading h4{
    margin-top: 20px !important;
    margin:5px;
    font-weight: 600;
    color: #727E8C;
}
.Theme-card-shadow{
    display: flex;
}
.Theme-card-shadow-switch{
    margin-top:10px    
}
.color-box-div{
    display: flex;
    margin: 10px;
}
.color-box{ 
    height: 35px;
    width: 35px;
    margin: .5rem;
    border-radius: .5rem;
    cursor: pointer;
}
.color-box-blue{
    background: #5A8DEE !important;
}
.color-box-green{
    background: #39DA8A !important;
}
.color-box-red{
    background: #FF5B5C !important;
}
.color-box-skyblue{
    background: #00CFDD !important;
}
.color-box-orange{
    background: #272E48 !important;
}
.color-box-dark{
    background: #fff !important;
    border: 1px solid #727E8C;
}
.Act-clr-btn{
    box-shadow: 0 0 0 3px rgba(52,144,220,.5);
}  