.maintenance_container {
  background-color: #F8F4FB;
  padding: 10px 75px;
}

.maintenance_body_title img {
  height: 50px;
  width: 154px;
}

.logo_desc {
  display: initial;
}

.logo_mobile {
  display: none;
}

.maintenance_body_title {
  max-width: 522px;
  align-self: center;
}

.maintenance_body h1 {
  color: #340B54;
  line-height: 58px;
  letter-spacing: 0.4px !important;
  font-size: 56px;
  font-family: 'Lato';
  font-weight: 700;
  margin-bottom: 0px;
}

.maintenance_body h3 {
  color: #9A85A9;
  line-height: initial;
  letter-spacing: 0.2px !important;
  font-size: 28px;
  font-family: 'Lato';
  font-weight: 400;
}

.maintenance_body_title .MuiGrid-root {
  margin-bottom: 21px;
}

.maintenance_logo {
  max-width: 100%;
  max-height: 600px;
}

.maintenance_logo_link, .maintenance_logo_link a {
  color: #9A85A9;
  text-align: right;
  line-height: 12.1px;
  font-size: 10px;
  font-family: 'Inter';
  font-weight: 400;
}

@media (max-width: 600px) {
  .maintenance_container {
    background-color: #F8F4FB;
    padding: 42px 24px 0px;
  }

  .maintenance_body_title img {
    height: 57px;
    width: 174px;
  }

  .app_logo {
    display: flex;
    justify-content: center;
    margin-bottom: 51.5px;
  }

  .logo_desc {
    display: none;
  }

  .logo_mobile {
    display: initial;
  }

  .maintenance_body {
    max-height: 90%;
    max-width: 342px;
  }

  .maintenance_body_title {
    display: flex;
    justify-content: center;
  }

  .maintenance_body_title .MuiGrid-root {
    margin-bottom: 14px;
  }

  .maintenance_body h1 {
    text-align: center;
    line-height: 34px;
    letter-spacing: 0.2px !important;
    font-size: 32px;
  }

  .maintenance_body h3 {
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.1px !important;
    font-size: 14px;
    font-weight: 700;
  }

  .maintenance_logo {
    max-width: 328px;
    max-height: 328px;
    margin-top: 10px;
  }

  .maintenance_logo_link, .maintenance_logo_link a {
    text-align: center;
    margin-bottom: 6px !important;
  }
}