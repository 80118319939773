@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600);
 a {
	 text-decoration: none;
	 transition: all 0.6s ease;
}
 a:hover {
	 transition: all 0.6s ease;
}
 .menuwid {
	 width: 95% !important;
}
 .app {
	 height: 100vh;
}
/* ------------- Sidebar ----------------*/
 .sidebar {
	 position: fixed;
	 width: 5%;
	 height: 100%;
	 top: 0%;
	 margin-top: 52px;
	 left: 0px;
	 overflow: hidden;
	 background-color: #251E8F;
	 -webkit-transform: translateZ(0);
	 visibility: visible;
	 -webkit-backface-visibility: hidden;
}
 .sidebar header {
	 background-color: #09f;
	 width: 100%;
	 display: block;
	 padding: 0.75em 1em;
}
 .sidebar ::-webkit-scrollbar-thumb {
	 background: #fff;
}
 .sidebar ::-webkit-scrollbar-thumb:hover {
	 background: #fff;
}
 .sidebar ::-webkit-scrollbar {
	 width: 0px;
}
 ::-webkit-scrollbar {
	 width: 2px;
}
/* ------------- Sidebar Nav ----------------*/
 .sidebar-nav:hover {
	 overflow: auto;
	 overflow-x: hidden;
}
 .sidebar-nav {
	 position: fixed;
	 background-color: #251E8F;
	 height: 90%;
	 width: 100%;
	 font-weight: 400;
	 font-size: 1.2em;
	 padding-bottom: 0;
	 z-index: 9;
	 overflow: hidden;
	 -webkit-overflow-scrolling: touch;
	/* --------------- active cls ------------------- */
	/* ------------- Chev elements ----------------*/
	/* ------------- Nav-Flyout ----------------*/
}
 .sidebar-nav ul {
	 list-style: none;
	 display: block;
	 padding: 0;
	 margin: 0;
}
 .sidebar-nav ul li {
	 margin-left: 3%;
	 padding-left: 0;
	 min-width: 13em;
	 display: inline-block;
	 width: 100%;
	/* ------------- Sidebar: icons ----------------*/
}
 .sidebar-nav ul li a {
	 color: #333;
	 font-size: 0.75em;
	 padding: 1.05em 1em;
	 position: relative;
	 display: block;
}
 .sidebar-nav ul li a:hover {
	 color: #444;
	 background: rgba(80, 80, 80, 0.05);
	 transition: all 0.6s ease;
}
 .sidebar-nav ul li i {
	 font-size: 1.8em;
	 padding-right: 0.5em;
	 width: 9em;
	 display: inline;
	 vertical-align: middle;
}
 .sidebar-nav .active {
	 background-color: #09f;
	 transition: all 0.6s ease;
}
 .sidebar-nav > ul > li > a:after {
	 content: '\f125';
	 font-family: ionicons;
	 font-size: 0.5em;
	 width: 10px;
	 color: #000;
	 position: absolute;
	 right: 0.75em;
	 top: 45%;
}
 .sidebar-nav .nav-flyout {
	 position: absolute;
	 background-color: #fff;
	 z-index: 9;
	 left: 3.1em;
	 top: 0;
	 height: 100vh;
	 transform: translateX(100%);
	 transition: all 0.5s ease;
	 /* box-shadow: 0 4px 11px 1px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.08); */
	 overflow: scroll;
	 border-left: 0px solid #e3e4e9;
}
@media screen and (max-width:1280px) {
	.sidebar-nav .nav-flyout{
		left: 2.05em;
	}
}
 /* .sidebar-nav .nav-flyout a:hover {
	 background-color: rgba(255, 255, 255, 0.05);
} */
 .ulist-padding {
	 width: 84%;
	 margin-left: 12px !important;
	 padding-left: 0px !important;
	 padding-right: 0px !important;
	 padding-bottom: 64px !important;
}
 .subitem-list {
	 color: #444;
	 display: block;
	 font-size: 0.75rem;
	 margin-top: 0px;
	 font-weight: 600;
	 line-height: 28px;
	 padding-left: 0;
	 font-family: Maven Pro, sans-serif;
	 text-transform: uppercase;
}
@media (max-width: 1200px){
	.subitem-list {
		font-size: 8px;
	}
}
 .MenuExpandicon {
	 color: #444;
}
 .Accmenucontent:hover {
	 background: #337ab7;
}
 .Accmenucontent:hover .MenuExpandicon {
	 color: #fff;
}
 .Accmenucontent:hover .subitem-list {
	 color: #fff;
}
 .Accmenucontent:hover .Acciconsm {
	 color: #fff;
}
 .Accmenucontent {
	 display: flex;
	 width: 100%;
	 padding: 8px;
}
 .clsactive {
	 color: #09f;
}
.tgactive {
	border-left: 5px solid #251E8F !important;
}
.tgactiveSmall {
	background: #fff;
	border-top-left-radius: 10px !important;
   border-bottom-left-radius: 10px !important;
}
.tgactiveSmall .iconpad {
	color: #251E8F;
}
 .ripple {
	 background-position: center;
	 transition: background 0.8s;
}
 .subfontSty {
	 font-size: 0.75rem;
	 font-weight: 400;
	 line-height: 1.83;
	 padding-left: 2%;
	 width: fit-content;
	 max-width: 75%;
}
 .padd5 {
	 display: flex;
	 color: #337ab7;
	 margin: 0rem 0rem 0rem 0rem !important;
	 margin-left:-22px !important;  /*rayan*/
	 padding: 5% 5% 3% 5% !important;
	 border-radius: 0px;
	 height: fit-content;
	 border-bottom: 1px solid #e3e4e9;
}
 .iconsm {
	 font-size: 14px !important;
	 width: 1em;
	 height: 1em;
	 margin: 3px 5px 0px 15px !important;
}
 .iconsmdrop {
	 font-size: 14px !important;
	 width: 1em;
	 height: 1em;
	 margin: 3px 8px 0px 8px !important;
	 color: #555;
}
 .btnDiv {
	 width: 100%;
	 display: flex;
	 padding: 8px;
	 text-align: center;
	 border-radius: 0px;
	 margin-bottom: 0px;
	 flex-direction: column;
	 cursor: pointer;
	 border-left: 5px solid #251E8F;
	 /* border-bottom: 1px solid #e4e4e4; */
}
 .btnDiv:hover {
	 border-left: 5px solid #251E8F;
	 background: rgba(80, 80, 80, 0.05) radial-gradient(circle, transparent 1%, rgba(80, 80, 80, 0.05) 1%) center/15000%;
	/* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	*/
}
 .btnDiv:hover .labeltxt, .btnDiv:hover .iconpad {
	 color: #251E8F;
}
 .Divbtnlvl {
	 width: 100%;
	 display: flex;
	 padding: 5px 10px 0px 3px;
	 text-align: center;
	 border-radius: 0px;
	 margin-bottom: 5px;
	 border-left: 0px solid #fff;
}
 .homeicon {
	 width: 30px !important;
	 height: 30px !important;
	 margin-top: -8px !important;
	 box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	 border: 1px solid #ebecf1;
	 border-radius: 50px;
	 padding: 6px;
	 margin-left: 0;
}
 .labeltxt {
	 width: 100%;
	 display: block;
	 overflow: hidden;
	 font-size: 0.65rem;
	 font-family: sans-serif;
	 -webkit-font-smoothing: antialiased;
	 line-height: 1.5;
	 color: #fff;
	 font-weight: 400;
}
 .iconspan {
	/*box-shadow: 10px -10px 5px rgba(0,0,0,0.6);
	 -moz-box-shadow: 10px -10px 5px rgba(0,0,0,0.6);
	 -webkit-box-shadow: -1px 2px 3px 0px rgba(0,0,0,0.6);
	 -o-box-shadow: 10px -10px 5px rgba(0,0,0,0.6);
	*/
	 border-radius: 25px;
	 /* border: solid 1px #e3e4e9; */
	 width: 40px !important;
	 height: 40px !important;
	 display: inline-block;
	 margin-bottom: 5px;
	 /* box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1); */
	/*box-shadow: 0px 0px 5px -5px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	*/
}
@media screen and (max-width:1280px) {
	.iconspan{
		width: 30px !important;
		height: 30px !important;
	}
}
 .iconpad {
	 color: #fff;
	/* display: block;
	 / / font-size: 1.7rem !important;
	 */
	 margin: 0;
	 margin-bottom: 0;
	 padding-top: 0;
	 line-height: 1.5;
	 height: 2.3rem !important;
}
/* Ripple effect */
 .ripple {
	 background-position: center;
	 transition: background 0.8s;
}
 /* .ripple:hover .iconspan {
	 border: solid 1px #337ab7;
} */
 .ripple:hover {
	background: #fff;
	border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
	/* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	*/
}
 .rippleSmall {
	 background-position: center;
	 transition: background 0.4s;
}
 .rippleSmall:hover {
	background-color: #fff;
	cursor: pointer;
	border-left: 5px solid #251E8F;
	border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
	/*background: rgba(80,80,80, 0.05) radial-gradient(circle, transparent 1%, rgba(80,80,80, 0.05) 1%) center/15000%;
	*/
	/* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	*/
}
/*.rippleSmall:active {
	 background-color: #bdbdbd;
	 background-size: 100%;
	 transition: background 0s;
}
*/
 .rippleSmall:hover .labeltxt, .rippleSmall:hover .iconpad {
	 color: #251E8F;
}
 /* .rippleSmall:hover .iconspan {
	 border: solid 1px #337ab7;
} */
/*.Divbtnlvl:before{
	 top: 0;
	 left: -8px;
	 width: 12px;
	 height: 35px;
	 z-index: 10;
	 content: "";
	 position: absolute;
	 background: #09f;
	 border-radius: 5px;
}
*/
/*.tagripple:hover {
	 background: rgba(80,80,80, 0.05) radial-gradient(circle, transparent 1%, rgba(80,80,80, 0.05) 1%) center/15000%;
}
*/
 .tagripple:active {
	 background-color: #bdbdbd;
	 background-size: 100%;
	 transition: background 0s;
}
 .sidebar-nav .nav-flyout a:hover {
	 color: #000;
	 background-color: #337ab7;
}
 li {
	 list-style-type: none;
}
 /* .menuSearch {
	 margin-top: 8px;
	 width: 50%;
	 border: 1px solid #ebecf1;
	 border-radius: 5px;
	 /* display: flex */
/* }
.menuSearch > div {
	display: flex
}
 .menuSearch div {
	 box-shadow: none;
	 background: #eee;
}  */
.ComSearchIcon{
	width: 35px !important;
	cursor: pointer;
   	color: #000 !important;
	/* padding-top: 4px; */
	border-right: 1px solid;
	height: 32px !important;
	padding: 5px;
}
 .MuiIconButton-edgeEnd {
	 margin-right: 8px !important;
}
 .Acciconsm {
	 font-size: 14px !important;
	 width: 1em;
	 height: 1em;
	 margin: 6px 8px 0px 8px !important;
	 color: #444;
}
 .Menuopenall {
	 font-size: 0.6rem;
	 color: #337ab7;
	 margin-top: 1%;
	 position: relative;
	 margin-left: auto;
	 margin-right: 10%;
}
@media (max-width: 1200px){
	.Menuopenall {
		margin-right: 10%;
	}
}
 