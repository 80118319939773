* {box-sizing: border-box;}
ul {list-style-type: none;}
body {font-family: Verdana, sans-serif;}

.month {
  padding: 70px 25px;
  width: 100%;
  background: #1abc9c;
  text-align: center;
}
.tagenvents {
  margin-top: 4px;
  position: absolute;
  text-align: start;
  width: 13%;
}
.monthEventTag{
  background-color: rgb(3, 155, 229);
  color: white;
  border-radius: 4px;
  padding: 2px 2px 2px 7px;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: auto;
    
}
.moreEventTag{
  background-color: white;
  color: #3c4043;
  border-radius: 4px;
  padding: 2px 2px 2px 7px;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: auto;
}
.monthEventTag:hover {
  /* background-color: rgba(32,33,36,0.122); */
}

.moreEventTag:hover {
    background-color: #f1f3f4;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}
.current-month{
  color:#3c4043 !important;
}
.T7dzVe {
  background-color: rgb(3, 155, 229);
  -webkit-border-radius: 4px;
  /* border-radius: 4px; */
  height: 14px;
  width: 14px;
  margin-left: 3px;
  margin-top: 3px;
}
.month ul {
  margin: 0;
  padding: 0;
}

.month ul li {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.month .prev {
  float: left;
  padding-top: 10px;
}

.month .next {
  float: right;
  padding-top: 10px;
}

.weekdays {
  margin: 0;
  padding: 10px 0;
  background-color: #ddd;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: #666;
  text-align: center;
}
.dayheader{
    border-right: 1px solid #ccc;
    font-size: 15px;
    line-height: 1.6;
}

.days {
  padding: 0px 0;
  /* background: #eee;
  
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  */
  margin: 0;
}

.days li {
  list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: -3px;
    font-size: 12px;
    color: #777;
    font-weight: bold;
}

.days li .active {
  padding: 5px;
  background: #1abc9c;
  color: white !important
}
.liborder{
  height: 16vh;
  /* border: 1px solid rgb(206, 201, 201); */
  border-bottom: 1px solid rgb(206, 201, 201);
  border-right: 1px solid rgb(206, 201, 201);
}
.daynamePop{
    font-size: 12px;
    font-family: sans-serif;
    margin-left: 15px;
    text-transform: uppercase;
}
.popDate{
  text-align: center;
    padding: 1rem 0rem;
    font-size: 23px;
    color: #3c4043;
}

/* Add media queries for smaller screens */
@media screen and (max-width:720px) {
  .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
  .weekdays li, .days li {width: 12.5%;}
  .days li .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
  .weekdays li, .days li {width: 12.2%;}
}

/* year view calendar css */

.contentyear {
  width: 256px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.cont{
  flex: 1;
  flex-basis: 20%;
  flex-direction: column;
}
.spantag{
  -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding-left: 5px;
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .25px;
    line-height: 20px;
    color: #3c4043;
}
.divsplit{
  display: flex;
  padding-top: 8px;
}
.divpadd {
  padding: 16px 14px 16px 19px;
}
.yearcalendars {
  padding: 0px 0;
  /* background: #eee; */
  margin: 0;
}
.yearcalendars li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;
  color: #777;
}