.newBtn{
    position:relative;
    margin-top: -15px;
    margin-right: 10px;
    padding: 0px;
}
.menuImg{
    height: 50px !important;
    width: 100% !important;
}
.tabcontain{
    width: 100%;
    height: 100%;
}
.tabIcons{
    float: left;
    padding: 2px 5px 0px 0px;
    /* top: 13px;
    position: relative;
    display: block; */
}
.tabcontent{
    position: relative;
    top: 4px;
}
.tabActive{
    font-size: 0.81rem;
    background-color: #337ab7!important;
    opacity: 1!important;
    color: #fff !important;
    font-weight: 400;
    z-index: 1!important;
    /* box-shadow: 2px -2px 5px -5px #333!important; */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 1px solid #337ab7 !important;
    border-right: 1px solid #337ab7 !important;
    border-left: 1px solid #337ab7 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    /* height: 35px !important; */
    /* border-bottom: 3px solid #ffffff !important; */
    font-weight: 600 !important;
}

.tabActive .closeBtn{
    color: #fff
}

.profile{
    margin-top: 5px;
}
/* h6.Profile-Name::first-letter{
     color: #1BACC2 !important;  
    font-size: 10%;
} */
.Profile-Name{
    margin: 0 0 0px;    
}
#user-name{
    font-size: 0.75rem;
    font-weight: 600;
    color:#337ab7 !important;
}
#welcome{
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.5;
    color:#337ab7 !important;
}
.dropdownicon{
    font-size: 1.1rem !important;
    margin-right: 0.6rem;
}
.horizontalNav{
    right:0;
    background: #fff;
    position: fixed;
    width: 95%;
    /* height: 8%; */
    z-index: 12;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
}
.listButton{
    margin-left: 15px;
    width: 40px;
    color:black;
    box-shadow:none !important;
    
}
.sidebarMiniIcon{
    width: 30px !important;
    height: 30px !important; 
    margin-top: -8px !important;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    border: 1px solid #ebecf1;
    border-radius: 50px;
    padding: 6px;
    margin-left: 0;
}
.sidenavUL{
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    border-right: 1px solid #eeeeee;
    color: black;
    width: 9% ;
    top: 52px;
    bottom: 0;
    position: fixed;
    z-index: 10;
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0px;
    overflow: hidden;
    background: #fff;
    transition: 0s;
    box-shadow: 0 0px 0px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
}
.subMenu{
    background: #fff;
    opacity: 1; 
    color: black;   
    position: fixed;
    z-index: 99;  
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    /* height: 250px !important; */
    /* overflow-y: scroll;
    overflow-x: hidden; */
}
.subDivli{    
    border-radius: 6px;
    list-style-type: none; 
    color: black;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-size: 0.81rem;
    cursor: pointer;
    padding: 12px 24px;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;
    cursor: pointer;
} 
.subDivli:hover{
    background: #eee !important;
    color: #34ace0;
} 
.subDivli:hover .SubMenuIcon{
    background: #eee !important;
    color: #34ace0;
} 
.arrow{
    float: right;
    font-size: 17px !important;
    /* position: absolute;
    right: 5px; */
    /* margin-top: 4; */
}
.expandNav{
    position: absolute;
    right: 0px;
    /* height: 100%; */
    width: 95%;
    transition: 0s;
}
.sidenavLI{ 
    cursor:pointer;    
    height:auto !important;
    padding:10%;
    border-bottom:1px solid #dee2e6;
}
.sidenavSpan{
    text-align: center;
    font-size: 0.81rem;
    display: block;
    font-weight: 400;
}
.sidenavSpan :hover{
    background-color: transparent !important;
}
.MenuIcon{
    color: black;
    font-size: 40px !important;
    height: 32px !important;
    width: 32px !important;
    display: block !important;
    margin: 0 auto 6px !important;
}
.SubMenuIcon{
    color: #337ab7;
    font-size: 0.88rem !important;
    margin-top: 6px;
    /* display: block !important;
    margin-right: 15px ;
    margin-top: 1px; */
}
/* .spanTab .SubMenuIcon{
    margin-right: 0px !important;
} */
.tabActive .SubMenuIcon {
    color: #fff;
}   
.liDiv{ 
    /* padding-top: 20px; */    
    /* padding-bottom: 20px !important; */
    margin: 8px 0px 0px;
    border-radius: 6; 
    position: relative;
}
/* .liDiv :hover{
    color: #34ace0 !important;
    background-color: rgba(200, 200, 200, 0.2);
    cursor: pointer;  
}    */
  
.sidenavUL :hover
.MenuIcon{     
    color: #34ace0; 
}
.sidenavUL :hover
.sidenavSpan{     
    color: #34ace0; 
}
.active .MenuIcon{
    color: #fff !important;
} 
.active .sidenavSpan{
    color: #fff !important;
}
.sidenavLI .active {
    background-color: #34ace0 !important;
    border-radius: 4px !important;
}
.active{
    color: white; 
    margin-bottom: 0px;
    box-shadow: none !important;
    user-select:none;
    /* box-shadow: 0 12px 20px -10px rgba(119, 136, 138, 0.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(104, 112, 113, 0.2) !important; */
} 
.active
.triangle{
    position: absolute;
    left: 100%;
    top: 30%;
    border-color: transparent #34ace0;
	border-style: solid;
	border-width: 10px 10px 10px 0px;
	height: 0px;
	width: 0px;
}
.subActive{
    color: #34ace0 !important;
    /* box-shadow: 0 12px 20px -10px rgba(119, 136, 138, 0.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(104, 112, 113, 0.2) !important; */
    background-color: #fff !important;
} 

.subActive .SubMenuIcon{
    color: #34ace0 !important; 
} 
.subActive2{
    color: #34ace0 !important;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    background-color: #fff !important;
} 
    
.subActive3{
    color: #34ace0 !important;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    background-color: #fff !important;
}  
.subMenu2{
    background: #fff;
    opacity: 1;
    width: 200;
    color: black;   
    position: fixed;
    z-index: 99;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);

}
.subMenu3{
    background: #fff;
    opacity: 1;
    width: 200;
    color: black;   
    position: fixed;
    z-index: 99;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
}
.closeBtn{
    color: #d00e0e;
    padding-left: 0px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 6px;
    font-size: 16px !important;
}
.liTabs:hover .closeBtn{
    /* background: #ccd3d6; */
    border-radius: 50%;
}

.liTabs{
    font-size: 0.81rem; /*.75rem;*/
    opacity: 1;
    border-radius: 0;
    cursor: pointer;
    padding: 0.2rem 0.35rem 0px;
    border: none;
    height: 35px;
    margin: 0.45rem 0.25rem 0.5rem 0;
    list-style-type: none;
    background-color: #fff; 
    font-weight: 400;
    min-width: 11%;
    /* border-top-left-radius: 5% !important; /
    / border-top-right-radius: 5% !important; /
    / border-bottom-right-radius: 5% !important; /
    / border-bottom-left-radius: 5% !important; */
    border-radius: 6px;
    box-shadow: 0 2px 0px 0 rgba(0,0,0,.14), 0 3px 0px -2px rgba(0,0,0,.2), 0 1px 0px 0 rgba(0,0,0,.12);
    color: #337ab7;
    } 




/* New style changes */
/* .menu-search{
    margin-top: 5px !important;
} */
.menuContent{
    padding: 0% 1%;
    margin-top: 56px;
    height: 92%;
}

.Logomenu{
    width: 68px;
    padding: 7px 14px;
    background: #251E8F;
    height: 52px;
}

.LogomenuExp{
    width: 123px;
    padding: 7px 0px;
    background: #251E8F;
    height: 52px;
}
@media screen and (max-width:1280px) {
    .Logomenu{
        width: 51px;
        padding: 12px 14px;
    }
    .LogomenuExp{
        width: 93px;
    }
}
