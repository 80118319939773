.ProCntCrd{
  background: linear-gradient(45deg,#4099ff,#73b4ff) !important;
  color: #fff !important;
}
.DocCntCard{
  background: linear-gradient(45deg,#FF5370,#ff869a) !important;
  color: #fff !important;
}
.HomeCardIcon{
  margin-top: 6px;
}
.leftbox {   
    height: 100%;
    text-align: center;
    background: #214F77 !important;  
  }
.homePageText{
  /* color: #343a40; */
  font-size: 0.88rem;
  margin-top: 5px;
  min-height: auto;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  text-decoration: none;
} 
.homePageNum{
  /* color: #34ace0 !important; */
  cursor: pointer !important;
  font-size: 25px !important;
  margin: 0px;
}  
.HomeCardCnt{
  height: 90px !important;
}
.HomeTrendCard{
  margin: 0px !important;
}
.HomeCardIcon{
  /* position: absolute;
  right: 0px; */
  /* top: 0px;   */
}
.HomeCardIconClr{
  background: #34ace0 !important;
}
.DocTypeExpand img{
  position: absolute;
  right: 10px;
  /* z-index: 100; */
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.DocExpClsIcn{
  position: absolute;
  right: 0;
  color: #fff;
  cursor: pointer;
  z-index: 100;
} 
.HomePieCard{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
/* new home page css 11-04-2021 */
.carddiv{
    text-align: center;
    display: table;
    width: 100%;
    height: 5.5rem;
}
.alignCarddiv{
  display: table-cell;
    vertical-align: middle;
}
.dtable{
  display: table;
}
.dtablecell{
  display: table-cell;
  vertical-align: middle;
}
.homeCardtxt{
    color: #3C4858;
    font-size: 14px;
    margin-top: 0;
    min-height: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 0px;
    text-decoration: none;
}
.fontnumbers{
    font-weight: bold;
    font-size: 24px;
}
.margtop5{
  margin-top: 5px !important;
}
.margtop15{
  margin-top: 1.5rem !important;
}
.floatdiv{
  padding: 5px 20px 0px 0px;
  display: flow-root;
}
.rowleft{
  float: left;
  font-size: 12px;
  color: #141414;
}
.rowright{
float: right;
font-size: 12px;
  color: #141414;
}