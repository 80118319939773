.trAction{
    font-family: sans-serif;
    font-weight: 500;
    color: #337ab7 !important;
    font-weight: 600;
}
.Edittablecls{
    width: 15px !important;
    color: orange;
}
.Viewtablecls{
    width: 16px !important;
    color: #62B445;
    margin-right: 10px;
}
.removetablecls{
    width: 16px !important;
    color: red;
    margin-right: 10px;
}
.MuiTableCell-stickyHeader {
    background-color: #fff !important;
}
.sublink{
    margin: 5px;
    padding: 8px 12px;
    padding-left: 0;
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.sublink p{
    color: #74777b;
    cursor: pointer;    
    font-size: 0.8rem !important;
    border-left: 3px solid #fff;
    padding-left: 5px;
    border-bottom: 1px solid #ebecf1;
    margin-bottom: 3% !important;
}
.sublink span{
    font-size: 0.688rem;
    font-weight: 500;
    color: #45B154;
    padding-left: 5%;
    float: right;
}
.formheader1{
    width: fit-content;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 5px !important;
    margin-top: -17px !important;
    border-radius: 3px !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px #4892F9;
    background-color: #367AB7;
}
.billingFilterColumnIcon {
    color: #444 !important;
    cursor: pointer !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: 3px;
    transition: all 0.3s ease-out !important;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #e3e4e9;
    padding: 14%;
}
.floatRight{
    float: right;
}
.formtabs{
    margin: 5px; 
    display: flex;
    border: 1px solid #e3e4e9;
}
.tabcontainer{
    width: 80%;
}
.secondaryMenuBtn{
    /* width: fit-content !important;
    
    opacity: 0.5; */
    position: relative;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2.5;
    border: none;
    background: #fff !important;
    color: #74777b;
    font-weight: 550 !important;
    padding: 2px 5px;
    border-right: 1px solid #e3e4e9;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    font-size: 0.8rem;
    font-style: italic
}
.pDisabled{
    cursor: not-allowed !important;
    color: #BFC0C5 !important;
} 
.secondaryMenuBtn:hover{
    cursor: pointer;
    color: #4892F9;
}
.secondaryMenuBtn:focus{
    outline: -webkit-focus-ring-color auto 0px;
}
.linkactive{
    color: #4892F9 !important;
    opacity: 1 !important;
    font-size: 12px !important;
    font-weight: 600;
    border-left: 3px solid #4892F9 !important;
    padding-left: 5px;
}
.btnactvtab{
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 25% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}
.btnactvtabnew{
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 50% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}
.btnactvtabnew1{
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 100% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}
.formcontent{
    margin: 5px;
}

.formdetail{
    background: #303650ed;
    margin: 5px !important;
    padding-bottom: 15px !important;
    padding: 7px;
}

.formdetail p{
    color: #fff;
    font-size: 0.625rem;
    font-style: italic;
}

.formdetail h3{
    color: #fff;
    text-decoration: underline;
    font-size: 0.75rem;
    font-weight: 600;
}

.formpos{
    position: relative;
    bottom: 20px;
    background: #fff;
}

.MuiAccordion-root{
    background-color: transparent !important;
}

.esthead{
    margin: 5px;
}

.esthead h3{
    font-size: 0.688rem;
    font-weight: 600;
    text-decoration: underline;
}

.esthead p{
    font-size: 0.625rem;
    padding: 3px 0px;
}
.sumperc{
    font-size: 0.88rem;
    font-weight: 600;
    color: #4892F9
}
.borderbtmgrid{
    padding: 4px 2px !important;
    border-bottom: 3px solid rgb(72, 146, 249);
}

.TextHorizontal{
    cursor: pointer;
    padding-top: 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background: #337ab7;
    color: #fff;
    /* width: 2%; */
    height: 100%;
    font-size: 0.8rem;
}
.backgroundLightL3{
    background: #fff;
    height: 42px;
}
.L3tableHeaderItems{
    padding-top: 4px;
    width: 80%;
}
.L3AddBtnCommon{
    background: #337ab7 !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-right: 0% !important;
}
.backgroundL3{
    background: #3d4977;
}

/* Report Css */
.ReportMenuListItems{
    width: 25%;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #e3e4e9;
}
/* Report Css */
.ReportMenuListItems{
    width: 25%;
    height: 66vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #e3e4e9;
}
.tabcontainerReport{
    width: 100%;
}
.btnactvtabBtn{
    border: none;
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 50% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}

/* New L4 Design */
.L4ViewDetailsDiv{
    margin: 0 5px;
}
.L4ViewDetailsDiv p{
    font-size: 0.625rem;
    color: black;
}
.L4ViewDetailsDiv p span{
    font-size: 0.8rem;
    font-weight: 600;
    color: black;
}
.AEDspan{
    margin-right: 5px;
    font-weight: 600 !important;
    color: rgb(239, 164, 45) !important;
}

.presentTab{
    background: #337ab7;
    color: #fff !important;

}
.GenBtnCommon{
    width: 9rem !important;
}
.simpleDivButton{
    height: 30px;
    flex: 1 1 auto;
    padding: 5px 20px;
    position: relative;
    -webkit-box-flex: 1;
    margin:0 15px;
    cursor: pointer;
    border: 1px solid #e3e4e9;
    font-weight: bold;
    color:#555;
    border-radius: 4px;
}
.simpleDivButton p{
    margin-top: 0px !important;
    font-size: 0.75rem;
}
.simpleDivButton span{
    height: 14px;
    min-width: 20px;
    font-size: 0.563rem;
    font-weight: bold;
    color: #fff;
    background-color: #f50057;

    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
}

/* AED CSS */
.floatTure{
    top: -10px !important;
    font-size: 10px !important;
    color: #337ab7 !important;
}
.EditNewcls{
    width: 18px !important;
    color: orange;
}
.Divbtnpos{
    position: relative;
    float: right;
    bottom: 30px;
}
.Closesmcls{
    width: 18px !important;
    margin-left: 1rem;
    color: red;
}
.pretextview{
    color: red;
    font-weight: bold;
}
.MuiTableSortLabel-root span{
    color: #337ab7 !important;
    font-weight: 600 !important;
}

.cardhight{
    height: 100% !important;
    margin-top: 0 !important;
}
.mpadd5{
    padding: 5px !important;
}
.contpad{
    padding-right: 1rem;
}
.pmarg2{
    padding: 0px;
    margin-top: 2rem;
}
.ascardpad{
  padding: 0.3rem !important;
}
.dflex{
    display: flex;
}
.qrdiv{
    width: 100%;
    flex: 1;
    float: right;
    padding: 5px;
}
.assNametag{
    font-size: 17px;
    font-weight: bold;
    color: cornflowerblue;
    padding: 5px 20px;
}
.pfonttag{
    font-size: 12px;
    font-weight: 600;
    color: #555;
}
.qralignrtxt{
    padding-top: 7px;
    text-align: right;
}
.assimgw{
    width: 17%;
    border-radius: 6px;
}
.lptag {
    font-weight: bold;
    color: #777;
    padding: 5px;
    border-left: 3px solid #fff;
}

.acrcardpad{
padding: 10px 20px !important;
}
.lptag:hover {
    border-left: 3px solid #4da7fc;
    color: #4da7fc;
}
.acctext{
    font-weight: bold;
    color: #4da7fc;
    font-size: 14px;
}
.acctext:hover{
    color: #4da7fc;
}
.mar0 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
.accsumar > .MuiAccordionSummary-root {
    border-bottom: 0px !important;
}
.ptno{
    font-size: 12px;
    color: #444;
    padding-left: 1rem;
}
.divcolleft{
    width: 50%;
    float: left;
    padding-left: 1.3rem;
}
.pnortxt{
    font-size: 12px;
    color: #444;
}
.padleft5 {
    padding-left: 0.5rem;
}