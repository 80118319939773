.ant-table-body{
    height: 58vh !important;
}
/* //, .ant-table-scroll */
.ant-table {
    height: 62vh !important;
} 
.antheight{
    height: 42vh !important;
}
.totalRecords{
    position: absolute;
    bottom: 15px;
    color: #777;
}
.expandedTotalRecord {
    position: relative;
    bottom: 25px;
    color: #777;
}
.expandIcon {
    color:#68bf71;
}
.PickerMargin{
    margin-top: 16px !important;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    /* height: 55vh !important; */
    /* max-height:55vh !important; */
    /* overflow-y: scroll;
    overflow-x: auto !important; */
} 
.ant-pagination-item-active{ 
    font-weight: 500;
    background: #fff;
    border-color: #34ace0;
}
.ant-pagination-item-active a {
    color: #34ace0 !important;
}
 

.ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a,
 .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: #34ace0 !important;
    /* border-color: #34ace0; */
}  
.ant-pagination-item-active:hover a {
    color: #fff !important;
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    color: #fff !important;    
}
.ant-pagination-item:hover a {
    color: #777 !important;
}
.ant-select-selection {
    border-radius: 17px !important;
    border: 1px solid #34ace0 !important;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.08) !important;
    transition-duration: 0.15s;
    transition-property: background-color;
}



.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #7e878f !important;
    color: #fff !important;
}

.ant-table-thead{
    background-color: /*#26E2ED #5B4EDC #3F167E*/ #34ace0 !important; 
}    
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    /* padding-top: 6px !important;
    padding-bottom: 6px !important; */
    padding: 6px 9px 6px 6px !important;
}
.ant-table-cell-scrollbar{
    display: none !important;
}
.ant-table-thead tr th{ 
    border: 1px solid #eee !important;
    background: #34ace0 !important;
    color: #fff !important; 
    font-weight: bold !important;
    font-size: 12px !important;
    /* padding-top: 6px !important;
    padding-bottom: 6px !important; */
}
.ant-table-tbody > .ant-table-row > td{
    border: 1px solid #f0f0f0 !important;
}
.ant-table-cell .MuiFormControlLabel-root{
    margin-left: 25% !important;
}
/* tr:nth-child(even) {background-color: #f2f2f2 !important;} */

.ant-table-tbody > .ant-table-row > td {
  padding: 9px !important;
  font-weight: 400;
  color: black;
  font-size: smaller;
  text-transform: capitalize !important;
}
.ant-pagination-prev, .ant-pagination-next{
    min-width: 28px !important;
    height: 26px !important;
    line-height: 26px !important;
}
.ant-pagination-item {
    min-width: 28px !important;
    height: 26px !important;
    line-height: 24px !important;
}
.ant-pagination, .ant-select {
    font-size: 13px !important;
}
.ant-select-selection--single {
    height: 26px !important;
}
.ant-table-pagination.ant-pagination {
    margin: 10px 5px !important;
}
.ant-select-selection__rendered{
    line-height: 21px !important;
}
.ant-empty-normal{
    margin: 0px;
}

.ant-table-placeholder{
    padding: 5px 16px;
}

th.seq_no,
td.seq_no {
  text-align: center !important;
}

th.center,
td.center {
  text-align: center !important; 
}

td.right{
    text-align: right !important;
}

td.left {
    text-align: left !important;
}

td.center {
    text-align: center !important; 
}

td.right{
    text-align: right !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    /* padding-left: 9px !important;  */
}
.tableSearch{
    /* margin-top: -10% !important; */
    color: #fff !important;
    /* padding: 4px; */
}  
.sortIcon{
    position: absolute !important;
    right: 5px !important;
}
/* .ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active { */
    /* background: none !important; */
    /* color: #34ace0 !important; */
    /* color: #fff !important; */
/* } */
/* .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.3em;
    color: #fff !important;
}
.ant-table-column-sorter-up, .ant-table-column-sorter-down {
    display: inline-block;
    font-size: 0.688rem;
    color: #fff !important;
} */
.ant-table-filter-column-title{
    margin-top: -15px !important;
    flex: auto !important;
    padding: 16px 2.3em 16px 16px !important;
}
.ant-table-filter-column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center !important; 
    margin: -16px -16px !important;
    margin-top: 0px  !important;
    /* position: absolute !important; */
}
.tableAdd{
    width: fit-content;
    right: 7%;
    position: absolute;
    color:#fff;
}
 .tableClose{
    width: fit-content;
    right: 0%;
    position: absolute;
    color:#fff;
 } 
 .tableShow{
    width: fit-content;
    right: 0%;
    position: absolute;
    color:#fff;
 } 
 .tableSort{
     position: absolute !important;
     top: 5px !important;
     /* left: 50px; */
     cursor: pointer;
     width: 18px !important;
 }
.editingspan {
    width: 80%;
    padding: -1px 10px 0px 10px
}
.LegndStyle{
    color: #FFF;
    margin: 0 15px;
    margin-top: 0px;
    padding: 0;
    position: relative;
}
.ImgUploadMsg{
    font-size: xx-small;
    color: brown;
} 
.User-Right-Child-Grid {
    width: calc(100% + 24px) !important;
    margin: 0 -15px !important;
}
.NestedTableChild{
    height: 40vh !important;     
} 
.expandSpan{
    cursor: pointer;
}
.ant-checkbox-wrapper {
    padding: 2px !important;
}
.ant-radio-inner::after{
    background-color: #68bf71 !important;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #68bf71 !important; 
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #68bf71 !important;
} 
svg {
    vertical-align: middle;
}  
.TableBtn{
    cursor: pointer;
    height: 33px;
    width: 77px;
    font-size: 0.94rem;
    padding-top: 5px !important;
    padding-bottom: 6px;
}
.TableBtn svg{
    position: relative !important;
    bottom: 2px !important;
    right: 0px !important;
} 
/* th.ant-table-cell.DocFixed.ant-table-cell-fix-left{ */
    /* background: #34ace0 !important;  */
/* } */
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    color: rgba(0, 0, 0, 0.87);
    background-color: #eee !important;
    transition-duration: 0.15s;
    transition-property: background-color;
}
.anticon-left,.anticon-right{
    position: relative !important;
    bottom: 3px !important;
}
.ant-table-measure-row{
    /* height: 50px !important; */
    visibility: collapse !important;
}

.ant-table-column-sorter{
    color: #fff;
}
.ant-table-column-sorters {
    margin-top: -10px !important;
}
/* Hover */
.ant-table-column-sorters-with-tooltip .ant-table-column-sorter-inner:hover{
    background: #bfbfbf !important;
    color: #fff !important;
} 
.Doc-Table .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 0px !important;
    overflow-wrap: break-word;
}
.Doc-Table .ant-table-thead .ant-table-cell{
    padding: 8px !important;
}
.tableSort{
    position: absolute !important;
    top: 5px !important;
    /* left: 50px; */
    cursor: pointer;
    width: 18px !important;
}

/* Table Search */
.tableHeadersearch{
    color: #227093;
    font-size: 15px !important; 
    cursor: pointer;
    float: right;
    margin-top: 6px;
}
.tableHeadersearchClose{
    color: red;
    font-size: 18px !important; 
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 0px;
}
.TableheaderSearchInput{
    /* width: fit-content !important; */
    color: black;
    margin: 0px !important;
}