.page-header, .page-header-space {
    /* height: 40px; */
     
    background: #fff
  }
  
  .page-footer, .page-footer-space {
    height: 40px;
  
  } 
  .erabGr {
    font-size: 1rem !important;
  }
  
  .page-footer {
    position: fixed;
    bottom: 0;
    left: 1%;
    width: 100%;  
  }
  
  .page-header {
    position: fixed;
    top: 0;
    width: 100%; 
    /* background: #eee; */
  }
  .page-heading{ 
    background:#ccc7c7 !important;
    text-align:center; 
    width:100% !important; 
  }
  
  .page {
    page-break-after: always;
    padding-bottom: 20px;
  } 
  /*@media print{
    @page {
      size: landscape !important;
      margin: 0mm;
    }
  }  */ 
   
  table #yearCustomer{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%; 
    /* height: 90%; */
    font-size: 0.563rem;
  }
  .print_td {
    /* border: 1px solid #dddddd; */
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
  }
  .print_tdbody {
    border: 1px solid #dddddd;
  }
  .print_tdhead {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
  }
  .print_tdfoot { 
    /* border-top: 1px solid #dddddd; */
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
  } 
  