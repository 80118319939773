@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600);
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 3.5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.MuiFormLabel-root {
  font-size: 13px !important;
}
.MuiFormLabel-root.Mui-focused {
color: #34ace0 !important;
}
.MuiAutocomplete-noOptions{
display: none
}

/* .MuiInput-underline:before {
  border-width: 2px !important;
} */
.MuiInput-underline::before {
   /* border-color: transparent !important; */
   border-bottom: 2px solid #337ab7 !important;
}

/* .MuiInput-underline:before:hover {
  border-color: #34ace0 !important;
  border-width: 2px !important;
} */
.MuiFormLabel-root.Mui-focused{
  /* color:#aaaaaa !important; */
}
.MuiFormControl-marginNormal {
  margin: -5px 0 17px 0 !important;
  position: relative;
  /* padding-top: 27px; */
  vertical-align: unset;
  }
  .MuiInputBase-input{
    /* font: -webkit-control !important; */
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #4d4d4d !important;
  }

.MuiAutocomplete-option{
  font-size: 13px !important;
}

.MuiMenuItem-root{
  font-size: 13px !important;
}

.EditIcon{
font-size: 16px !important;
color: #ff9800;
margin-left: 10px;
cursor: pointer;
}

.LogIcon{
font-size: 16px !important;
color: #4caf50;
cursor: pointer;
}

.DeleteIcon{
font-size: 16px !important;
color: red;
margin-left: 10px;
cursor: pointer;
}
.DoneIcon{
  font-size: 18px !important;
  color: #4caf50;
  cursor: pointer;
}
.iconButton{
padding: 0px !important;
transition: none !important;
border-radius: 0% !important; 
}
.fltPadding { 
padding: 5px 20px 0px 0px !important;
}
.addIconBtn{
color:white !important;
padding: 0px !important;
transition: none !important;
border-radius: 0% !important; 
}
.ant-input{
width: 80% !important;
}
.active{
color: #FFF !important;
box-shadow: 0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2) !important;
background-color: #34ace0 !important;
} 
.selectedWheel .MuiChip-root > .MuiSvgIcon-root{
width: 14px !important;
height: 14px !important;
}

.render li > .MuiSvgIcon-root{
width: 16px !important;
height: 14px !important;
}
/* 
.outclick > .MuiFormControl-root {
margin: -5px 0 17px 0 !important;
}

.outclick > .MuiFormControl-root > .MuiInputBase-root{
padding-bottom: 1px !important;
}

.outclick > .MuiFormControl-root > .MuiInputBase-root > input {
padding: 3px 0px 6px !important;
} */
.spanTab svg{
font-size: 15px !important;
/* position: relative;
right: 5px;
bottom: 8px; */
}
.ant-checkbox-inner {
width:  18px !important;
height: 18px !important;
border:2px solid #43a047 !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
background-color: #43a047 !important;
}
.ant-checkbox-wrapper{
padding: 7px !important;
}
.ant-checkbox-inner::after {
  background-color: #43a047 !important;
}
.ant-checkbox-inner :hover {
  background-color: #43a047 !important;
}
.liTabs{
font-size: 0.81rem;
padding: 2px 8px;
opacity: 0.5;  
border-radius: 6px;  
cursor: pointer;
margin: 3px;
list-style-type: none;
background-color: #fff;        
border-right: 1px solid gainsboro;
box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
} 

.hideDiv{
display: none;
}

.outclick > .MuiFormControl-root > .MuiInputBase-root > input {
padding: 3px 0px 6px !important;
}
.btngrp{
color: #34ace0 ;
}
.radioColor{
color: #43a047 !important;
} 
.closeBtn{
float: right;
color: red;
margin-left: 5px;
font-weight: 400;
}
.MuiRadio-root {
   padding: 0px 9px !important;
}
.Checkboxs-radioRoot-432 {
padding: 16px !important;
}
.homeImg{
padding: 19px;
} 

.HlpsideMenuIcon{ 
  margin-top: 10px;
  color: #fff;
}
.imageBackColour
{
color: #FFF !important;
margin-left: 23%;
}
.active {
color: green;
}
#staffAnalysisSlideClose{
cursor: pointer;
height: 91%;
width: 0;
margin-top: 25px;
position: fixed;
z-index: 10;
top: 70px;
right: 2%;
background: rgba(130, 130, 130, 0.5);
overflow-x: hidden;
transition: 0.7s;
}
#staffSlideChild{
position: absolute;
background-color: whitesmoke;
right: 0px;
}

#MaterialSlideClose{
cursor: pointer;
height: 91%;
width: 0;
margin-top: 25px;
position: fixed;
z-index: 10;
top: 70px;
right: 2%;
background: rgba(130, 130, 130, 0.5);
overflow-x: hidden;
transition: 0.7s;
}
#MaterialSlideChild{
position: absolute;
background-color: whitesmoke;
right: 0px;
}
#MattabHead{
background-color: #214F77 !important ;
color: #FFF; 
width: 100%;
z-index: 10;
}

#staffTabHead{
background-color: #214F77 !important ;
color: #FFF; 
width: 51%;
z-index: 10;
}

#staffCloseBtn{
position: absolute;
top: 9%;
border-radius: 3px !important;
left: 31%; 
background: #34ace0 !important;
color: #FFF;
border-bottom-left-radius: 25px !important;
border-top-left-radius: 25px !important;
} 
#BOQCloseBtn{
  position: absolute;
  top: 9%;
  border-radius: 3px !important;
  left: 11%; 
  background: #34ace0 !important;
  color: #FFF;
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
}
#CheckCloseBtn{
  position: absolute;
  top: 9%;
  border-radius: 3px !important;
  left: 11%; 
  background: #34ace0 !important;
  color: #FFF;
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
}
.tabBody{
  padding:12px;
  margin-top: 30px;
  padding-bottom: 10%;
}


/* Material Availablity Stype */
.MaterialtabClose
{
left: 12.5vw;
top: 48px;
background: #34ace0 !important;
color: #FFF;
border-bottom-left-radius: 25px;
border-top-left-radius: 25px;
position: absolute;   
}

.MaterialAvgImg{
position: absolute !important;
right: 15px !important;
top: -10px !important;
border-radius: 4px !important;
background-color: #4CAF50 !important;
box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(73, 155, 239, 0.84);
}
.MaterialAvgSize{
color: #fff;
}
.MaterialAvgSize:hover{
color: #fff !important;
}


.slideHeading{
color: #FFF;
}
.fixedFooter{
margin-top: 0px;
width: 100%;  
background: #214F77; 
height: 32px;
z-index: 5;
}

.helpdeskMain{
width: 96%;
} 
.HelpdeskBtn{
background: #43a047 !important;
padding: 18px !important;
padding-bottom: 2px !important;
padding-top: 4px !important;
} 

/* Execution */
.stfExeRC{
  position: absolute;
  top: 15%;
  width: 99% !important;
}
#staffExecutionSlideClose{
  cursor: pointer;
  height: 91%;
  width: 0;
  margin-top: 25px;
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 2%;
  background: rgba(130, 130, 130, 0.5);
  overflow-x: hidden;
  transition: 0.7s;
}
#ExstaffImgSlide{
cursor: pointer;
height: 91%;
width: 0;
margin-top: 25px;
position: fixed;
z-index: 10;
top: 26px;
right: 3%;
background: transparent;
overflow-x: hidden;
transition: 0.7s;
}
#staffExecutionSlideChild{
position: absolute;
background-color: whitesmoke;
right: 0px; 
}


.EximgTabClose{
left: 0vw;
top: 48px;
background: #34ace0 !important;
color: #FFF;
border-bottom-left-radius: 25px;
border-top-left-radius: 25px;
position: absolute;
}


.ExstaffImgChild{
background: whitesmoke;
padding: 12px;
right: 0%;
top: 48px;
position: absolute;
height: 92%;
width: 93%;
}
/*----------Vendor Invoice------*/
#vendorHead{
    background-color: #214F77 !important ;
    color: #FFF; 
    width: 100%;
    z-index: 10;
}
#vendorSlideClose{
  cursor: pointer;
  height: 91%;
  width: 0;
  margin-top: 25px;
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 2%;
  background: rgba(130, 130, 130, 0.5);
  overflow-x: hidden;
  transition: 0.7s;
} 
#vendorSlideChild{
position: absolute;
background-color: whitesmoke;
right: 0px; 
}
/*----------Checkpoints Invoice------*/
#CheckPointsHead{
  background-color: #214F77 !important ;
  color: #FFF; 
  width: 100%;
  z-index: 10;
}
#CheckPointsSlideClose{
cursor: pointer;
height: 91%;
width: 0;
margin-top: 25px;
position: fixed;
z-index: 10;
top: 70px;
right: 2%;
background: rgba(130, 130, 130, 0.5);
overflow-x: hidden;
transition: 0.7s;
} 
#CheckPointsSlideChild{
position: absolute;
background-color: whitesmoke;
right: 0px; 
}
/*----------BOQ Invoice------*/
#BOQHead{
  background-color: #214F77 !important ;
  color: #FFF; 
  width: 100%;
  z-index: 10;
}
#BOQClose{
cursor: pointer;
height: 91%;
width: 0;
margin-top: 25px;
position: fixed;
z-index: 10;
top: 70px;
right: 2%;
background: rgba(130, 130, 130, 0.5);
overflow-x: hidden;
transition: 0.7s;
} 
#BOQChild{
position: absolute;
background-color: whitesmoke;
right: 0px; 
}
/*--------Floating Button--------*/
#floatButton{
  position: fixed;
  right: 10px;
  top: 50%;
  transition: 0.7s;
  z-index: 9999;
  cursor: pointer; 
} 
#hlpdskTableSlide{
width: 0px;
cursor: pointer;
height: 91%; 
margin-top: 25px;
position: fixed; 
z-index: 10;
top: 68px;
right: 7px;
background: whitesmoke;
overflow-x: hidden;
transition: 0.7s;
}
.Tooltip:hover{
cursor: pointer;
}


.expandSpan{
color: #9c27b0 !important;
}

.ImageIcon{
position: absolute; 
top: -3px;
right: 20px;
}
.MuiDialog-paperFullWidth {
width: calc(100% - 64px) !important;
/* height: 78% !important; */
}
.mainSave{
position: absolute !important;
top:-4% !important;
right: 20% !important;  
}
.mainSaveDrf{
position: absolute !important;
top:-4% !important;
right: 10% !important;  
}
.LegndStyle{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 5px !important;
  margin-top: -17px !important;
  border-radius: 3px !important;
}
.logout{
  margin: 0 !important;
  color: #34ace0;
}
.sidmenu::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.2;
  /* background:  url("/static/media/sidebar-2.d30c9e30.jpg") no-repeat center center; */
  background-size: cover;
}
.sidmenu{
  position: relative;
  background-color: #000;
}
.MuiAutocomplete-input{
  width: auto !important;
}
.posAlign{
  position: relative;
    border: 0;
    top: 6px;
}
/* Wifi Css */
.wifiIcon{
  margin-top: 6%;
  padding-left: 12%;
}
.wifioff{
  color: red;
}
.wifion{
  color: #7fde7a;
}
/* Supervisor */

#supervisorSlideClose{
  cursor: pointer;
  height: 91%;
  width: 0;
  margin-top: 25px;
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 2%;
  background: rgba(130, 130, 130, 0.5);
  overflow-x: hidden;
  transition: 0.7s;
  }
  
  #supervisorSlideChild{
  position: absolute;
  background-color: whitesmoke;
  right: 0px;
  }
  
  #supervisorTabHead{
  background-color: #214F77 !important ;
  color: #FFF; 
  width: 100%;
  z-index: 10;
  }
  /* Staff execution */
  #StfExecHead{
    background-color: #214F77 !important ;
    color: #FFF; 
    width: 100%;
    z-index: 10;
  }
  /* Supervisor */

#supervisorSlideClose{
  cursor: pointer;
  height: 91%;
  width: 0;
  margin-top: 25px;
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 2%;
  background: rgba(130, 130, 130, 0.5);
  overflow-x: hidden;
  transition: 0.7s;
  }
  
  #supervisorSlideChild{
  position: absolute;
  background-color: whitesmoke;
  right: 0px;
  }
  
  #supervisorTabHead{
  background-color: #214F77 !important ;
  color: #FFF; 
  width: 100%;
  z-index: 10;
  }



  /* BDM Approval */

  #bdmApprovalSlideClose{
    cursor: pointer;
    height: 91%;
    width: 0;
    margin-top: 25px;
    position: fixed;
    z-index: 10;
    top: 70px;
    right: 2%;
    background: rgba(130, 130, 130, 0.5);
    overflow-x: hidden;
    transition: 0.7s;
    }
    
    #bdmApprovalSlideChild{
    position: absolute;
    background-color: whitesmoke;
    right: 0px;
    }
    
    #bdmApprovalTabHead{
    background-color: #214F77 !important ;
    color: #FFF; 
    width: 100%;
    z-index: 10;
    }
 /* BDM Approval */

 #bdmApprovalSlideClose{
  cursor: pointer;
  height: 91%;
  width: 0;
  margin-top: 25px;
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 2%;
  background: rgba(130, 130, 130, 0.5);
  overflow-x: hidden;
  transition: 0.7s;
  }
  
  #bdmApprovalSlideChild{
  position: absolute;
  background-color: whitesmoke;
  right: 0px;
  }
  
  #bdmApprovalTabHead{
  background-color: #214F77 !important ;
  color: #FFF; 
  width: 100%;
  z-index: 10;
  }



  /* Helpdesk others Material */

#otherMaterialSlideClose{
  cursor: pointer;
  height: 91%;
  width: 0;
  margin-top: 25px;
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 2%;
  background: rgba(130, 130, 130, 0.5);
  overflow-x: hidden;
  transition: 0.7s;
  }
  
  #otherMaterialSlideChild{
  position: absolute;
  background-color: whitesmoke;
  right: 0px;
  }
  
  #otherMaterialTabHead{
  background-color: #214F77 !important ;
  color: #FFF; 
  width: 100%;
  z-index: 10;
  }


  /* Other Material  Stype */
.OtherMaterialtabClose
{
left: 12.5vw;
top: 48px;
background: #34ace0 !important;
color: #FFF;
border-bottom-left-radius: 25px;
border-top-left-radius: 25px;
position: absolute;   
}




  /* Helpdesk others Supplimentry */

  #otherSupplimentrySlideClose{
    cursor: pointer;
    height: 91%;
    width: 0;
    margin-top: 25px;
    position: fixed;
    z-index: 10;
    top: 70px;
    right: 2%;
    background: rgba(130, 130, 130, 0.5);
    overflow-x: hidden;
    transition: 0.7s;
    }
    
    #otherSupplimentrySlideChild{
    position: absolute;
    background-color: whitesmoke;
    right: 0px;
    }
    
    #otherOutherSupllientryTabHead{
    background-color: #214F77 !important ;
    color: #FFF; 
    width: 100%;
    z-index: 10;
    }



       
.OtherSupplimentrytabClose
{
left: 12.5vw;
top: 48px;
background: #34ace0 !important;
color: #FFF;
border-bottom-left-radius: 25px;
border-top-left-radius: 25px;
position: absolute;   
}
.ant-table-body{
    height: 58vh !important;
}
/* //, .ant-table-scroll */
.ant-table {
    height: 62vh !important;
} 
.antheight{
    height: 42vh !important;
}
.totalRecords{
    position: absolute;
    bottom: 15px;
    color: #777;
}
.expandedTotalRecord {
    position: relative;
    bottom: 25px;
    color: #777;
}
.expandIcon {
    color:#68bf71;
}
.PickerMargin{
    margin-top: 16px !important;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    /* height: 55vh !important; */
    /* max-height:55vh !important; */
    /* overflow-y: scroll;
    overflow-x: auto !important; */
} 
.ant-pagination-item-active{ 
    font-weight: 500;
    background: #fff;
    border-color: #34ace0;
}
.ant-pagination-item-active a {
    color: #34ace0 !important;
}
 

.ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a,
 .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: #34ace0 !important;
    /* border-color: #34ace0; */
}  
.ant-pagination-item-active:hover a {
    color: #fff !important;
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    color: #fff !important;    
}
.ant-pagination-item:hover a {
    color: #777 !important;
}
.ant-select-selection {
    border-radius: 17px !important;
    border: 1px solid #34ace0 !important;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.08) !important;
    transition-duration: 0.15s;
    transition-property: background-color;
}



.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #7e878f !important;
    color: #fff !important;
}

.ant-table-thead{
    background-color: /*#26E2ED #5B4EDC #3F167E*/ #34ace0 !important; 
}    
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    /* padding-top: 6px !important;
    padding-bottom: 6px !important; */
    padding: 6px 9px 6px 6px !important;
}
.ant-table-cell-scrollbar{
    display: none !important;
}
.ant-table-thead tr th{ 
    border: 1px solid #eee !important;
    background: #34ace0 !important;
    color: #fff !important; 
    font-weight: bold !important;
    font-size: 12px !important;
    /* padding-top: 6px !important;
    padding-bottom: 6px !important; */
}
.ant-table-tbody > .ant-table-row > td{
    border: 1px solid #f0f0f0 !important;
}
.ant-table-cell .MuiFormControlLabel-root{
    margin-left: 25% !important;
}
/* tr:nth-child(even) {background-color: #f2f2f2 !important;} */

.ant-table-tbody > .ant-table-row > td {
  padding: 9px !important;
  font-weight: 400;
  color: black;
  font-size: smaller;
  text-transform: capitalize !important;
}
.ant-pagination-prev, .ant-pagination-next{
    min-width: 28px !important;
    height: 26px !important;
    line-height: 26px !important;
}
.ant-pagination-item {
    min-width: 28px !important;
    height: 26px !important;
    line-height: 24px !important;
}
.ant-pagination, .ant-select {
    font-size: 13px !important;
}
.ant-select-selection--single {
    height: 26px !important;
}
.ant-table-pagination.ant-pagination {
    margin: 10px 5px !important;
}
.ant-select-selection__rendered{
    line-height: 21px !important;
}
.ant-empty-normal{
    margin: 0px;
}

.ant-table-placeholder{
    padding: 5px 16px;
}

th.seq_no,
td.seq_no {
  text-align: center !important;
}

th.center,
td.center {
  text-align: center !important; 
}

td.right{
    text-align: right !important;
}

td.left {
    text-align: left !important;
}

td.center {
    text-align: center !important; 
}

td.right{
    text-align: right !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    /* padding-left: 9px !important;  */
}
.tableSearch{
    /* margin-top: -10% !important; */
    color: #fff !important;
    /* padding: 4px; */
}  
.sortIcon{
    position: absolute !important;
    right: 5px !important;
}
/* .ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active { */
    /* background: none !important; */
    /* color: #34ace0 !important; */
    /* color: #fff !important; */
/* } */
/* .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.3em;
    color: #fff !important;
}
.ant-table-column-sorter-up, .ant-table-column-sorter-down {
    display: inline-block;
    font-size: 0.688rem;
    color: #fff !important;
} */
.ant-table-filter-column-title{
    margin-top: -15px !important;
    flex: auto !important;
    padding: 16px 2.3em 16px 16px !important;
}
.ant-table-filter-column {
    display: flex;
    align-items: center !important; 
    margin: -16px -16px !important;
    margin-top: 0px  !important;
    /* position: absolute !important; */
}
.tableAdd{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    right: 7%;
    position: absolute;
    color:#fff;
}
 .tableClose{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    right: 0%;
    position: absolute;
    color:#fff;
 } 
 .tableShow{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    right: 0%;
    position: absolute;
    color:#fff;
 } 
 .tableSort{
     position: absolute !important;
     top: 5px !important;
     /* left: 50px; */
     cursor: pointer;
     width: 18px !important;
 }
.editingspan {
    width: 80%;
    padding: -1px 10px 0px 10px
}
.LegndStyle{
    color: #FFF;
    margin: 0 15px;
    margin-top: 0px;
    padding: 0;
    position: relative;
}
.ImgUploadMsg{
    font-size: xx-small;
    color: brown;
} 
.User-Right-Child-Grid {
    width: calc(100% + 24px) !important;
    margin: 0 -15px !important;
}
.NestedTableChild{
    height: 40vh !important;     
} 
.expandSpan{
    cursor: pointer;
}
.ant-checkbox-wrapper {
    padding: 2px !important;
}
.ant-radio-inner::after{
    background-color: #68bf71 !important;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #68bf71 !important; 
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #68bf71 !important;
} 
svg {
    vertical-align: middle;
}  
.TableBtn{
    cursor: pointer;
    height: 33px;
    width: 77px;
    font-size: 0.94rem;
    padding-top: 5px !important;
    padding-bottom: 6px;
}
.TableBtn svg{
    position: relative !important;
    bottom: 2px !important;
    right: 0px !important;
} 
/* th.ant-table-cell.DocFixed.ant-table-cell-fix-left{ */
    /* background: #34ace0 !important;  */
/* } */
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    color: rgba(0, 0, 0, 0.87);
    background-color: #eee !important;
    transition-duration: 0.15s;
    transition-property: background-color;
}
.anticon-left,.anticon-right{
    position: relative !important;
    bottom: 3px !important;
}
.ant-table-measure-row{
    /* height: 50px !important; */
    visibility: collapse !important;
}

.ant-table-column-sorter{
    color: #fff;
}
.ant-table-column-sorters {
    margin-top: -10px !important;
}
/* Hover */
.ant-table-column-sorters-with-tooltip .ant-table-column-sorter-inner:hover{
    background: #bfbfbf !important;
    color: #fff !important;
} 
.Doc-Table .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 0px !important;
    overflow-wrap: break-word;
}
.Doc-Table .ant-table-thead .ant-table-cell{
    padding: 8px !important;
}
.tableSort{
    position: absolute !important;
    top: 5px !important;
    /* left: 50px; */
    cursor: pointer;
    width: 18px !important;
}

/* Table Search */
.tableHeadersearch{
    color: #227093;
    font-size: 15px !important; 
    cursor: pointer;
    float: right;
    margin-top: 6px;
}
.tableHeadersearchClose{
    color: red;
    font-size: 18px !important; 
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 0px;
}
.TableheaderSearchInput{
    /* width: fit-content !important; */
    color: black;
    margin: 0px !important;
}
.page-header, .page-header-space {
    /* height: 40px; */
     
    background: #fff
  }
  
  .page-footer, .page-footer-space {
    height: 40px;
  
  } 
  .erabGr {
    font-size: 1rem !important;
  }
  
  .page-footer {
    position: fixed;
    bottom: 0;
    left: 1%;
    width: 100%;  
  }
  
  .page-header {
    position: fixed;
    top: 0;
    width: 100%; 
    /* background: #eee; */
  }
  .page-heading{ 
    background:#ccc7c7 !important;
    text-align:center; 
    width:100% !important; 
  }
  
  .page {
    page-break-after: always;
    padding-bottom: 20px;
  } 
  /*@media print{
    @page {
      size: landscape !important;
      margin: 0mm;
    }
  }  */ 
   
  table #yearCustomer{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%; 
    /* height: 90%; */
    font-size: 0.563rem;
  }
  .print_td {
    /* border: 1px solid #dddddd; */
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
  }
  .print_tdbody {
    border: 1px solid #dddddd;
  }
  .print_tdhead {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
  }
  .print_tdfoot { 
    /* border-top: 1px solid #dddddd; */
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
  } 
  
.OpenSlide{
    cursor: pointer;    
}
.SlidecloseIcon{
    color: #fff;
}
#DocSlide {
    cursor: pointer;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 20; 
    right: 10px;
    background-color: transparent;
    overflow-x: hidden;
    transition: 0.2s;
    top: 108px;    
}
#InsideSlide{
    margin-left: 30px;
}
#DocClose{
    background: #34ace0;
    width: 50px; 
    height: 42px;
    position: absolute;
    padding-top: 10px;
    top: 70px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding-left: 8px;
    box-shadow: inset -6px 0 8px -10px rgba(0,0,0,0.95);
    cursor: pointer;
}
.DocSlideHead{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 0px !important;
}



.OpenRevSlide{
    cursor: pointer;
}
#DocRevSlide {
    cursor: pointer;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 20; 
    right: 10px;
    background-color: transparent;
    overflow-x: hidden;
    transition: 0.2s;
    top: 108px;    
}
#InsideRevSlide{
    margin-left: 30px;
}
#DocRevClose{
    background: #337ab7;
    width: 50px; 
    height: 42px;
    position: absolute;
    padding-top: 10px;
    top: 70px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding-left: 8px;
    box-shadow: inset -6px 0 8px -10px rgba(0,0,0,0.95);
    cursor: pointer;
} 
.DocRevSlideHead{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 0px !important;
}


.OpenRevImgSlide{
    cursor: pointer;
}
#DocRevImgSlide {
    cursor: pointer;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 20; 
    right: 10px;
    background-color: transparent;
    overflow-x: hidden;
    transition: 0.2s;
    top: 108px;    
}
#InsideRevImgSlide{
    margin-left: 30px;
}
#DocRevImgClose{
    background: #337ab7;
    width: 50px; 
    height: 42px;
    position: absolute;
    padding-top: 10px;
    top: 70px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding-left: 8px;
    box-shadow: inset -6px 0 8px -10px rgba(0,0,0,0.95);
    cursor: pointer;
} 
.DocRevImgSlideHead{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 0px !important;
}
.ImageID{  
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    left: 0px;
    z-index: 10;
}
.ImageDiv{  
    position: relative;
    height: 103px;
    color: #97A1A8;
    background: #fff;
    border: 1px dashed #C8CBCE;
    text-align: center;
    transition: box-shadow 0.3s, border-color 0.3s;
    cursor: pointer;
    margin-top: 14px;
} 

.ImagePreview{
    
    height: 100px;
    width: 120px;
    border-radius: 4px;
    padding: 5px;
}
.ImgClose{ 
    position: absolute;
    z-index: 11;
    background: #02B152 !important;
    border-radius: 8px;
    display: flex;
    right: 0px;
    top: 2px;
}
.CloseIconImg{
    color: #fff;
    font-size: large !important;
}
.ImgNew{
    padding: 15px;
}
.MuiSvgIcon-fontSizeLarge {
    margin-top: 15px;
    color: #476b6b;
    font-size: 3.188rem !important;
}
.FileName{
    color: #476b6b;
}
.File-Name{
    margin-top: 5px;
    font-size: 0.625rem;
}
.ImgUploadMsg{
    font-size: xx-small;
    color: brown;
}
.brandhead{
    color:#fff;
    font-size: 0.8rem;
    margin: 0px;
}
.newBtn{
    position:relative;
    margin-top: -15px;
    margin-right: 10px;
    padding: 0px;
}
.menuImg{
    height: 50px !important;
    width: 100% !important;
}
.tabcontain{
    width: 100%;
    height: 100%;
}
.tabIcons{
    float: left;
    padding: 2px 5px 0px 0px;
    /* top: 13px;
    position: relative;
    display: block; */
}
.tabcontent{
    position: relative;
    top: 4px;
}
.tabActive{
    font-size: 0.81rem;
    background-color: #337ab7!important;
    opacity: 1!important;
    color: #fff !important;
    font-weight: 400;
    z-index: 1!important;
    /* box-shadow: 2px -2px 5px -5px #333!important; */
    -webkit-user-select: none;
    user-select: none;
    border-top: 1px solid #337ab7 !important;
    border-right: 1px solid #337ab7 !important;
    border-left: 1px solid #337ab7 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    /* height: 35px !important; */
    /* border-bottom: 3px solid #ffffff !important; */
    font-weight: 600 !important;
}

.tabActive .closeBtn{
    color: #fff
}

.profile{
    margin-top: 5px;
}
/* h6.Profile-Name::first-letter{
     color: #1BACC2 !important;  
    font-size: 10%;
} */
.Profile-Name{
    margin: 0 0 0px;    
}
#user-name{
    font-size: 0.75rem;
    font-weight: 600;
    color:#337ab7 !important;
}
#welcome{
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.5;
    color:#337ab7 !important;
}
.dropdownicon{
    font-size: 1.1rem !important;
    margin-right: 0.6rem;
}
.horizontalNav{
    right:0;
    background: #fff;
    position: fixed;
    width: 95%;
    /* height: 8%; */
    z-index: 12;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
}
.listButton{
    margin-left: 15px;
    width: 40px;
    color:black;
    box-shadow:none !important;
    
}
.sidebarMiniIcon{
    width: 30px !important;
    height: 30px !important; 
    margin-top: -8px !important;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    border: 1px solid #ebecf1;
    border-radius: 50px;
    padding: 6px;
    margin-left: 0;
}
.sidenavUL{
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    border-right: 1px solid #eeeeee;
    color: black;
    width: 9% ;
    top: 52px;
    bottom: 0;
    position: fixed;
    z-index: 10;
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0px;
    overflow: hidden;
    background: #fff;
    transition: 0s;
    box-shadow: 0 0px 0px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
}
.subMenu{
    background: #fff;
    opacity: 1; 
    color: black;   
    position: fixed;
    z-index: 99;  
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    /* height: 250px !important; */
    /* overflow-y: scroll;
    overflow-x: hidden; */
}
.subDivli{    
    border-radius: 6px;
    list-style-type: none; 
    color: black;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-size: 0.81rem;
    cursor: pointer;
    padding: 12px 24px;
    transition: all .15s ease-in;
    cursor: pointer;
} 
.subDivli:hover{
    background: #eee !important;
    color: #34ace0;
} 
.subDivli:hover .SubMenuIcon{
    background: #eee !important;
    color: #34ace0;
} 
.arrow{
    float: right;
    font-size: 17px !important;
    /* position: absolute;
    right: 5px; */
    /* margin-top: 4; */
}
.expandNav{
    position: absolute;
    right: 0px;
    /* height: 100%; */
    width: 95%;
    transition: 0s;
}
.sidenavLI{ 
    cursor:pointer;    
    height:auto !important;
    padding:10%;
    border-bottom:1px solid #dee2e6;
}
.sidenavSpan{
    text-align: center;
    font-size: 0.81rem;
    display: block;
    font-weight: 400;
}
.sidenavSpan :hover{
    background-color: transparent !important;
}
.MenuIcon{
    color: black;
    font-size: 40px !important;
    height: 32px !important;
    width: 32px !important;
    display: block !important;
    margin: 0 auto 6px !important;
}
.SubMenuIcon{
    color: #337ab7;
    font-size: 0.88rem !important;
    margin-top: 6px;
    /* display: block !important;
    margin-right: 15px ;
    margin-top: 1px; */
}
/* .spanTab .SubMenuIcon{
    margin-right: 0px !important;
} */
.tabActive .SubMenuIcon {
    color: #fff;
}   
.liDiv{ 
    /* padding-top: 20px; */    
    /* padding-bottom: 20px !important; */
    margin: 8px 0px 0px;
    border-radius: 6; 
    position: relative;
}
/* .liDiv :hover{
    color: #34ace0 !important;
    background-color: rgba(200, 200, 200, 0.2);
    cursor: pointer;  
}    */
  
.sidenavUL :hover
.MenuIcon{     
    color: #34ace0; 
}
.sidenavUL :hover
.sidenavSpan{     
    color: #34ace0; 
}
.active .MenuIcon{
    color: #fff !important;
} 
.active .sidenavSpan{
    color: #fff !important;
}
.sidenavLI .active {
    background-color: #34ace0 !important;
    border-radius: 4px !important;
}
.active{
    color: white; 
    margin-bottom: 0px;
    box-shadow: none !important;
    -webkit-user-select:none;
            user-select:none;
    /* box-shadow: 0 12px 20px -10px rgba(119, 136, 138, 0.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(104, 112, 113, 0.2) !important; */
} 
.active
.triangle{
    position: absolute;
    left: 100%;
    top: 30%;
    border-color: transparent #34ace0;
	border-style: solid;
	border-width: 10px 10px 10px 0px;
	height: 0px;
	width: 0px;
}
.subActive{
    color: #34ace0 !important;
    /* box-shadow: 0 12px 20px -10px rgba(119, 136, 138, 0.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(104, 112, 113, 0.2) !important; */
    background-color: #fff !important;
} 

.subActive .SubMenuIcon{
    color: #34ace0 !important; 
} 
.subActive2{
    color: #34ace0 !important;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    background-color: #fff !important;
} 
    
.subActive3{
    color: #34ace0 !important;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
    background-color: #fff !important;
}  
.subMenu2{
    background: #fff;
    opacity: 1;
    width: 200;
    color: black;   
    position: fixed;
    z-index: 99;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);

}
.subMenu3{
    background: #fff;
    opacity: 1;
    width: 200;
    color: black;   
    position: fixed;
    z-index: 99;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08);
}
.closeBtn{
    color: #d00e0e;
    padding-left: 0px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 6px;
    font-size: 16px !important;
}
.liTabs:hover .closeBtn{
    /* background: #ccd3d6; */
    border-radius: 50%;
}

.liTabs{
    font-size: 0.81rem; /*.75rem;*/
    opacity: 1;
    border-radius: 0;
    cursor: pointer;
    padding: 0.2rem 0.35rem 0px;
    border: none;
    height: 35px;
    margin: 0.45rem 0.25rem 0.5rem 0;
    list-style-type: none;
    background-color: #fff; 
    font-weight: 400;
    min-width: 11%;
    /* border-top-left-radius: 5% !important; /
    / border-top-right-radius: 5% !important; /
    / border-bottom-right-radius: 5% !important; /
    / border-bottom-left-radius: 5% !important; */
    border-radius: 6px;
    box-shadow: 0 2px 0px 0 rgba(0,0,0,.14), 0 3px 0px -2px rgba(0,0,0,.2), 0 1px 0px 0 rgba(0,0,0,.12);
    color: #337ab7;
    } 




/* New style changes */
/* .menu-search{
    margin-top: 5px !important;
} */
.menuContent{
    padding: 0% 1%;
    margin-top: 56px;
    height: 92%;
}

.Logomenu{
    width: 68px;
    padding: 7px 14px;
    background: #251E8F;
    height: 52px;
}

.LogomenuExp{
    width: 123px;
    padding: 7px 0px;
    background: #251E8F;
    height: 52px;
}
@media screen and (max-width:1280px) {
    .Logomenu{
        width: 51px;
        padding: 12px 14px;
    }
    .LogomenuExp{
        width: 93px;
    }
}

 
.GridViewbtn{
    position: relative !important;
    display: flex;
    bottom: 5px;
}
.DocExportBtn{
    position: absolute;
    right: 12px;
    top: 20px;
} 
.TblViewBtn{
    font-size: 10px !important;
    border: none;
    outline: none;
    padding: 10px 16px;
    background-color: #f1f1f1;
    color: #ccc !important;
    cursor: pointer;
} 
.TblViewAct{
    background-color: #fff !important;
    color: #34ace0 !important;
} 
.DocStoreTable .ant-table{
    height: 35vh !important;
}
.DocStoreTableV .ant-table{
    height: 38vh !important;
}
.DocViewTable{
    margin-top: -20px ;
}
/* Dov View Styles */

.DocviewCard{
    background: #f0f5f5 !important;
} 
.highLight{
    color: #34ace0 !important;
} 
.DocViewHead{
    font-size: 0.75rem;
}
.DocViewHead span{
    float: right;
}
.DocViewBody{
    font-size: 0.75rem;
    font-weight: bold;
}
.pTag{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.DocGridName{
    font-size: 0.688rem;
    font-weight: bold;
    margin: 0px;
    height: 40px;
    /* padding-top: 15px; */
}
.DocGridCnt{
    color: #337ab7 !important;
    cursor: pointer !important;
    font-size: 2.188rem !important;
    margin: 0px;
    line-height: 60px;
}
.DocCircleDiv{
    text-align: -webkit-center;
}  
.AddBtndiv{
    display: flex;
}
.MuiButton-startIcon {
    margin-right: 4px !important;
    margin-bottom: 2px;
}
.BrowseBtn{
    padding: 7px 12px !important;
}
.borderstyle {
    padding: 0% 1% !important;
    border: 1px solid #ebecf1;
    margin: 0.5% 0% 0.5% 0% !important
}
.font8rem{
    font-size: 0.8rem !important;
}
/* Close Button Icon */
.CloseBtnCommon{ 
    background: #337ab7 !important;
    color: #fff !important;
    min-width: 30px !important;
    width: 30px;
    height: 30px;
    margin: 0px 6px!important;
    float: left;
    padding: 0!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    border: 1px solid #337ab7!important;
}

.CloseBtnIcon{
    left: 4px;
    height: 20px!important;
    width: 20px!important;
    margin-right: 0!important;
    top: 0.04rem !important;
}
.CloseBtndiv{
    position: absolute;
    top: -25px;
    right: 15px;
}
.GenBtnCommon{
    width: 120px !important;
}
/* Save Button Icon */
.SaveBtnCommon{
    background: #337ab7 !important;
    color: white !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
/*browser button*/
.browsebtnCommon{
    background: #337ab7 !important;
    width: 120px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
.ApplyButton{
    padding: 5px !important;
    background: brown !important;
}
/* Add Button Icon */
.AddBtnCommon{
    background: #337ab7 !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
.L1AddBtnCommon{
    background: #337ab7 !important;
    color: white !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
.L2AddBtnCommon{
    background: #337ab7 !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 10px !important;
    margin-left: 2% !important;
}
.AddBtnIcon{
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}

.PrintBtnIcon{ 
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}
.SubmitBtnIcon{
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}
.add-btn-outer-div{
    display: flex;
}
.add-export-btn{
    position: relative; 
    top: 20px;
    left: 86%;
}


/* New Styles */
.custButtons{
    background: #34ace0 !important;
    padding: 5px 10px !important;
}
.ViewHeadpTag{
    color: black;
    line-height: 1.5;
}
p{
    margin: 0px !important;
}
.ViewHeadpTag span{
    float: right;
    padding-right: 3px;
}
.ViewHeadpTagB{
        width: 80%;
}
.ViewBodypTag{
    /* font-weight: bold; */
    color: black;
   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;      /* IE */
}
.viewgridpad{
    padding: 1% 0px !important;
}
.ListViewHead{
    font-size: 0.625rem;
    color: #2F2725;
}
.ListViewDiv{
    padding-top: 14px;
}
.ListViewBody{
    font-size: 0.563rem;
    font-style: italic !important;
    color: black;
}
.ViewHightLight{
    font-size: 0.75rem;
    font-weight: 100;
    padding-left: 5%;
}
.ViewHightLightBlue{
    color: #34ace0 !important;
    font-weight: bold;
}
.ViewBodyForeignTag{    
    color: orange;
    cursor: pointer;
}
.ViewBodyForeignTag:hover{
    text-decoration: underline;
}
.ViewHeadingTitle{    
    background: #fff !important;
    color: #337ab7 !important;
    font-size: 13px !important; /*0.8rem*/
    /* border-radius: 5px !important; */
    padding: 0px;
    padding-left: 0px;
    margin-top: 15px;
    margin-bottom: 14px;
    font-weight: 600;
    /* border-bottom: 1px solid #ebecf1; */
}
.GrpViewHeadingTitle{
    background: #337ab7 !important;
    color: #fff !important; 
    border-radius: 5px !important;
    padding: 3px 10px;
    margin-top: -10px;
    margin-bottom: 14px;
    font-size: 0.75rem !important;
    font-weight: bold;
}
.GrpViewHeadingTitle p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cardheadvh{
    height: 0px;
}
.ViewHeading{
    font-weight: bold;
}
.ViewHeading span{
    float: right;
}


.ViewTableCardBody{
    padding: 2px !important;
}
.ExportBtndiv{
    position: relative;
    top: 5px;
    left: 5px;
 
}
.GridViewChild{
    height:54vh;
    overflow-y:hidden;
    overflow-x: hidden;
    background: #eee;
    border-left: 1px solid #ccc; 
}
.GridViewChild:hover{
    overflow-y:auto !important;
}
.GridViewChildCard{
    /* height:calc(100vh - 170px); */
    overflow-y:auto;
    overflow-x: hidden;
}
.GridViewHeader{
    height: 32px;
    background: #EDEDED;
    margin-bottom: 2px;
    background: #337ab7;
    border-left: 1px solid #fff;
    /* display:flex */
}
.BubbleViewChild{
    text-align: center;
    border-left: 1px solid #e3e4e9;
    padding-left: 5px;
}
.inputborder{
    padding: 0px 11px
}
.borderInp{
    border: 1px solid #fff !important;
    margin: 6px 2px !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    line-height: 15px !important;
    background: #c4cbf5 !important;
}
::placeholder { /* Internet Explorer 10-11 */
    color: #fff;
   }
.GridViewHeaderTitle{
    padding-top: 6px;
    padding-left: 10px !important;
    /* padding: 2px; */
    font-size: 0.75rem;
    color: #fff;
    width: 80%;
}
.GridViewCard{
    width: 70% !important;
    height: 70% !important;
    text-align: center;
    margin: 15px 0px 15px 10px !important;
}
.LeftBorderBlue{
    border: 1px solid #ddd  ;
    border-left: 4px solid #337ab7 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; 
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    cursor: pointer;
    background: #fff;
    margin: 3px 5px;
}
.LeftBorderGreen{
    border: 1px solid #ddd  ;
    border-left: 4px solid #337ab7 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; 
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: grid;
    cursor: pointer;
    margin: 3px 5px;
}
.LeftBorderBlue p,h6{
    padding-left: 20px;
}
/* .TableCommonSearch{
    height: 10px !important;
} */
.homeButton{
    color: #A9A9A9;
    margin-top: 16px;
    cursor: pointer !important;
    margin-left: 5px;
}
.ViewCard{
    color: #fff !important;
} 
/*.RoundedInput::-webkit-input-placeholder { 
    padding-left: 10px;
    font-size: 0.625rem;
    color: #e4e4e4 !important;
 } */

 /* Pagination CSS */
 .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a {
    display: block;
    font-size: 0.75rem;
    margin: -9px -13px;
    padding: 3px 12px;
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.3s;
}
.ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 0.625rem;
    padding: 0px !important;
    font-size: 10px !important;
    height: 20px;
}
.ant-pagination{
    margin-top: 5px !important;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;    
    width: 50%;
    font-size: 0.625rem;
    margin-right: 0px !important;
    padding: 0px 2px !important;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;
}
.ant-pagination{
    font-size: 10px !important;
}
.ant-pagination-prev{
    margin-right: 0 !important;
} 
.ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 0px;
}
.ant-dropdown-trigger{
    font-size: 12px !important;
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.65);
}
.ant-select-item-option-content{
    font-size: 0.625rem;
}
.ant-select-selector{
    margin-top: 4px;
}
.ant-select-selection-item{
    font-size: 0.75rem;
}

.GridViewDiv{
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    border-bottom: none;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
}
.gridactive{
    background: #666666
}
#GridViewCard{
    width: 100%;
    padding: 0px 10px;
    transition: 0.5s;
    height: 60vh;
}
#GridViewCardChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s; 
    border-right: 1px solid #e0d3d3;
}
#GridViewCardSubChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s; 
}
.MoreDataDiv{
    height: 30px;
    width: 100%;
    /* background: #ddd; */
    color: blue;
    cursor: pointer;
    padding-top: 5px;
    padding-left: 5px;
}
.MoreDataDiv p {
    text-decoration: underline;
}
/* .BubbleViewChart{
} */
div.tooltip {	
    display:inline-block;
    position:relative;
    text-align:left;
    margin:12px 0; 
    min-width:200px;
    max-width:400px;
    top:-20px;
    left:50%;
    transform:translate(-30%,-100%);
    padding:10px 20px;
    color:black;
    background-color:#fff;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);  
    display:block;
} 
.svg-p{
    width: 40px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    border: 1px solid #ddd;
    margin: 0;
}
.svg-html{
    background-color: transparent;
} 
.svg-body{
    color: #fff;
    background: transparent !important;
    width: auto !important;
}
ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    /* background-color: #eee; */
  }
  
  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
    font-size: 0.75rem;
  }
  
  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  
/* Add a color to all links inside the list */
ul.breadcrumb li  {
    color: #0275d8;
    text-decoration: none;
}
ul.breadcrumb span{
    font-size: 0.75rem;
    font-weight: bold;
}  
#ShareViewCard{
    width: 100%;
    padding: 0px 10px;
    transition: 0.5s;
}
#ShareViewCardChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s;
}
#ShareViewCardChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s;
}
#ProjectRegistryTree{
    transition: 0.5s;
    position: relative;
    width: 100%;
}
.CustomizeDiv{
    width: 50%;
    padding: 8px;
    border-radius: 5px;
    background:linear-gradient(45deg,#ff4757,#ff7675) !important;
    color: #fff;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
    display: flex;
}
.CustomizeIcon{
    font-size: 18px !important;
    margin-right: 8px;
}
.ApplyDiv{
    width: 50%;
    padding: 8px;
    border-radius: 5px;
    background: linear-gradient(45deg,#00b894,#55efc4) !important;
    color: #fff;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
    display: flex;
}
.CustomizeDiv:hover
.ApplyDiv:hover{
    box-shadow: 0 14px 26px -12px rgba(2, 177, 82, 0.42), 0 4px 23px 0px rgba(2, 177, 82, 0.12), 0 8px 10px -5px rgba(2, 177, 82, 0.2);
}
.ClearButton{
    background: #ef4b4b !important;
    color: white !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-right: 0% !important;
}
@media screen and (max-width:1280px) {
    .ClearButton{
        width: 65px;
    }
}
.FilterSave{
    background: #fff !important;
    color: #0275d8 !important;
}

#BubbleViewChild{
    width: 0%;
    display: none;
}
#BubbleViewSubChild{
    width: 0%;
    display: none;
}

/* Draggable */
.drag-checkbox{
    margin-left: 15px !important;
}
.DragColumnAccordian{
    background-color: #ffffff !important;
}
.floating .item {
    background-color: #ffffff !important;
    box-shadow: 0 4px 20px #666666 !important;
}

.dl-item.floating {
    width: 85%;
}
.table-row-active{
    background-color: red !important; 
    color: #fff !important;
}
.MuiAccordionSummary-root {
    min-height: 30px !important;
}
.MuiAccordionSummary-content {
    margin: 0 !important;
}
.MuiPaginationItem-root {
    color: #666666 !important;
    height: 30px !important;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 12px !important;
    min-width: 22px !important;
    border-radius: 3px !important;
}
.MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff !important;
    background-color: #337ab7 !important;
    height: 24px !important;
}
.MuiPaginationItem-ellipsis {
    height: auto !important;
}
/* .MuiSelect-select.MuiSelect-select {
    padding-right: 23px !important;
    padding-bottom: 0px !important;
    padding-top: 1px !important;
} */
.per-page{    
    margin-top: 5px !important;
    margin-left: 5px !important;
    font-size: 0.75rem;
}
.AppointmentBtnIcon{
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}

/*   My Styles */

.L1AppointmentBtnCommon{
    background: #191970 !important;
    width:40%;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}
.L1AppointmentSaveBtnCommon {
    background: #191970 !important;
    height: 30px !important;
    margin-top: 0px !important; 
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: bold; 
    text-transform: capitalize !important; 
    text-align: center !important;
}
.width100{
    width: 100% !important;
}
.width135px{
    width: 135px;
}
/* .ClearButton{
    background: #ef4b4b !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    top:100% !important;
    margin-left:10px !important;
    margin-right: 0% !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
} */

.sweetAlertBtn {
    box-shadow: none !important;
    color: white !important;
    cursor: pointer;
    font-size: 12px !important;
    text-transform: uppercase;
    padding: 12px 30px !important;
    font-weight: 400 !important;
    margin-top: 10px !important;
}

.sweetAlertBtnSuccess {
    background-color: #4caf50 !important;
    margin-right: 100px !important;
    margin-left: 0px !important;
}

.sweetAlertBtnSuccessInfo {
    background-color: #4caf50 !important;
    margin-right: 8px !important;
    margin-left: 0px !important;
}

.sweetAlertBtnCancel {
    background-color: #f44336 !important;
}

.SaveBtnCommon, .ClearButton {
    min-width: 104px !important;
    margin-left: 15px !important;
}

.disabledCheckboxBlue .MuiFormControlLabel-label.Mui-disabled {
    color: #337ab7 !important;
}

.disabledCheckboxBlue .Mui-disabled {
    opacity: 1 !important;
}

.CloseBtnCommon {
    padding-left: 8px !important;
}

.VerifyBtn {
    margin-left: 0px !important;
}

.Mui-disabled {
    opacity: 0.5;
}
#floatButton{
    position: fixed;
    right: 5px;
    top: 50%;
    transition: 0.4s;
    z-index: 9999;
    cursor: pointer; 
} 
#ThemeSlide{
    width: 0px;
    cursor: pointer;
    height: 91%; 
    margin-top: 25px;
    position: fixed; 
    z-index: 100;
    top: 28px;
    right: 0px;
    background: #fff;
    overflow-x: hidden;
    transition: 0.4s;
    border-left: 1px solid rgba(0,0,0,.05);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
}

.Theme-Heading{
    text-align: left;
}
.Theme-Heading h4{
    margin-top: 20px !important;
    margin:5px;
    font-weight: 600;
    color: #727E8C;
}
.Theme-card-shadow{
    display: flex;
}
.Theme-card-shadow-switch{
    margin-top:10px    
}
.color-box-div{
    display: flex;
    margin: 10px;
}
.color-box{ 
    height: 35px;
    width: 35px;
    margin: .5rem;
    border-radius: .5rem;
    cursor: pointer;
}
.color-box-blue{
    background: #5A8DEE !important;
}
.color-box-green{
    background: #39DA8A !important;
}
.color-box-red{
    background: #FF5B5C !important;
}
.color-box-skyblue{
    background: #00CFDD !important;
}
.color-box-orange{
    background: #272E48 !important;
}
.color-box-dark{
    background: #fff !important;
    border: 1px solid #727E8C;
}
.Act-clr-btn{
    box-shadow: 0 0 0 3px rgba(52,144,220,.5);
}  
.DragCheckbox{
    padding: 0px !important;
}
::placeholder {
    color: #8A99B5;

  }
.flexEnd{
    display: flex;
    align-items: flex-end;
}

/* .MuiFormControlLabel-root
{
    padding: 9px !important;
}
.PrivateSwitchBase-root{
    padding: 0px !important;
} */
  .AttachScroll{
    padding: 0px 10px 10px;
    border: 1px solid rgb(204, 204, 204);
    height: 93vh;
    overflow: hidden;
    overflow-y: scroll;
  }

/* card css */
.detailCard{
    /* box-shadow: 0 4px 20px 1px rgba(0,0,0,.0), 0 1px 4px rgba(0,0,0,.08); */
    /* border: 1px solid #eee; */
    transition: 0.3s;
    display: grid;
    /* border-radius: 6px; */
    /* margin: 0 0px 0 4px; */
    /* z-index: 2; */
    /* height: 77vh; */
}
.detailDiv{
    width: 100% !important;
    /* margin-left: 4px !important; */
    /* height: 100%; */
    /* padding: 2px 15px; */
    margin: 0;
}
.ListHeader{
    /* padding: 2px 0 !important; */
    cursor: pointer;
    width: 100%;
}
.ListHeader .ListP{ 
    padding: 6px 0px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #337ab7 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.EntryHeader .ListP{ 
    padding: 8px 0px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #337ab7 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.EntryHeader .ListPlevl1 {
     
    color: #337ab7 !important;
}
.EntryViewHeader .ListP{ 
    padding-top: 7px;
    padding-left: 0px;
    font-size: 0.88rem;/*0.8rem;*/
    font-weight: bold;
    color: #337ab7 !important;
}
.EntryHeader{
    margin: 2px 6px !important;
    /* width: 30%; */
}
.EntryHeaderBtn{
    /* width: 20%; */
    margin-left: auto !important;
}
.EntryViewHeader{
    margin: 2px 0 !important;
    width: 70%;
}
.EntryHeaderViewBtn{
    margin-left: auto !important;
}

.backgroundWhite{
    background: #fff;
    /* background: #F0F9FF; */
}
.backgroundDark{
    background: #fff;
    /* background: #F0F9FF; */
}
.backgroundLightLev1{
    background: #fff;
    height: 42px;
}

.backgroundLight{
    background: #fff;
    /* #3d4977; */
    height: 42px;
}
.backgroundYellow{
    background: #ffda79;
}
.backgroundGreen{
    background: #5BB35F;
}
.infoIcon{
    font-size: 15px !important;
    color: #cd6133 ;
    margin: 5px;
}
.backgroundPurple{
    background: #864C99;
}

.viewTypes{
    position: relative;
    top: 2px;
    left: 2%;  
    width: 100%;
}


/* Common search css */
.searchbar{
    height: 30px;
    margin-top: 0px !important;
    width: 100%;
    background-color: #fff;
    /* border-radius: 30px; */
    /* padding: 10px; */
    font-size: 0.8rem;
    border: 1px solid #ebecf1;
}

.search_input{
    color: black;
    border: 0;
    outline: 0;
    background: none;  
    /* line-height: 10px; */
    padding: 4px 15px 0 15px;
    width: 100%;    
}

.search_icon{
    float: right !important;
    padding: 0rem 0.5rem 1rem 0.5rem;
    cursor: pointer !important;
    margin-top: -24px;
    margin-right: 0px;
    border-radius: 0px !important;
    background: #337ab7 !important;
    color: #fff !important;
    text-decoration: none !important;
    position: relative;
    left: 0px;
    height: 29px;
}


/* Common search css */
.searchbard2{
    height: 30px;
    margin-top: 0px !important;
    width: 100%;
    background-color: #eeee;
    border-radius: 8px;
    /* padding: 10px; */
    font-size: 0.8rem;
    display:flex;
    /* border: 1px solid #ebecf1; */
}


.search_icond2{
    /* float: right !important; */
    padding: 0rem 0.5rem 1rem 0.5rem;
    cursor: pointer !important;
    /* margin-top: -22px; */
    margin-right: 0px;
    border-radius: 0px !important;
    /* background: #337ab7 !important; */
    color: #fff !important;
    text-decoration: none !important;
    position: relative;
    left: 0px;
    height: 29px;
    border-right: 1px solid #000;

}

.InputSearchIcond2{ 
    width: 20px !important;
    cursor: pointer;
    color: #000 !important;
    padding-top: 4px;
}

.CommonSearchInputDiv{
    position: relative;
    width: 100% !important;
    height: 0px;
}
.RoundedInput{
    border-radius: 20px;
    border: 1px solid #5C69E1;
    width: 100%;
    padding-left: 15px;
}
.InputSearchIcon{ 
    width: 20px !important;
    cursor: pointer;
    color: #fff !important;
    padding-top: 4px;
}
.SearchIconDiv{
    background: #5c69e1 !important;
    position: absolute;
    /* width: fit-content; */
    padding: 0 12px;
    left: 91%;
    border-radius: 20px;
    bottom: 15px;
    text-align: center;
}
.InputSearchClearIcon{
    width: 20px !important;
    cursor: pointer;
    color: #fff !important;
}
.borderRadiusTop{
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
.height85{
    height: 85vh;
}
.floatLeft{
    float: left;
    border-right: 1px solid #ccc;
}
.floatLeftOnly {
    float: left;
}
.displayNone{
    display: none !important;
}
.displayFlex{
    display: flex !important;
}
.displayGrid{
    display: grid !important;
}
.componentMainDiv{
    /* height: 80vh; */
    /* margin-top: 3%; */
    padding-bottom: 1%;
    border-bottom: 1px solid #e3e4e9;
}
.floatOrder{
    margin-left: auto !important;
    order: 2 !important;
}
.padding{
    padding: 5px !important;
}
.padding2{
    padding: 2px !important;
}
.padding0{
    padding: 0px !important;
}
.paddingLeft{
    padding-left: 15px !important;
}
.scrollYDefault{
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.scrollY{
    overflow-y: auto;
    overflow-x: auto;    
}
.scrollY:hover{
    overflow-y: auto;
    overflow-x: auto;
}

.PaginationDropDown{
    /* margin-top: -20px !important; */
    /* margin: 0px !important; */
    position: relative !important;
    top: -5px !important;
}
.tableFilterColumnIcon{
    color: #444 !important;
    cursor: pointer !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: 3px;
    transition: all 0.3s ease-out !important;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #e3e4e9;
    padding: 16%;
}
.tableFilterColumnIcon:hover{
    border: 1px solid #337ab7;
    color: #337ab7 !important;
    background: #fbfbfb;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
}
.tablExpicon{
    /* margin-left: 10px !important; */
    /* padding: 5% !important; */
}
.iconinfo{
    padding-left: 5px;
    font-size: 0.625rem;
}
.buttoncard{
    display: inline-block;
    height: 35px;
    width: 35px;
    float: left;
    margin: 0 5px;
    overflow: hidden;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    /* box-shadow: 0px 10px 10px rgba(0,0,0,0.1); */
    transition: all 0.3s ease-out;
    -webkit-user-select: none;
            user-select: none;
  }
  /* .buttoncard:hover{
    width: 90px;
  } */

.tableExpandIcon{
    color: #337ab7 !important;     
    cursor: pointer !important; 
}  
/* Sort Css */
.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #34ace0 !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
    color: #34ace0 !important;
}
/* draft 
.badge{
    margin:0px 12px 0 10px !important;
}
.badge span{
    height: 15px;
    min-width: 12px;
    font-size: 0.563rem;
    font-weight: bold;
}*/

.boxShadowNone{
    box-shadow: none !important;
}

.ColumnApplyIcon{
    color: #fff;
    /* padding-bottom: 4px !important; */
    font-size: 12px !important;
}
.ColumnApplyTitle{
    color: #fff !important;
    font-size: 9px !important;
    font-weight: 600;
}
.ColumnApplyButton{
    background: #337ab7 !important;
    margin-left: 2px !important;
    padding: 0px !important;
    height: 20px;
    border: 1px solid #fff !important;
    margin-top: 2px !important;
}
.DragBackGround:hover {
    background: #ccc;
}

/* Column css */
.MUICheckbox .MuiSvgIcon-root {
height: 19px !important;
}
.columnDiv{
    /* border: 1px solid #ddd; */
    margin: 0 0px 0 0; 
}
.DropdownDiv{
    margin: 0 5px 0 0; 
}
.columnHeaderIcon{
    color: #337ab7 !important;
    font-size: 1rem !important;
    margin: 4px 5px;
}
.columnHeader{
    color: #337ab7;
    padding: 7px 5px;
    font-size: 0.75rem;
    background-color: #fff;
    border-bottom: 1px solid #e3e4e9;
}
.columnHeaderbtn{
    width: 35%; 
    /* padding: 2px;    */
    margin: 0px !important;
}
.columnFilterHeaderTitle{    
    width: 100%;
    display: flex;
    padding: 0 0px;
}
.columnHeaderTitle{    
    width: 65%;
    display: flex;
    padding: 0 0px;
}
.columnHeaderp{
    color: #337ab7;
    font-weight: 600;
    font-size: 0.75rem;
    margin-left: 0px !important;
    padding-top: 3px;
}
.columnHeaderdesc{
    font-size: 0.563rem;
    font-weight: bold;
    color:#cd6133;
    margin-top: 5px !important;
    margin-left: 2px !important;
}
.columnCheckboxDiv{
    display: grid;
    height: 64vh; 
    border: 2px solid #eee;
}
.dragCheckboxDiv{
    height: 53vh;
    overflow: auto;
    /* position: absolute !important; */
}
.dragCheckboxDivL1{
    height: 50vh;
    overflow: auto;
}
.DragDrop .dl-list{
    height: auto !important;
}
.dragItem{
    font-family: sans-serif; 
    /* background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 4px;
    padding: 0px;
    display: flex; */
    margin-bottom: 4px;    
}
.dragListItem{
    background: #EBEEF1;
} 
.activeDragListItem .check-list-item {
    background: #EBEEF1 !important;
}
.activeDragItem .check-item {
    /* background: #C7E7CC !important; */
    background: #ebfaeb !important;
}
.check-item{
    /* background:#fff; */
    /* border: 1px solid #ddd; */
    /* border-radius: 5px; */
    padding: 3px 15px; 
}
.check-list-item{
    margin: 2px;
    padding: 3px 15px; 
}
.backButtonList{
    color: #337ab7;
    margin-top: 1px;
    margin-right: 5px;
    font-size: 22px !important;
    margin-left: 10%;
}
.ViewBtn.ViewBtnAct{
    background: rgba(202, 202, 202, 0.34) radial-gradient(circle, transparent 1%, rgba(80, 80, 80, 0.05) 0) 50%/15000% !important;
    color: #337ab7 !important;
}
.ViewBtn{
    font-size: 10px !important;
    border: none;
    outline: none;
    padding: 2px 16px !important;
    background-color: #f1f1f1;
    color: #337ab7 !important;
    cursor: pointer; 
    border-radius: 0 !important;
} 
/* Customer Enq field css */
.ArrowIcons{
    cursor: pointer;
    color: #337ab7;
    margin: 5px 5px !important;
}
.ArrowIconsLight{
    cursor: pointer;
    color: #337ab7 !important;
    margin: 5px 5px !important;
}
.FullSizeIcon{
    cursor: pointer;
    color: #337ab7;
    margin: 10px !important;    
    font-size: 18px !important;
}

/* Floating Button */
.MuiFab-root {
    width: 30px !important;
    height: 30px !important;
}
.MuiFab-primary {
    background-color: #34ace0 !important;
}
.MuiSpeedDialIcon-icon {
    position: absolute;
    left: 3px;
    padding: 2px;
}

/* New Design CSS */
.MainDivStyles{
    padding-top: 1.5rem;
    margin-bottom: 1%;
    width: 100%;
}


/* Accordian Css */
.MuiAccordionSummary-root {
    padding: 0 !important;
    border-bottom: 1px solid #e3e4e9 !important;

}
.MuiAccordionDetails-root {
    padding: 0px !important;
}
.MuiAccordion-root.Mui-expanded{
    margin: 0px !important;
}
.MuiAccordion-root:before{
    content: none !important;
}
/* View Details */
.ViewDetailLabel{
    font-size: 0.688rem;
    color: black;
    font-style: italic;
}
.ViewDetailLabel span{
    float: right;
}
.ViewDetailText{
    font-size: 0.75rem;
    font-weight: 600;
    font-style: italic;
}
/* Filter Slide */
.level2DropDown{
    /* height: 30vh; */
    /* margin: 0 12px; */
}
.FilterParentDiv{
    background-color: initial;
    border: none;
    /* border-bottom: 1px solid #E6E6E6; */
    position: relative;
    width: 100%;
    min-height: 20px;
    max-height: 200px;
    /* overflow-y: auto; */
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 20px;
    outline: none;
    transition: border-color .2s ease-out;
}
.FilterParentDiv ul{
    padding: 5px 10px;
    margin-bottom: 0px;
}
.heightScrollY{
    overflow-y: auto;
    min-height: 20px;
    max-height: 120px;
}
div[data-list-scroll-container] {
    transform: translateZ(0);
}
.filterSelectedItems{
    /* margin-right: 6px; */
    margin: 3px;
    padding: 0;
    background: #EBFAEB;
    color: #222F3E;
    border-radius: 6px;
    cursor: default;

    display: inline-flex;
    height: auto;
    vertical-align: top;

    position: relative;
    /* display: flex; */
    flex-direction: row;
    /* height: 72px !important; */
    pointer-events: all;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    /* min-width: 100px; */
}
.filterSelectedItemNames{
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;

    padding: 0;
    border-top: none!important;

    max-width: 250px;
}
.filterSelectedItemNames span{
    display: inherit;
    overflow: inherit;
    white-space: inherit;
    text-overflow: inherit;

    display: inline-block;

    position: relative;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    visibility: visible;
    margin-left: 15px;
    margin-top: 2px;
}
.filterSelectedItemClose{
    padding: 0;
    border-top: none!important;
    background: #ddd;
    font-weight: 400!important;
    font-size: 13.5px;

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    align-self: center;
    height: 100%;

    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;

    margin-left: 6px;
    margin: 4px;
    padding: 1px;
    border-radius: 50%;
    flex: none;
}
.filterSelectedItemCloseIcon{
    color: #8898aa;
    font-size: 13px !important;
    cursor: pointer;
}
.filterSelectedItemCloseIcon:hover{
    color: black;
}
.filterSearchInput{
    width: 366px;

    margin: 5px;
    padding: 2px 12px 2px 25px;
    background: #F3F6F9;
    border: 1px solid #ddd; 
    display: inline-block;
    width: 100%;
    height: auto;
    color: var(--primary-text);
    font-size: 0.625rem;
    line-height: 22px;
    vertical-align: top;
    outline: none;

    -webkit-user-select: text;

            user-select: text;
}
.FilterItemDiv{
    padding: 5px;
    border-bottom: 1px solid #ebecf1;
    cursor: pointer;
    font-size: 0.75rem;
    cursor: pointer;    
}
li .FilterItemDiv{
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
}
.FilterItemDivBody{        
    padding: 0 0 0 0px;
    height: 30vh;    
}
.FilterItemDiv:hover{
    background: #F3F6F9;
    color: #337ab7;
    font-weight: 600;
    font-size: 0.813rem;
    width: 100%;
}
.FilterItemDivHeader{
    width: 100%;
    /* margin: 0 12px;     */
    padding: 2px 0 2px 10px;
    border-bottom: 1px solid #e6e6e6; 
    font-size: 0.75rem;
    font-weight: 500;
    background: #337ab7;
    color: #fff;    
}
.widthfit{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;    
}
.width10{
    width: 10%;    
}
.width20{
    width:20%;
}
.width30{
    width: 30%;
}
.width40{
    width: 40%;
}
.width50{
    width: 50%;
}
.width60{
    width: 60%;
}
.width70{
    width: 70%;
}
.width80{
    width:80%;    
}
.width90{
    width: 90%;
}
.width100{
    width: 100% !important;
}
.circleRound{
    background: #02B152 !important;
    border-radius: 4px;
    margin: 5px;
    padding-top: 3px;
    padding-left: 6px;    
    color: #fff !important;
    box-shadow: 0 1px 4px 0 #eee;
    cursor: pointer;
    /* height: 20px; */
}
.circleRoundIcon{
    font-size: 18px !important;   
    padding-right: 3px;
}
.MuiTablePagination-actions button{
    padding: 4px !important;    
   }
.MuiTablePagination-actions{
    margin-left: 0 !important;
}
   #overlayimgAtt {
    cursor: pointer;
    height: 100%;
    width: 0px; 
    overflow-x: hidden;
    display: none;
   }
    /* position: fixed; */
    /* z-index: 20000;
    top: 20;
    right: 0;
    background-color: #F2F2F2;
     */
    /* transition: 0.2s; */
    /* padding-top: 60px; */
  /* }

/* .MuiButtonBase-root span{
margin: 4px !important;

} */

/* customise upload list */
.customise-upload{
    float: left;
    width: 100%;
    margin-right: 8px;
    position: relative;
    height: 52px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 2%;
}
.custom-item-info {
    padding: 0;
}
.imgOuter {
    position: absolute;
    top: 8px;
    left: 8px;
    /* width: 48px; */
    height: 48px;
    line-height: 54px;
    text-align: center;
    opacity: .8;
    display: flex;
    flex-direction: row;
}
.itemImage {
    display: block;
    width: 36px;
    height: 36px;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    margin-right: 5px;
}
.list-item-name {
    /* color: #777;
    padding-right: 18px;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 37px;
     white-space: nowrap;  
    text-overflow: ellipsis;
    -webkit-transition: all .3s;
    width: 80%;
    transition: all .3s; */
    font-size: 0.6rem;
}
.ViewHeadingcontent{
    background: #337ab7 !important;
    color: #fff !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    padding: 0px 20px;
    /* padding-left: 9px; */
    margin-top: 15px;
    margin-bottom: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}
.card-actions{
    position: absolute;
    right: 0;
    top: 18px;
    line-height: 0;
    margin-right: 5px;
}
.actionBtn {
    opacity: 1;
    height: 20px;
    line-height: 1;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 0.88rem;
    border-radius: 2px;

}
.ViewClosebtn {
    z-index: 5;
    margin-left: auto;
}
.ImgPreview{
    background-color: hsla(0, 0%, 100%, 0.96);
    z-index: 20000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* pointer-events: none; */
}

.viewImgPreview{
    background-color: hsla(0, 0%, 100%, 0.96);
    z-index: 20000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    /* pointer-events: none; */
}
.container-outer { overflow: scroll; width: 100%; }
.container-inner {
     /* width: 10000px; */
     padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    width: 100%;
    overflow-x: scroll;
}

.E7GkP {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 0;
    height: 100%;
    cursor: default;
}
._19AnP, ._1AhJ7 {
    overflow: visible;
}

._19AnP {
    display: flex;
    align-items: center;
    justify-content: center;
}
._3Obxx {
    position: relative;
}
.H36t4 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
._8Yseo {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
._2kLly, ._1iHeu {
    width: 100%;
    height: 100%;
}
._1iHeu {
    position: relative;
}
._2kLly {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
._2kLly, ._1iHeu {
    width: 100%;
    height: 100%;
}
._1G9lM {
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
._3hYfA {
    width: 100%;
    height: 100%;
    /* -webkit-filter: blur(8px);
    filter: blur(8px); */
}
.closeBtncss{
    color: #fff !important;
    cursor: pointer !important;
    height: 20px !important;
    margin-left: -10px;
    margin-top: 10px;
}

._2iyX0 {
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    max-width: 168px;
    overflow: hidden;
}
._2kLly {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
._2kLly, ._1iHeu {
    width: 100%;
    height: 100%;
}

.uslS5 {
    position: absolute;
    z-index: 10;
    color: var(--inverse);
    font-size: 35px;
    pointer-events: none;
}
.YZNwM {
    color: #555;
    font-weight: 500;
    font-size: 0.94rem;
    line-height: 35px;
    margin-left: -30px;
}
.DotIcon{
    color: #ccc;
    font-size: 15px !important;
}
/* Range Date Picker Css */
/* .Cal__Header__root{
    padding: 0 15px !important;
    min-height: 0 !important;
}
.Cal__Header__date {
    position: relative !important;
}
.Cal__Header__wrapper{
    flex-direction: row !important;
}
.Cal__Header__dateWrapper.Cal__Header__year {
    height: 20px !important; 
    line-height: 27px !important;
    width: fit-content !important;
}
.Cal__Header__dateWrapper.Cal__Header__day {
    height: 24px !important;
    font-size: 17px !important;
    line-height: 25px !important;
    text-transform: capitalize !important;
    width: fit-content !important;
    padding-left: 5px !important;
}
.Cal__Header__wrapper.Cal__Header__blank {
    height: 24px !important;
      line-height: 0;  
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 15px !important; 
}
.Cal__Weekdays__day{
    padding: 0px !important;
}
.Cal__Years__year ol li {
    width: 35px !important;
    height: 35px !important;
} */
.FilterParentDiv ul {
    padding: 0px !important;
}
 
 
.Calendar {
    width: 100% !important;
    min-height: 30.7em !important;
}
.Calendar__sectionWrapper {
    min-height: 20.8em !important;
}
.btnDate{
    background:transparent;
    border: 0;
    /* border-right: 1px solid; */
    cursor: pointer;
}
.Datemrg{
    padding: 6px 6px 0px 6px;
    font-size: 0.625rem;
    color: black;
    /* margin: 10px;
    height: 25px;
    width: 70px; */
}
.activedate{
    color: blue !important;
}
.icondate{
    color: black
}
.iconswap{
    cursor: pointer;
}
.datelabel{
    font-size: 0.625rem;
    font-weight: 600;
    opacity: 0.5;
    line-height: 1;
}
.yearimg{
    height: 20px;
}
.MuiPickersStaticWrapper-staticWrapperRoot{
    min-width: 100% !important;
}
/* Bubble Charts Css */
.BubbleViewParent{
    width: 100%;
    text-align: center !important;
    border-right: 1px solid #decccc !important;
}
.GridDate{
    text-align:center;
  border-right: 1px solid rgb(241, 227, 227);
  border-bottom: 1px solid rgb(241, 227, 227);
  padding:8px 0px !important;
  cursor: pointer;
  
}
.GridDate:hover{
    box-shadow: 0 1px 2px -2px rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.12), 0 5px 12px 4px rgba(0,0,0,.09);
}
.gridclose{
    cursor: pointer;
    color:#fff; 
    font-size: 17px !important;
    width: 2em !important;
    padding-left: 8px;
    margin-top: 6px;
}
.gridclose2{
    cursor: pointer;
    color:#fff;
    /* margin: 4px; */
    font-size: 17px !important;
    width: 2em !important;
    padding-left: 8px;
    margin-top: 6px;
}








/* Drag CSS */
.Showcase__style__stylizedList {
    position: relative;
    z-index: 0;
    /* background-color: #f3f3f3; */
    /* border: 1px solid #efefef; */
    border-radius: 3px;
    outline: none;
}
.Showcase__style__list {
    width: 100%;
    /* height: 100%; */
    /* overflow: auto; */
    -webkit-overflow-scrolling: touch;
    /* border: 1px solid #999; */
}
.Showcase__style__stylizedItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    /* border: 1px solid #efefef; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 400;
    /* height: 40px; */
}
.Showcase__style__item {
    position: relative;
    padding: 5px 0px;
    cursor: pointer;
}
.DragBackGround{
    /* background: #ebfaeb !important; */
    border-bottom: 1px solid #ebecf1;
}



/* Drag & Drop CSS */
.CheckBoxLabel{
    font-size: 0.688rem;
}
.DragCheckbox .MuiSvgIcon-root{
    height: 19px !important;    
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #02B152 !important;
}
label + .MuiInput-formControl{
    /* margin-top: 12% !important; */
}
/* .MuiInput-underline:before{
    border-bottom: 2px solid #337ab7 !important;
} */

/* Card View CSS */
.CardViewDiv{
    height: 100%;
    overflow-y: auto;
}
.CardViewGrid{
    width: 100% !important;
    margin: 0 !important;    
}
.CardViewMain{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    border: 1px solid #e4e4e4;
    /* border-left: 3px solid #34495E;
    border-bottom: none; */
    border-radius: 5px;
    background: #fff;
}
.CardViewHeader{
    display: flex;
    margin-bottom: 3px;
    padding: 2px 2px 8px 3px;
    /* background : linear-gradient(0deg,#1e3c72 0,#1e3c72 1%,#2a5298)!important; */
    /* background: #FFF;     */
    width: 100%;
    height: auto;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    /* border-bottom: 1px solid #e6e6e6; */
    /* margin: 0 5px; */
}
.CardViewTitle{
    font-size: 0.75rem;
    font-weight: 600;
    color: #fff;
    width: 100%;
    background: #337ab7;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.CardViewAvatar{
    width: 20%;
    height: 52px;
    /* border-radius: 5px; */
    border: 1px solid #dadada;
    margin: 2% 3%;
}
.CardViewAvatar img{
    width: 100%;
    height: 52px;
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
    /* border: 1px solid #ddd; */
}
.CardTitle{
    background: #337ab7;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 3px 0px 2px 8px;
    flex: 1 1;
    height: 25px;
    display: flex; 
    margin-top: 3%;
} 
.CardSecTitle{
    display: flex;
    margin: 3px 0;    
}
.CardViewStatus{
    font-size: 10px !important;
    font-weight: 600;
    /* color: #337ab7; */
    height: 20px;
    padding: 2px 8px;
    border-radius: 0px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}
.CardViewCreated{
    font-size: 10px !important;
    font-weight: 600;
    /* color: #337ab7; 
    font-style: italic;*/ 
    height: 20px;
    padding: 2px 8px;
    border-radius: 0px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding-right: 10%;
}
.AvatarFirstLetter{
    width: 100%;
    height: 50px; 
    font-size: 24px;
    color: #337ab7;
    background: #f1f1f1; 
    font-weight: 600;
    text-align: center;
    padding: 10% 0%;
}
.CardViewCheckbox{
    margin-left: auto;
    position: relative;
    /* height: 19px; */
    bottom: 4px;
    
}
.ActiveCheckBox{
    margin-left: 4px !important;
}
.CardViewBody{
    width : 100%;
    display: grid;    
    padding: 0% 5% 5% 5%;

}
.CardViewBody span{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.43;
    color: #4a5568;
    /* font-style: italic; */
    margin: 0px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.CardViewBody p{
    font-size: .73rem;
}
.CardViewFooter{
    height: 25px;
    /* background : #fff; */
    border-top: 1px solid #d3d6d6;
    color: #4a5568;
    width: 100%;
    padding-left: 15px;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.CardViewFooter span{
    font-size: 0.75rem;
    font-weight: 600;
    font-style: italic;
}
/* Graph tab */
.graphhead{
    margin: 5px;
    text-align: center;
    background: #4892F9;
    margin-top: 10px;
}
.graphhead span{
    color: #fff;
    font-size: 0.625rem;
    font-weight: 600;
}
.graphtabs{
    margin: 5px;
}
.graphtabs button{
    width: 50%;
    font-size: 0.625rem;
    
    font-weight: 600;
    font-style: italic;
    background: #fff;
    opacity: 0.5;
}
.btnactgrp{
    border-bottom: 3px solid #4892F9;
    background: #eee !important;
    opacity: 1 !important;
}
.graphgrid{
    width: 97% !important;
    margin: 4px !important;
}
.buildtotal{
    text-align: center;
    background: #ef0e7d; 
    padding: 26px 0px !important;
}

.buildtotal h3{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
}
.buildtotal span{
    font-size: 0.75rem;
    font-weight: 600;
    color: #fff;
}
.buildBUA{
    text-align: center;
    background: #096dd9; 
    padding: 5px 0px;
}
.buildBUA h3{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
}
.buildBUA span{
    font-size: 0.88rem;
    font-weight: 600;
    color: #fff;
}
.seglist{
    margin: 0;
    padding: 0px;
    background: #ef79b9;
    border-radius: 5%;
    padding: 2px 6px;
    list-style: none;
}
.seglist li{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
}
.btnAlignpad{
    padding: 8px 8px;
    margin-right: 10px;
}
.marginTop5{
    margin-top: '5px';
}
/* /Popover */
.popover .MuiPopover-paper{
    /* width: 100% !important; */
    max-width: 100%;
}

.FilterSearchIcon{
    font-size: 18px !important;
    color: #555;
    position: absolute;
    top: 10px;
    left: 10px;
}
.ColFilterClearIcon{
    position: absolute;
    display: none !important;

}
.ColFilterSearchIcon{
    position: absolute !important;
    font-size: 18px !important;
    top: 10px;
}

.ColorViolet{
    /* color: #5C69E1; */
}
.DropdownLabelHeader{
    font-weight: 500;
    font-size: 0.75rem;
    /* width: 200px; */
    /* height: 20px; */
    /* padding-left: 12px; */
    /* width: 15% !important; */
    /* min-width: 15% !important; */
    /* max-width: 15% !important; */
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1;
    text-align: left;
    overflow: hidden;
    margin: 0% 1%;
}
.DropdownLabelValues{    
    /* width: 200px;  */
    /* max-width: 200px; */
    /* min-width: 150px; */
    font-size: 0.7rem;
    /* padding-right: 10px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    margin: 0% 1.9%;
}
/* .DropdownLabelValues:hover {
    width: 200px;  
    padding-right: 10px;
    overflow: visible;
} */
.popOverFooter{
    background: #337ab7;
    height: 25px;
    position: relative;
    padding: 2px 4px;
}
.viewMoreLabel{
    cursor: pointer;    
    color: #fff;
    font-size: 0.75rem; 
}
.viewMoreLabel:hover{
    color: #ddd;
    text-decoration: underline;
}
.showingTitle{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
    margin-right: 10px;
    margin-top: 1px;
    margin-left: 5px;
}
.DropDownIcons{
    cursor: pointer !important;
    font-size: 18px !important;
}
.FilterRefreshIcon{
    color: #fff ;
    font-size: 18px !important;
    margin-left: auto;
    cursor: pointer;
}
.FilterRefreshIcon:hover{
    color: #ccc;
}
.FilterActiveSelected{
    background: #F3F6F9;
    color: #337ab7;
    font-weight: 600; 
    transform: scaleX(1.01);    
    /* box-shadow: inset 0.1875rem 0 0 #8950FC; */
    padding-left: 10px;
    margin-left: -6px;
}
/* Image Upload */
.btnpadding {
    padding: 4px 8px !important;
    margin-right: 5px !important;
}
.Iconstyles{
    color: #337ab7 !important;
    cursor: pointer !important;
    height: 20px !important;
    margin-bottom: 10px;
}

.overlayPreview {
    cursor: pointer;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    width: 0px;
    overflow-x: hidden;
    position: absolute;
    display: none;
    z-index: 20000;
    top: 0%;
    right: 0;
    background-color: #efefef;
    transition: 0.2s;
    border: 1px solid #ebecf1;
    box-shadow: 0 1px 12px 2px #dbdbdb;
}

/* Filter Items */
.FilterItems{
    width: 100%;
    overflow-y: auto; 
}
 
.FilterItems .FilterItemDivTitle{
    border-bottom: 1px solid #ddd;    
    width: 100%;
    padding: 2%;
    /* color: #3D4977; */
    cursor: pointer;
    font-size: 0.75rem;
    display: grid;
}
.FilterItems .FilterItemDivTitle:hover{
    background: #EFF2F6;
    color: #3D4977;
    border-radius: 5px;
}
.FilterItemCount{
    color: #3D4977;
    height: 50px;
    background: #02B152;
}
.filterSearchInput::-webkit-input-placeholder {
    color: #888;
}
.FilterItemDivSelected{
    margin-right: 6px;
    margin: 3px;
    padding: 0 5px;
    /* background: #EBFAEB; */
    color: #222F3E;
    border-radius: 6px;
    cursor: default;
    display: inline-flex;
    height: auto;
    vertical-align: top;
    position: relative;
    flex-direction: row;    
    pointer-events: all;    
    width: -webkit-fit-content;    
    width: -moz-fit-content;    
    width: fit-content;
    max-height: 100%;

}
.RoundedDivNumber{
    border-radius: 50%;
    background: cadetblue;
    padding: 2px 4px 0 4px;
    margin-left: 5px;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
}
.RoundedDivName{
    border-radius: 5px;
    background: cadetblue;
    padding: 2px 4px 0 4px;
    margin-left: 5px;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.FilterItemDivSmall{
    font-size: 0.563rem;
}
.FilterItemClose{
    float: right;
}
.FilterItemCloseIcon{
    width: 100%;
    margin-top: 2px !important;
    font-size: 18px !important;
    cursor: pointer;
    color: #ff4d4f;
}
.FiltercloseAlign{ 
    margin-right: 8px;
    float: right;
}
.ViewfontBold{
    font-weight: bold !important;
}

/* Image Preview */
#divDocumentRegistryEntry {
    position: relative;
 }
 ._3JuBL {
    display: flex;
    flex: none;
    align-items: center;
    box-sizing: border-box;
    height: 30px;
    color: white;
    background-color: #337ab7;
}
._1D7Lo {
    display: flex;
    flex: none;
    align-items: center;
    width: 100%;
}

._3JuBL ._3SrqU {
    width: 48px;
    opacity: 1;
}
._3SrqU {
    flex: none;
}
.Iconwhite{
    color: #ffffff !important;
    cursor: pointer !important;
    height: 20px !important;
    margin-left: 3px;
}
.btnpadding4 {
    padding: 6px 4px !important;
    /* margin-right: 5px !important; */
}
.naICS{
    flex: 0 0 130px;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    padding: 24px 20px 0;
    background-color: var(--panel-background-deep);
    justify-content: center;
}
._2_F5S {
    margin-right: 6px;
    padding-bottom: 5px;
    display: flex;
    overflow-x: auto;
}
/*  attachments priview bottom list */
._2K3Eo, ._2_F5S {
    display: flex;
    /* justify-content: center; */
}
._2aa7- {  
    margin-right: 6px;
}
._38JkY {
    position: relative;
    flex: none;
    box-sizing: border-box;
    width: 78px;
    height: 78px;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    display: block;
    transform-origin: 50% 50% 0px;
    transform: scaleX(1) scaleY(1);
}
.TZVqr {
    position: relative;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: transform 75ms ease-out;
 
    background-color: #e6e6e6;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transform: none; 
}

._20A_P {
    display: flex!important;
    flex: 0 0 78px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: 78px;
    height: 78px;
    color: var(--button-alternative);
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 13px;
    letter-spacing: -.2px;
    text-transform: uppercase;
    transition: transform 75ms ease-out;
    padding: 8px;
    background-color: #fff;
}
 ._2-TA4 {
    /* margin-top: 5px; */
    text-align: center;
}
/* image Add */

.ImageAddDiv {
    position: relative;
    height: 100%;
    width: 100%;
    color: #337ab7;
    background: #fff;
    text-align: center;
    transition: box-shadow 0.3s, border-color 0.3s;
    cursor: pointer;
}
.closeImgBtn{
    color: #777;
    position: absolute;
    z-index: 100;
    width: 18px !important;
    right: 5px;
    top: 2px;
    opacity: 1;
}
.ImageAddDiv:hover {
    opacity: 1;
}
.AttachDiv{
    display: flex;
    height: 42px;
    background: #337ab7;
    padding: 1px 8px;
}

.AttachDiv .ListP {
    
    padding-top: 8px;
    font-size: 0.88rem;
    font-weight: bold;
    color: #fff !important;
}

/* No Data Image */
.NoDataImage{
    width: 100%;
    text-align: center;
}
.NoDataImage img{
    height: 150px;
    width: 150px ;
}
.NoDataDesc{
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
    color: #698194;
}
/* Tooltip Css */
.Level2Tooltip {
    /* position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; */
  }
  
  .Level2Tooltip .Level2TooltipText {
    visibility: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #333;
    color: #ddd;
    font-size:10px;
    text-align: center;
    border-radius: 6px;
    padding: 3px; 
    margin: 0 2px;
    position: absolute;
    z-index: 1000 !important;
    top: -2px;
    /* left: 105%; */
  }
  
  .Level2Tooltip:hover .Level2TooltipText {
    visibility: visible;
  }



  /* Toggle button */
.toggle-button-cover
{
    display: table-cell;
    position: relative;
    width: 200px;
    height: 140px;
    box-sizing: border-box;
}

.button-cover
{
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
}

.button-cover:before
{
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 0.75rem;
    line-height: 1;
    padding: 5px;
}

.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button
{
    position: relative;
    top: 50%;
    width: 75px;
    height: 28px;
    margin: 0px auto 0 auto;
    overflow: hidden;
    border: 1px solid #ebecf1;
}

.button.r, .button.r .layer
{
    border-radius: 100px;
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: #fff;
    transition: 0.3s ease all;
    z-index: 1;
}
#button-11
{
    overflow: visible;
}

#button-11 .knobs
{
    perspective: 70px;
}

#button-11 .knobs:before, #button-11 .knobs:after, #button-11 .knobs span
{
    position: absolute;
    top: 4px;
    border-radius: 2px;
}

#button-11 .knobs:before, #button-11 .knobs:after
{
    width: 30px;
    height: 20px;
    color: #444;
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 5px 0px;
}

#button-11 .knobs:before
{
    content: 'List';
    left: 4px;
}

#button-11 .knobs:after
{
    content: 'Card';
    right: 4px;
}

#button-11 .knobs span
{
    right: 1px;
    width: 35px;
    height: 18px;
    background-color: #337ab7;
    transform: rotateY(0);
    transform-origin: 0% 50%;
    transition: 0.6s ease all;
    z-index: 1;
}

#button-11 .checkbox:checked + .knobs span
{
    transform: rotateY(-180deg);
    background-color: #337ab7;
}

/* default card open */

.button-cover, .cardknobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cardknobs
{
    z-index: 2;
}


#button-11 .cardknobs
{
    perspective: 70px;
}

#button-11 .cardknobs:before, #button-11 .cardknobs:after, #button-11 .cardknobs span
{
    position: absolute;
    top: 4px;
    border-radius: 2px;
}

#button-11 .cardknobs:before, #button-11 .cardknobs:after
{
    width: 30px;
    height: 20px;
    color: #444;
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 5px 0px;
}

#button-11 .cardknobs:before
{
    content: 'Card';
    right: 4px;
}

#button-11 .cardknobs:after
{
    content: 'List';
    left: 4px;
}

#button-11 .cardknobs span
{
    right: 1px;
    width: 35px;
    height: 18px;
    background-color: #337ab7;
    transform: rotateY(-180deg);
    transform-origin: 0% 50%;
    transition: 0.6s ease all;
    z-index: 1;
}

#button-11 .checkbox:checked + .cardknobs span
{
    transform: rotateY(0);
    background-color: #337ab7;
}


/* default card exit */

#button-11 .checkbox:checked ~ .layer
{
    /* background-color: #fcebeb; */
}
.Maincontent{
    padding: 1%;
    border-top: 2px solid #337ab7;
    margin: 0.3rem 0rem;
    background: #fff;
    box-shadow: 0px 6px 8px rgba(4, 4, 7, 0.1);
    border: 1px solid #dbe2eb;
    border-radius: 10px;
}
/* .MuiCheckbox-root {
    color: #fff !important
} */

/* .MuiFormControlLabel-root {
    margin-left: -10% !important;
    margin-right: 1% !important;
} */

.MuiTableSortLabel-icon {
    opacity: 1 !important;
}

.LockIcon{
    font-size: 15px !important;
    margin: 0 3%;
    color: #ff4d4f;
}
.Entry-Checkbox{
    /* position: relative;  */
    bottom: 5%;
}

/* Table header font */
.MuiTableSortLabel-root{
    font-size: 12px !important;
}
.MuiTableSortLabel-icon {
    font-size: 12px !important;
}
/* FIle preview Icon */
.FilePreviewIcon-lg{
    font-size: 100px !important;
    position: relative;
    left: 42%;
    top: 30%;
    color: #777 !important;
}

.EntryView-Checkbox {
    position: relative;
    right: 1%;
    top: 46%;
}

/* FIle preview Icon */
.FilePreviewIcon-xs{
    position: relative;
    left: 42%;
    top: 30%;
    color: #777 !important;
}

.FilePreviewIcon-sm{
    font-size: 50px !important;
    margin-top: 1%;
    margin-right: 1%;
    color: #777 !important;
}

/* Common search css */
.searchBar{
    height: 40px !important;
    margin-top: 0px !important;
    width: 100%;
    background-color: #fff;
    border-radius: 5px !important;
    font-size: 0.8rem;
    border: 1px solid #ebecf1;
}
.searchbar1 {

    /* position: fixed; */
    /* top: 10px;
    left: 3%;
    right: 3%; */
    margin-top:-6px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 65px;
    height: 6vh !important;
}

.providerDropdown label {
    font-weight: bold !important;
    color:#000 !important;
    font-size: 12px !important;
    margin-top:-9px !important;
    
    
}

.entryimg img {
    border-radius: 5px;
    width: 60px;
    /* height:16vh; */
}

.entrydes {
    margin-left:10px;
}
.entrydes span {
    font-weight:bold !important;
    font-size:0.75rem !important;
}
.pointer{
    cursor: pointer;
}
.entrybk {
    text-align: center;
}
.entrybk span {
    font-size:11px;
    font-weight: bold;
}
.entrybk p {
    font-size:24px;
    font-weight: bold;
    text-align: center;
}
@media screen and (max-width: 1280px) {
    .entrybk span {
        font-size: 9px;
        font-weight: bold;
    }
  }
.entrycon {
    font-size:12px;
    color:grey;


}
.entryphy {
    font-size: 12px;


}

.con{
    font-size: 0.75rem;
    padding:0px 6px;
    color:grey;

}
.tabphy {
    line-height: 2 !important;
  }
  .tabCon {
    text-transform: capitalize !important;
    font-size: 14px !important;
  }
  .tabqueue {
    margin-left: auto !important;
    font-size: 14px !important;
  }
  
  .tabImage {
    width: 80px;
    height: 70px;
    border-radius: 5px !important;
  }
  .docname {
    font-size: 14px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold !important;
    line-height: 1.334 !important;
    letter-spacing: 0em !important;
}
.docname2{
    font-size: 13px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.334 !important;
}
  @media screen and (max-width: 1280px) {
    .tabImage {
        width: 50px;
        height: 50px;
    }
    .docname {
        font-size: 12px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: bold !important;
        line-height: 1.334 !important;
        letter-spacing: 0em !important;
    }
    .docname2{
        font-size: 12px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1.334 !important;
    }
    .cardbtn {
        height: 4rem !important;
        border-bottom: 1px solid rgb(238, 238, 238) !important;
        justify-content: flex-start !important;
    }
    
  }
  .tabbtn > .MuiButtonBase-root > .MuiButton-label{
    justify-content: normal !important;
  }
  .aligncunt{
    text-align: right;
    width: 20%;
  }
  .tabCon{
      width: 100%;
  }
  .MuiPaper-rounded {
    
    border-radius: 5px !important;
}

.proqueue {
    font-size: 16px !important;
    color:#337ab7 !important;
}



.quecnt {
    margin-left:70px !important;
}
.conqueue {
    font-size: 20px;
}

.patientDetails {
    border-bottom: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    font-weight: bold;
    margin-left: 20px !important; 
}
.modalHeaderDetails {
    border-bottom: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    font-weight: bold;
    margin-left: 15px !important; 
    margin-bottom: 0px !important;
}

.Register {
    font-size: 12px !important;
    border-bottom: 1px solid !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    color: #251E8F;
}
.dialogTitle {
    font-weight: bold !important;
    color:rgb(103,149,237) !important;
    font-size: 1rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    margin: 0
}

.consultim{
    margin: 0;
    font-size: 10px;
    margin-top: 5px !important;
    font-weight: 600;
}
 
.consultDetails {
    border-bottom: 1px solid !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-top: 4% !important;
}
.patientValue {
    font-weight: bold !important;
    margin-left: 7px !important;
    color: #251E8F;
    font-size: 12px;
}
.patientKey {
    margin-top: 6px !important;
}
.patientKey1 {
    margin-top: 13px !important;
}

.ListP{
    font-weight: bold;
    color: #4da7fc;
    font-size: 14px;
}
.trAction{
    font-family: sans-serif;
    font-weight: 500;
    color: #337ab7 !important;
    font-weight: 600;
}
.Edittablecls{
    width: 15px !important;
    color: orange;
}
.Viewtablecls{
    width: 16px !important;
    color: #62B445;
    margin-right: 10px;
}
.removetablecls{
    width: 16px !important;
    color: red;
    margin-right: 10px;
}
.MuiTableCell-stickyHeader {
    background-color: #fff !important;
}
.sublink{
    margin: 5px;
    padding: 8px 12px;
    padding-left: 0;
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.sublink p{
    color: #74777b;
    cursor: pointer;    
    font-size: 0.8rem !important;
    border-left: 3px solid #fff;
    padding-left: 5px;
    border-bottom: 1px solid #ebecf1;
    margin-bottom: 3% !important;
}
.sublink span{
    font-size: 0.688rem;
    font-weight: 500;
    color: #45B154;
    padding-left: 5%;
    float: right;
}
.formheader1{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 5px !important;
    margin-top: -17px !important;
    border-radius: 3px !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px #4892F9;
    background-color: #367AB7;
}
.billingFilterColumnIcon {
    color: #444 !important;
    cursor: pointer !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: 3px;
    transition: all 0.3s ease-out !important;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #e3e4e9;
    padding: 14%;
}
.floatRight{
    float: right;
}
.formtabs{
    margin: 5px; 
    display: flex;
    border: 1px solid #e3e4e9;
}
.tabcontainer{
    width: 80%;
}
.secondaryMenuBtn{
    /* width: fit-content !important;
    
    opacity: 0.5; */
    position: relative;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2.5;
    border: none;
    background: #fff !important;
    color: #74777b;
    font-weight: 550 !important;
    padding: 2px 5px;
    border-right: 1px solid #e3e4e9;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    font-size: 0.8rem;
    font-style: italic
}
.pDisabled{
    cursor: not-allowed !important;
    color: #BFC0C5 !important;
} 
.secondaryMenuBtn:hover{
    cursor: pointer;
    color: #4892F9;
}
.secondaryMenuBtn:focus{
    outline: -webkit-focus-ring-color auto 0px;
}
.linkactive{
    color: #4892F9 !important;
    opacity: 1 !important;
    font-size: 12px !important;
    font-weight: 600;
    border-left: 3px solid #4892F9 !important;
    padding-left: 5px;
}
.btnactvtab{
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 25% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}
.btnactvtabnew{
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 50% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}
.btnactvtabnew1{
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 100% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}
.formcontent{
    margin: 5px;
}

.formdetail{
    background: #303650ed;
    margin: 5px !important;
    padding-bottom: 15px !important;
    padding: 7px;
}

.formdetail p{
    color: #fff;
    font-size: 0.625rem;
    font-style: italic;
}

.formdetail h3{
    color: #fff;
    text-decoration: underline;
    font-size: 0.75rem;
    font-weight: 600;
}

.formpos{
    position: relative;
    bottom: 20px;
    background: #fff;
}

.MuiAccordion-root{
    background-color: transparent !important;
}

.esthead{
    margin: 5px;
}

.esthead h3{
    font-size: 0.688rem;
    font-weight: 600;
    text-decoration: underline;
}

.esthead p{
    font-size: 0.625rem;
    padding: 3px 0px;
}
.sumperc{
    font-size: 0.88rem;
    font-weight: 600;
    color: #4892F9
}
.borderbtmgrid{
    padding: 4px 2px !important;
    border-bottom: 3px solid rgb(72, 146, 249);
}

.TextHorizontal{
    cursor: pointer;
    padding-top: 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background: #337ab7;
    color: #fff;
    /* width: 2%; */
    height: 100%;
    font-size: 0.8rem;
}
.backgroundLightL3{
    background: #fff;
    height: 42px;
}
.L3tableHeaderItems{
    padding-top: 4px;
    width: 80%;
}
.L3AddBtnCommon{
    background: #337ab7 !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-right: 0% !important;
}
.backgroundL3{
    background: #3d4977;
}

/* Report Css */
.ReportMenuListItems{
    width: 25%;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #e3e4e9;
}
/* Report Css */
.ReportMenuListItems{
    width: 25%;
    height: 66vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #e3e4e9;
}
.tabcontainerReport{
    width: 100%;
}
.btnactvtabBtn{
    border: none;
    border-bottom: 3px solid #4892F9 !important;
    background: #fff !important;
    color: #4892F9 !important;
    opacity: 1 !important;   
    width: 50% !important; 
    /* transition: all 0.3s ease-out !important; */
    transition: top 0.3s ease-out !important;
}

/* New L4 Design */
.L4ViewDetailsDiv{
    margin: 0 5px;
}
.L4ViewDetailsDiv p{
    font-size: 0.625rem;
    color: black;
}
.L4ViewDetailsDiv p span{
    font-size: 0.8rem;
    font-weight: 600;
    color: black;
}
.AEDspan{
    margin-right: 5px;
    font-weight: 600 !important;
    color: rgb(239, 164, 45) !important;
}

.presentTab{
    background: #337ab7;
    color: #fff !important;

}
.GenBtnCommon{
    width: 9rem !important;
}
.simpleDivButton{
    height: 30px;
    flex: 1 1 auto;
    padding: 5px 20px;
    position: relative;
    -webkit-box-flex: 1;
    margin:0 15px;
    cursor: pointer;
    border: 1px solid #e3e4e9;
    font-weight: bold;
    color:#555;
    border-radius: 4px;
}
.simpleDivButton p{
    margin-top: 0px !important;
    font-size: 0.75rem;
}
.simpleDivButton span{
    height: 14px;
    min-width: 20px;
    font-size: 0.563rem;
    font-weight: bold;
    color: #fff;
    background-color: #f50057;

    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
}

/* AED CSS */
.floatTure{
    top: -10px !important;
    font-size: 10px !important;
    color: #337ab7 !important;
}
.EditNewcls{
    width: 18px !important;
    color: orange;
}
.Divbtnpos{
    position: relative;
    float: right;
    bottom: 30px;
}
.Closesmcls{
    width: 18px !important;
    margin-left: 1rem;
    color: red;
}
.pretextview{
    color: red;
    font-weight: bold;
}
.MuiTableSortLabel-root span{
    color: #337ab7 !important;
    font-weight: 600 !important;
}

.cardhight{
    height: 100% !important;
    margin-top: 0 !important;
}
.mpadd5{
    padding: 5px !important;
}
.contpad{
    padding-right: 1rem;
}
.pmarg2{
    padding: 0px;
    margin-top: 2rem;
}
.ascardpad{
  padding: 0.3rem !important;
}
.dflex{
    display: flex;
}
.qrdiv{
    width: 100%;
    flex: 1 1;
    float: right;
    padding: 5px;
}
.assNametag{
    font-size: 17px;
    font-weight: bold;
    color: cornflowerblue;
    padding: 5px 20px;
}
.pfonttag{
    font-size: 12px;
    font-weight: 600;
    color: #555;
}
.qralignrtxt{
    padding-top: 7px;
    text-align: right;
}
.assimgw{
    width: 17%;
    border-radius: 6px;
}
.lptag {
    font-weight: bold;
    color: #777;
    padding: 5px;
    border-left: 3px solid #fff;
}

.acrcardpad{
padding: 10px 20px !important;
}
.lptag:hover {
    border-left: 3px solid #4da7fc;
    color: #4da7fc;
}
.acctext{
    font-weight: bold;
    color: #4da7fc;
    font-size: 14px;
}
.acctext:hover{
    color: #4da7fc;
}
.mar0 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
.accsumar > .MuiAccordionSummary-root {
    border-bottom: 0px !important;
}
.ptno{
    font-size: 12px;
    color: #444;
    padding-left: 1rem;
}
.divcolleft{
    width: 50%;
    float: left;
    padding-left: 1.3rem;
}
.pnortxt{
    font-size: 12px;
    color: #444;
}
.padleft5 {
    padding-left: 0.5rem;
}
.L2DropDownDivOpen{
  border-radius: 10px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: rgba(223,225,229,0) !important;
  box-shadow: 0 1px 6px rgba(32,33,36,.28) !important;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.dropicon{
  float: right;
  color: #777;
  /* position: relative; */
  top: -30px;
}
.dclosePadd {
  padding-right: 2.5rem !important;
}
.dcloseright {
  right: 1.5rem !important;
}
.L2DropDownDiv{
  background: #fff;
  display: flex;
  border: none;
  box-shadow: none;
  height: 35px;
  /* width: 690px; */
  /* border-radius: 10px; */
  border-bottom: 2px solid #4892F9;
  z-index: 3;
  height: 40px;
  margin: 0 auto;
  margin-top: 5px;
}
.L2DropDownInnerDiv{
  flex: 1 1;
  display: flex;
  padding: 12px 4px 0 2px;
}
.L2DropDownDivOpen .L2DropDownInnerDiv{
  padding-left: 10px;
}
.L2DropDownInputDiv{
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
}
.L2DropDownInputInnerDiv{
  color: transparent;
  flex: 100% 1;
  white-space: pre;
  height: 34px;
  font: 16px arial,sans-serif;
  color: rgba(0,0,0,.87);
  line-height: 34px;
}
.L2DropDownInputText{
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.87);
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100% 1;
  -webkit-tap-highlight-color: transparent;
  margin-top: -37px;
  height: 34px;
  font-size: 0.75rem;

  font: 12px arial,sans-serif;    
  line-height: 34px;    
  
}
.L2DropDownList{
  width: 100%;
  text-align: left;
  margin-top: -1px;
  z-index: 989;
  cursor: default;
}

@media screen and (min-width:960px) {
  .L2DropDownList{
    position: absolute;
    width: 40%;
  }
}
.L2DropDownListDiv{ 

  background: #fff;
  box-shadow: 0 4px 6px rgba(32,33,36,.28);
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 0; 
  padding-bottom: 4px;
  overflow: hidden;
}
.L2DropDownListBorder{
  border-top: 1px solid #e8eaed;
  margin: 0 14px;
  padding-bottom: 4px;
}
.L2DropDownListUL{
  flex: auto;
  padding: 0px;
}
.L2DropDownListUL li{
  border-bottom: 1px solid #e3e4e9;
}
.L2DropDownListLI{
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  padding: 0;
}
.L2DropDownListLIDiv{
  flex: auto;
  display: flex;
  font-size: 0.88rem;
  margin: 0 20px;
  align-items: center;
  margin: 0 20px 0 14px;
}
.FilterClearIcon{
  font-size: 12px !important;
  color: red;
  cursor: pointer;
  /* position: absolute; */
  right: 10px;
  top: 10px;
  /* margin: 5px 8px 0 0 ; */
}

.L2DropDownInputClearDiv{
  position: absolute;
  right: 0px;
  top: 5px;
}
.loginInputClearDiv{
  position: absolute;
  right: 5px;
  top: 10px;
}
/* Password Visible icon */
.passwordVisibleIcon{
  position: absolute;
  top: 25%;
  right: 7%;
  font-size: 20px !important;
  color: #4892F9;
  cursor: pointer;
}

/* Floating Css */
.floating-label { 
  position:relative; 
  /* margin-bottom: 2%;     */
}
.floating-input , .floating-select {
  font-size:0.73rem;
  padding:0px 2px;
  display:block;
  width:100%;
  height:35px;
  background-color: transparent;
  border:none;
  padding-right: 18px;
  border-bottom: 2px solid #337ab7;
}
.floating-input-password{
  padding-right: 20% !important; 
}
.errorinput{
  border-bottom: 2px solid red !important;
}
.successinput{
  border-bottom: 2px solid #337ab7 !important;
}
.logininput{
  border-bottom: 0;
  box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
  padding: 4%;
  /* border-left: 3px solid #33234E !important; */

}
.logininput:focus{
  border-bottom: 0px solid #fff !important; 
  border-left: 3px solid #33234E !important;
}
.loginactive {
  border-left: 3px solid #33234E !important;
}
/* .logininputborder{
  border-left: 3px solid #33234E !important;

} */

.loginlabel{
  top:10px !important;
  left:15px !important;
  text-align: left;
}

.logininput:focus ~ label, .logininput:not(:placeholder-shown) ~ label {
  top:-15px !important;
  font-size:10px;
  color:#33234E !important; 
}

.floating-input:focus , .floating-select:focus {
  outline:none;
  border-bottom:2px solid #337ab7; 
}
.float-label {
  color:#999; 
  font-size:12px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:1px;
  top:1rem;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}
.flexend{
  display: flex;
    align-items: flex-end;
}

.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:-10px;
  font-size:10px;
  color:#337ab7;
  
}

.floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
  top:-18px;
  font-size:14px;
  color:#5264AE;
}

.floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
  width:50%;
}

*, *:before, *:after {
    box-sizing: border-box;
}
/* .hidetext { -webkit-text-security: disc; } */
.dropdownCreateNew{
  color: #fff;
  text-decoration: underline;
  font-size: 0.625rem;
  cursor: pointer;
  margin-top: 2px;
}

/* Accordian in menu */
.foreignlableL {
  text-align: left;
  width: 50%;
  float: left;
  font-size: 0.7rem;
}

.foreignlableR {
text-align: end;
width: 50%;
float: right;
font-size: 0.7rem;
}

.foreignval:hover {
  text-decoration: underline;
  color:orange;
  cursor: pointer
}

.tooltitle {
position: relative;
display: inline-block;
}

.tooltitle:hover .tooltiptext {
visibility: visible;
opacity: 1;
}

.tooltitle .tooltiptext {
visibility: hidden;
background-color: #5f5d5de0;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 8px;
position: absolute;
z-index: 1;
opacity: 0;
transition: opacity 0.3s;
}

.tooltitle .tooltip-top::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #5f5d5de0 transparent transparent transparent;
}

.tooltip-right::after {
content: "";
position: absolute;
top: 50%;
left: 100%;
margin-top: -5px;
border-width: 5px;
border-style: solid;
border-color: transparent transparent transparent #555;
}

.tooltip-top {
bottom: 125%;
left: 50%;
margin-left: -30px;
}
.tooltip-right {
top: -5px;
bottom: auto;
right: 128%;
width: -webkit-max-content;
width: max-content;
}

/* Detail Link */
.foreignlableL {
  text-align: left;
  width: 50%;
  float: left;
  font-size: 0.7rem;
}

.foreignlableR {
text-align: end;
width: 50%;
float: right;
font-size: 0.7rem;
}

.foreignval:hover {
  text-decoration: underline;
  color:orange;
  cursor: pointer
}

/* AED */
.pretext{
  font-weight: 700;
    color: red;
    position: absolute;
    top: 0.15rem;
    font-size: 12px;
}
.paddingcurr{
  padding-left: 2rem;
}
.paddingcurrLeft{
  padding-left: 25px;
}
.font12{
    font-family: sans-serif !important;
    font-size: 12px !important;
}
.tableLabelHeader{
    font-weight: 500;
    font-size: 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1;
    text-align: left;
    overflow: hidden;
    margin: 0.2rem 1%;
}
.tableItemDivHeader {
    width: 100%;
    padding: 6px 0px;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    /* border-bottom: 1px solid #e6e6e6; */
    font-size: 12px;
    font-weight: 500;
    background: #367AB7;
    color: #fff;
}
.MenutablemDivHeader {
    width: 100%;
    padding: 6px 0px 6px 2.9rem;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    /* border-bottom: 1px solid #e6e6e6; */
    font-size: 12px;
    font-weight: 500;
    background: #367AB7;
    color: #fff;
}
.tableItemDiv {
    width: 100%;
    /* padding: 6px 12px; */
    cursor: pointer;
    font-size: 12px;
    cursor: pointer;
}
tableItemDivChild {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    cursor: pointer;
}
.tableArrowIcons {
    cursor: pointer;
    color: #777;
    margin: 1px 5px !important;
}

.FilterTableDivBody {
    padding: 0 0 0 0px;
    height: 52vh;
    border: 1px solid #e3e4e9;
}
.userDivBody {
    padding: 0 0 0 0px;
    height: 69vh;
    border: 1px solid #e3e4e9;
}
.AccondianTable{
    box-shadow: 0 0px 5px rgba(32,33,36,.28) !important;
    border-bottom: 0px !important;
}

.tabelListDiv {
    background: #fff;
    /* box-shadow: 0 4px 6px rgba(32,33,36,.28); */
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 4px;
    overflow: hidden;
}
.tableLabelValues {
    /* width: 200px; */
    /* max-width: 200px; */
    /* min-width: 150px; */
    font-size: 0.75rem;
    /* padding-right: 10px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    margin: 0;
}
.tableLabelSecVal {
    /* width: 200px; */
    /* max-width: 200px; */
    /* min-width: 150px; */
    font-size: 0.75rem;
    /* padding-right: 10px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    margin: 0% 1% 0% 5%;
}
.imgviewpage{
    height: 120px !important;
    padding: 5px !important;
    margin: 5px;
    box-shadow: 0 4px 6px rgba(32,33,36,.28) !important;
    cursor: pointer;
}
.cusorPointer{
    cursor: pointer;
}
.lastImg{
    display: flex;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.imgtotCount{
    position: absolute;
    left: 36%;
    top: 39%;
    font-size: 17px;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
}

.Imgbgmstyle{
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.previewFile{
    color: rgb(119, 119, 119);
    width: 100% !important;
    height: 30% !important;
    margin-top: 1em;
}
.homeActive{
    color: #227093;
}

.tableItembtn {
    width: 100%;
    padding: 5px 0 5px 5%;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    font-size: 12px;
    font-weight: 500;
    background: #367AB7	;
    color: #fff;
    margin-bottom: 0.50rem;
    border-radius: 4px;
    cursor: pointer;

}


.tableItemtab {
    width: 100%;
    padding: 5px 0 5px 5%;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    color: #000;
}

.legendcheck span{
    padding: 1px 1px 0px 6px!important;
}

.savediv{
    margin-left: auto;
}
.rightsheader{
    display: flex;
    padding: 5px 5px;
}
.searchbarDiv {
    height: 33px;
    margin-top: 2px;
    width: 100%;
    background-color: #fff;
    /* border-radius: 30px; */
    /* padding: 10px; */
    font-size: 0.8rem;
    /* box-shadow: 0 4px 6px rgba(32,33,36,.28); */
    border-radius: 4px;
    display: flex;
    border: 1px solid #e3e4e9;
    line-height: 2;

}
/* .isearch {
    color: #777;
    padding: 3px 0px 0px 4px;
} */
.s_input {
    color: black;
    border: 0;
    outline: 0;
    background: none;
    /* line-height: 10px; */
    padding: 2px 10px 0 3px;
    width: 100%;
}
.tableDivGroup {
    width: 100%;
    padding: 1% 1%;
    /* box-shadow: 0 2px 2px rgba(101, 102, 107, 0.28); */
    border-bottom: 1px solid #e3e4e9;
    font-size: 12px;
    font-weight: 500;
    /* background: #367AB7; */
    /* color: #fff; */
}
.uSearchicon{
    width: 15px !important;
    color: #777;
    margin: 2px 2px 0px 8px;

}
/* Ripple effect */
.btnripple {
    background-position: center;
    transition: background 0.8s;
  }
  .btnripple:hover {
    background: #367AB7 radial-gradient(circle,transparent 1%,rgba(80,80,80,.05) 0) 50%/15000%;
    /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;*/
  }
  .btnripple:active {
    background-color: #fff;
    background-size: 100%;
    color: #777;
    transition: background 0s;
    -webkit-user-select: none;
            user-select: none;
  }
  .tableItemDivHeader span {
      padding: 0px !important;
  }
  .MenutablemDivHeader span {
    padding: 0px !important;
}
  .Level2Tooltip span {
    padding: 0px !important;
  }
  .custTabActive{
    background: #367AB7 !important;
    color: #fff !important;
  }
.groupActive{
    background: #e4e6e9;
}
.divdisable{
    position: absolute;
    width: 100%;
    /* background: #ccc; */
    height: 85%;
    z-index: 1;
    cursor: not-allowed;
}
.UserRightsNoData{
    margin-top: 5rem;
}
.icondata{
    width: 30%;
    color: #367AB7;
    padding: 1%;
    cursor: pointer;
}
/* user Data permission */
.datadropdiv{
    padding: 0px 0;
    height: 100%;
    border: 1px solid #e3e4e9;
    margin: 10px;
}
.top-acc-title{
    background: #283C4F !important;
    color: #fff !important;
    padding-left: 2% !important;    
}
.top-acc-title p{
    text-decoration: underline;
    font-weight: 600;
}
.top-acc-body{
    /* background: #34495e !important;
    color: #fff !important; */
}
.top-acc-card{
    display: flex;
    overflow: hidden;
    width: 100%;
    background-image: linear-gradient(#283C4F, #4B6482);    
}
.top-acc-card-body{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    /* border-left: 3px solid #34495E; */
    border-bottom: none;
    border-radius: 0; 
    /* width: 250px; */    
}
.top-acc-card-body-items{
    width : 100%;
    display: grid;    
    padding: 0% 5% 3% 5%;
}
.top-acc-card-body-items p{
    font-size: 0.688rem;
    font-weight: 700;
}
.width25{
    width: 25%;
}
.acc-viewHeadpTag{
    font-size: 0.625rem;
    color: #fff;
}
.acc-viewValuepTag{
    font-size: 0.625rem !important; 
    color: #fff;
    font-style: italic;
    margin-left: 6px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
/* icons */
.acc-forward-icon{
    position: absolute;
    top: 45%;
    color: #fff;
    left: 4px;
    cursor: pointer;
}
.acc-back-icon{
    position: absolute;
    top: 45%;
    color: #fff;
    right: 0px;
    cursor: pointer;
}
.acc-heading{
    padding-top: 5px;
    font-size: 0.88rem;
    font-weight: bold;
    color: #fff !important;
}
.browsebtnCommon{
    padding: 6px 5px 5px 14px !important;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #FFF;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    /* padding: 12px 30px; */
    position: relative;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400; 
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
    background-color: #999;
}
.AddRowBtn{
    padding: 6px 5px 5px 14px !important;
    padding: 0.40625rem 1.25rem;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgb(53 113 147 / 14%), 0 3px 1px -2px rgb(53 113 147 / 20%), 0 1px 5px 0 rgb(53 113 147 / 12%);
    background-color: rgb(52, 179, 68) !important;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    position: relative;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
}
.ClearRowBtn{
    padding: 6px 5px 5px 14px !important;
    padding: 0.40625rem 1.25rem;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgb(53 113 147 / 14%), 0 3px 1px -2px rgb(53 113 147 / 20%), 0 1px 5px 0 rgb(53 113 147 / 12%);
    background-color: #ef4b4b !important;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    position: relative;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
}
/* SpreedSheet */
.spreadSheetheader{
    font-family: sans-serif;
    font-weight: 500;
    color: #337ab7 !important;
    font-weight: 600;
}
.Spreadsheet__cell, .Spreadsheet__header {
    min-width: 4em !important;
}

  
  /* ----------------------- */

  * {
    box-sizing: border-box;
  }
  
  .slider {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
  
  .slides {
    display: flex;
    
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    
    
    
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    
    /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */
  }
  .slides::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .slides::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
  .slides::-webkit-scrollbar-track {
    background: transparent;
  }
  .slides > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    /* height: 300px; */
    margin-right: 50px;
    border-radius: 10px;
    background: #eee;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 100px; */
  }
  .slides > div:target {
  /*   transform: scale(0.8); */
  }
  .author-info {
    background: #bdbdbd;
    color: white;
    padding: 0.75rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }
  .author-info a {
    color: white;
  }
  
  
  .slider > a {
    display: inline-flex;
    width: 1.5rem;
    height: 1.5rem;
    background: white;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0 0.5rem 0;
    position: relative;
  }
  .slider > a:active {
    top: 1px;
  }
  .slider > a:focus {
    background: #000;
  }
  
  /* Don't need button navigation */
  @supports (scroll-snap-type) {
    .slider > a {
      display: none;
    }
  }
/*   
  html, body {
    height: 100%;
    overflow: hidden;
  }
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #74ABE2, #5563DE);
    font-family: 'Ropa Sans', sans-serif;
  } */
  

/*

Created by Matej Kovac
http://moodydev.io

*/

/* FONT IMPORTS */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #dadce0;
  --bg-color: #F6F6F6;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

wbody {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
}

header {
  display: block;
  width: 100%;
  /* padding: 1.75em 0; */
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}
.daynames{
    color: #70757a;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: center;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.wcol-center {
  justify-content: center;
  text-align: center;
  /* border-right: 1px solid var(--border-color); */
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


.odcol {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 15%;
    text-align: center;
  }
  
.odcol-start {
    justify-content: flex-start;
    text-align: left;
  }

.odcol-end {
    justify-content: flex-end;
    text-align: right;
  }

/* Calendar */

.wcalendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.wcalendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.wcalendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.wcalendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.wcalendar .header .icon:first-of-type {
  margin-left: 1em;
}

.wcalendar .header .icon:last-of-type {
  margin-right: 1em;
}

.wcalendar .wdays {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  /* padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color); */
}

.wcalendar .wbody .wcell {
  position: relative;
  height: 2.5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}


.wcalendar .wbody .weecell {
  position: relative;
  height: 2.5em;
  /* border-right: 1px solid var(--border-color); */
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}


.wcalendar .wbody .wcell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.wcalendar .wbody .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.wcalendar .wbody .row {
  border-bottom: 1px solid var(--border-color);
}

.wcalendar .wbody .row:last-child {
  border-bottom: none;
}

.wcalendar .wbody .wcell:last-child {
  border-right: none;
}

.wcalendar .wbody .wcell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  /* right: 0.75em; */
  font-weight: 700;
}

.wcalendar .wbody .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.wcalendar .wbody .wcell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.wcalendar .wbody .wcell:hover .bg,
.wcalendar .wbody .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.wcalendar .wbody .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.dcalendar .dbody .dcol {
  flex-grow: 0;
  flex-basis: calc(100%);
  width: calc(100%);
}
/* manual css  */

.dayhours{
    width: 6%;
    /* height: 100%; */
    display: block;
    text-align: right;
    position: absolute;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    padding-top: 3rem
}
.rowpadd {
  padding: 1.1em 0.5rem 0rem 0.54rem;
}
.wnumber{
  font-size: 12px;
  font-weight: 400  
}

.dselectdt{
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 3px 8px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    color: #185abc;
    background-color: #d2e3fc;
    margin-top: -10px;
    margin-left: 10px;
}

/*

Created by Matej Kovac
http://moodydev.io

*/

/* FONT IMPORTS */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #dadce0;
  --bg-color: #F6F6F6;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  /* font-size: 1em; */
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
}

header {
  display: block;
  width: 100%;
  /* padding: 1.75em 0; */
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}
.daynames{
    color: #70757a;
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: center;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
  border-right: 1px solid var(--border-color);
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  /* padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color); */
}

.calendar .body .cell {
  position: relative;
  height: 6em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  /* right: 0.75em; */
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
/* manual css bilal */

.selectdt{
  width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 4px 0px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    color: #185abc;
    background-color: #d2e3fc;
    margin-top: -10px;
    margin-left: -10px;
}
.tagday{
  font-size: 0.60em;
  background: rgb(3, 155, 229);
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  text-align: start;
  padding: 1px 8px;
  margin-right: 5px;
  margin-bottom: 2px;
}
.tagdaymore{
    font-size: 0.60em;
    color: #3c4043;
    font-weight: 600;
    border-radius: 4px;
    text-align: start;
    padding: 1px 8px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.tagdaymore:hover {
  background-color: aliceblue;
  border-radius: 4px;
}
.headerday{
  color: #70757a;
  font-size: 11px;
  letter-spacing: .8px;
  line-height: 16px;
  padding-bottom: 0;
  text-transform: uppercase;
}
.subTitpopDate {
  font-family: 'Google Sans',Roboto,Arial,sans-serif;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  outline: none;
  font-size: 26px;
  letter-spacing: -2.6px;
  text-indent: -2.6px;
  font-variant: tabular-nums;
  font-feature-settings: "tnum" 1, "tnum";
  line-height: 40px;
  color: #3c4043;
  /* width: 40px; */
  border-radius: 50%;
  height: 40px;
  text-align: center;
}

/*

Created by Matej Kovac
http://moodydev.io

*/

/* FONT IMPORTS */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #dadce0;
  --bg-color: #F6F6F6;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

wbody {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
}

header {
  display: block;
  width: 100%;
  /* padding: 1.75em 0; */
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}
.daynames{
    color: #70757a;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: center;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.wcol-center {
  justify-content: center;
  text-align: center;
  /* border-right: 1px solid var(--border-color); */
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.wcalendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.wcalendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.wcalendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.wcalendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.wcalendar .header .icon:first-of-type {
  margin-left: 1em;
}

.wcalendar .header .icon:last-of-type {
  margin-right: 1em;
}

.wcalendar .wdays {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  /* padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color); */
}

.wcalendar .wbody .wcell {
  position: relative;
  height: 2.5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}


.wcalendar .wbody .weecell {
  position: relative;
  height: 2.5em;
  /* border-right: 1px solid var(--border-color); */
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}


.wcalendar .wbody .wcell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.wcalendar .wbody .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.wcalendar .wbody .row {
  border-bottom: 1px solid var(--border-color);
}

.wcalendar .wbody .row:last-child {
  border-bottom: none;
}

.wcalendar .wbody .wcell:last-child {
  border-right: none;
}

.wcalendar .wbody .wcell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  /* right: 0.75em; */
  font-weight: 700;
}

.wcalendar .wbody .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.wcalendar .wbody .wcell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.wcalendar .wbody .wcell:hover .bg,
.wcalendar .wbody .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.wcalendar .wbody .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
/* manual css  */

.dayhours{
    width: 6%;
    /* height: 100%; */
    display: block;
    text-align: right;
    position: absolute;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    padding-top: 3rem
}
.rowpadd {
  padding: 1.1em 0.5rem 0rem 0.54rem;
}
.wnumber{
  font-size: 12px;
  font-weight: 400  
}

.wselectdt{
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 3px 8px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    color: #185abc;
    background-color: #d2e3fc;
    margin-top: -10px;
    margin-left: -10px;
}
* {box-sizing: border-box;}
ul {list-style-type: none;}
body {font-family: Verdana, sans-serif;}

.month {
  padding: 70px 25px;
  width: 100%;
  background: #1abc9c;
  text-align: center;
}
.tagenvents {
  margin-top: 4px;
  position: absolute;
  text-align: start;
  width: 13%;
}
.monthEventTag{
  background-color: rgb(3, 155, 229);
  color: white;
  border-radius: 4px;
  padding: 2px 2px 2px 7px;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: auto;
    
}
.moreEventTag{
  background-color: white;
  color: #3c4043;
  border-radius: 4px;
  padding: 2px 2px 2px 7px;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: auto;
}
.monthEventTag:hover {
  /* background-color: rgba(32,33,36,0.122); */
}

.moreEventTag:hover {
    background-color: #f1f3f4;
    border-radius: 4px;
}
.current-month{
  color:#3c4043 !important;
}
.T7dzVe {
  background-color: rgb(3, 155, 229);
  -webkit-border-radius: 4px;
  /* border-radius: 4px; */
  height: 14px;
  width: 14px;
  margin-left: 3px;
  margin-top: 3px;
}
.month ul {
  margin: 0;
  padding: 0;
}

.month ul li {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.month .prev {
  float: left;
  padding-top: 10px;
}

.month .next {
  float: right;
  padding-top: 10px;
}

.weekdays {
  margin: 0;
  padding: 10px 0;
  background-color: #ddd;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: #666;
  text-align: center;
}
.dayheader{
    border-right: 1px solid #ccc;
    font-size: 15px;
    line-height: 1.6;
}

.days {
  padding: 0px 0;
  /* background: #eee;
  
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  */
  margin: 0;
}

.days li {
  list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: -3px;
    font-size: 12px;
    color: #777;
    font-weight: bold;
}

.days li .active {
  padding: 5px;
  background: #1abc9c;
  color: white !important
}
.liborder{
  height: 16vh;
  /* border: 1px solid rgb(206, 201, 201); */
  border-bottom: 1px solid rgb(206, 201, 201);
  border-right: 1px solid rgb(206, 201, 201);
}
.daynamePop{
    font-size: 12px;
    font-family: sans-serif;
    margin-left: 15px;
    text-transform: uppercase;
}
.popDate{
  text-align: center;
    padding: 1rem 0rem;
    font-size: 23px;
    color: #3c4043;
}

/* Add media queries for smaller screens */
@media screen and (max-width:720px) {
  .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
  .weekdays li, .days li {width: 12.5%;}
  .days li .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
  .weekdays li, .days li {width: 12.2%;}
}

/* year view calendar css */

.contentyear {
  width: 256px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.cont{
  flex: 1 1;
  flex-basis: 20%;
  flex-direction: column;
}
.spantag{
  box-flex: 1;
  flex-grow: 1;
    padding-left: 5px;
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .25px;
    line-height: 20px;
    color: #3c4043;
}
.divsplit{
  display: flex;
  padding-top: 8px;
}
.divpadd {
  padding: 16px 14px 16px 19px;
}
.yearcalendars {
  padding: 0px 0;
  /* background: #eee; */
  margin: 0;
}
.yearcalendars li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;
  color: #777;
}
.MuiBackdrop-root {
    background-color: transparent !important;
  }
  
  .MuiPaper-rounded {
    border-radius: 8px !important;
  }
  
.divAppbar {
    width: 100%;
    height: 3rem;
    /* background: aliceblue; */
}
.width15{
    width: 15%;
}
.width11{
    width: 11%;
}
.flex{
    display: flex;
}
.dpadd8{
    padding: 8px 0px;
}
.dpadd6{
    padding: 6px 0px;
}
.btnlabel{
    font-weight: 600;

}
.upglist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
}
.uvlist{
    width: 7rem;
    height: 5rem;
    display: flex;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
}
.inuvlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #82CAFA;
}
.font13{
    font-size: 0.8rem;
}
.ptext{
    color:#82CAFA;
    font-size: 14px;
    font-weight: 600;
}
.spantxt{
    color:#777;
    font-size: 14px;
    font-weight: 600;
    margin-left: 1rem;
}
.whitecolor{
    color: #fff !important;
}
.whitecolor > .MuiIconButton-label {
    color: #fff !important;
}
.umlist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
/* card */
 
.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #82CAFA;;
    border-radius: 0 4px 0 32px;
}
.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}
.card1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #82CAFA;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.card1:hover:before {
    transform: scale(21);
}
.card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.card1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
/* module */
.inmlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #AF7AC5;
}
.cardm1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.cardm1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #AF7AC5;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardm1:hover:before {
    transform: scale(21);
}
.cardm1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.cardm1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.cardm1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
.gom1-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #AF7AC5;;
    border-radius: 0 4px 0 32px;
}
/* sec module */
.usbmlist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.insbmlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #2ECC71;
}
.cardsbm1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.cardsbm1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #2ECC71;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardsbm1:hover:before {
    transform: scale(21);
}
.cardsbm1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.cardsbm1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.cardsbm1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
.gosbm1-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #2ECC71;;
    border-radius: 0 4px 0 32px;
}
/* third module */
.utmlist {
    margin: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.intmlist{
    width: 100%;
    height: 100%;
    text-align: center;
    /* color: white; */
    font-weight: 600;
    padding: 1rem;
    border:1px solid #DE3163;
}
.cardtm1 {
    display: block;
    position: relative;
    border-radius: 8px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}
.cardtm1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #DE3163;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardtm1:hover:before {
    transform: scale(21);
}
.cardtm1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.cardtm1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.cardtm1:hover span {
    transition: all 0.3s ease-out;
    color: #fff;
}
.gotm1-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background: #DE3163;
    border-radius: 0 4px 0 32px;
}
.modArrowbtn{
    text-align: right;
    padding: 5px;
}
.umodDivBody {
    padding: 0 0 0 0px;
    height: 63vh;
    
}
.grplistActive{
background: #82CAFA;
color: #fff;
}
.modliActive{
    background: #AF7AC5;
    color: #fff;
}
.submodliAct{
    background: #2ECC71;
    color: #fff;
}
.thirdmodliAct{
    background: #DE3163;
    color: #fff;
}
.width100{
    width: 100%;
}
.floatwid{
    float: right;
    width: 50% !important;
}
.popdivm{
    width: 9rem;
    display: flex;
}
.font13p{
    font-size: 13px;
    line-height: 2.8;
}
.popdivm:hover{
    background-color:#e8e8e8;
 }
 .popElem{
     padding: 0.6rem;
 }
 .menupopdes > .MuiPaper-elevation8 {
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149) !important;
    background-color: #fff;
    border-radius: 8px !important;
 }
/* @import './HomePage.css'; */
/* @import './menuNew.scss'; */

/* @import './../..//pages/HomePage/assets/css/smartSafari.css'; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Roboto",sans-serif !important; 
  background: #ebeef1 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 ul li { display: flex;justify-content: space-around; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiInputLabel-shrink{
  color: #337ab7 !important;
}

.passwordStyle{
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
.TextWeight400{
  color: #33234E;
  font-size: 13px;
  font-weight: 600;
  -webkit-user-select: none;
          user-select: none;
}


.carousel{
  height:79vh
}
.MuiTypography-body1 {
  font-size: 14px !important; 
  font-family: "Roboto", sans-serif !important;
} 

  .MuiPaper-rounded{
    border-radius : 0px !important; 
  }
  
  .MuiAccordion-rounded{
    /* border-bottom: 1px solid #ebecf1; */
    box-shadow: none !important;

  }
  .MuiBox-root {
    margin: 0px 8px !important; 
  }
/* .MuiPaper-elevation1 {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14) !important;
} */
  .styles_simpleReactWeather__3uy4l {
    color: #000 !important;
    padding:15px 0px !important;
    text-align: left !important;
  }
  .styles_simpleReactWeatherTemp__2PnUn {
    color:#000 !important;
    font-weight: 400 !important;
  }
  .styles_simpleReactWeatherIcon__3sqXV {
    margin-bottom: 10px !important;
  }
  .styles_simpleReactWeatherCity__zyECF {
    font-weight: 400 !important;
  }

  /* .MuiPaper-elevation1{
    box-shadow: none !important;
  } */
  .rbc-event-content{
    text-align: center;
  }
  #viewoverlay{
    cursor: pointer;
    height: 100%;
    width: 0;
    margin-top: 47px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: whitesmoke;
    overflow-x: hidden;
    transition: 0.5s;
}

.MuiDialog-paper .MuiGrid-item {
  padding: 0 15px !important;
}

 .MuiDialog-paper .viewModal .MuiGrid-item {
  padding: 0px !important;
}

.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.reasonForReassign_call_center .errorinput {
  border-bottom: 2px solid #337ab7 !important;
}

.dropdownMargin{
  margin-top: 8px;
}
.Checkboxmargin{
  margin-top: 15px !important;
}
/* Asset side menu */
.sideMenuIcon{
  margin-left: 7px;
  width: 20px !important;
  color: #fff;
  }
.Main{
  cursor: pointer;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 98;
  top:52px;
  right: 41px;
  background: hsla(0,0%,51%,.5);
  color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  }

.tabClose{
  top: 48px;
  background: #34ace0!important;
  color: #fff;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  position: absolute;
  }
.child{
  position: absolute;
  background-color: #f5f5f5;
  right: 0;
  }
.tabHead{
background-color: #214F77 !important ;
color: #FFF; 
}
.slideHeading {
  color: #fff;
  }
.tabBody{ 
  padding: 12px;
  /* margin-top: 30px; */
  /* padding-bottom: 10%; */

  }
.helpdeskSide{
width: 4%;
}
.removebtn{
  margin-top:'-20 !important';
  margin-left:'8px';
}

/* Page 404 Css */
.c{
  text-align: center;
  display: block;
  position: relative;
  width:80%;
  margin: auto;
}
._404{
  font-size: 5rem;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 240px;
  padding-top: 120px;
  letter-spacing: 15px;
}
._1{
  text-align: center;
    display: block;
    position: relative;
    letter-spacing: 12px;
    font-size: 1.5em;
    line-height: 80%;
    /* padding-top: 20%; */
}
._1::first-letter {
  font-size: 2.5rem;
}
._2{
  text-align:center;
  display:block;
  position: relative;
  font-size: 1.25rem;
  padding: 13px 0px;
  color: #7a827f;
}
.text{
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}


.btn{
  background-color: rgb( 255, 255, 255 );
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  z-index: 5;
  font-size: 25px;
  margin:0 auto;
  color:#33cc99;
  text-decoration: none;
  margin-right: 10px;
  border-radius: 5px;
}
.right{
  float:right;
  width:60%;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #5f5d5de0;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
  position: absolute;
  padding: 3px 8px;
  bottom: -2.5em;
  /* left: 4.5rem;
  white-space: nowrap;
  /* box-shadow: 1px 1px 3px #222222; */
  opacity: 0;
  /* border: 1px solid #111111; */
  z-index: 99999;
  line-height: 1.5;
  visibility: hidden;
  border-radius: 3px;
}
[data-title] {
  position: relative;
}
 
h3 {
  font-size: 1.825em;
  margin: 20px 0 10px;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal!important;
} 
h6 {
  font-size: .8em;
  text-transform: uppercase;
  font-weight: 500;
} 
h1 {
  line-height: 1.15em;
}
.displayflex{
  display: flex;
}
/* consult registry */
.display-sm-hide{
  display: none ;
}
.display-sm-show{
  display: block;
}
.with-100 {
width: 100%;
}
 
@media only screen and (min-width: 600px) {
  .display-sm-hide{
    display: block;
  }
  .display-sm-show{
    display: none;
  }
 
}
  
.Calendar__sectionWrapper{
  min-height: 250px !important;
}

.height88{
  height: 88vh;
}
.height71{
  min-height: 71vh;
}

/* Call Center  */
.cardbtn{
    height: 5rem;
    border-bottom: 1px solid rgb(238, 238, 238) !important;
    justify-content: flex-start !important;
}
.cardTab {
  border-radius: 5px !important;
  box-shadow: 0px 6px 8px rgba(4, 4, 7, 0.1) !important;
  border: 1px solid #eee;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
}
.callApplyButton {
  background: #337ab7 !important;
  margin-left: 10px !important;
  padding: 0px !important;
  height: 25px;
  border: 1px solid #fff !important;
  margin-top: 2px !important;
}
.AccHtext{
  color: #6495ED;
  font-weight: bold
}
@media screen and (max-width: 1280px) {
  .AccHtext{
    color: #6495ED;
    font-weight: bold;
    font-size: 12px;
  
  }
}
.flexEnd{
  display: flex;
    align-items: flex-end;
}

div.MuiModal-root:has(> div[style*="opacity: 0"]) {
  pointer-events: none;
}

/* datepicker */
.datePickerItemError .MuiInput-underline::before {
  border-bottom: 2px solid red !important;
}

#reasonForMeeting, #reasonForReassign {
  border-left: 1px solid #337ab7;
  border-right: 1px solid #337ab7;
  border-bottom: 1px solid #337ab7;
  padding-top: 5px;
  min-height: 95px;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 900px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 7%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.MuiDialog-paper .MuiGrid-item.tooltipPassRules {
  padding: 0px !important;
}

.tooltipPassRules .tooltipbottom .tooltiptext {
  bottom: 51%;

  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

}

.tooltip-bottom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ccc;
  color: #006080;
}

.tooltip-bottom:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-bottom .tooltiptext {
  visibility: hidden;
  position: absolute;
  top: 51px;
  right: 0px;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.change-password .tooltiptext {
  right: -266px;
}

.tooltiptext-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 95%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.change-password .tooltiptext-bottom::after {
  left: 6%;
}

.tooltip-bottom:hover .tooltiptext {
  visibility: visible;
}
.ProCntCrd{
  background: linear-gradient(45deg,#4099ff,#73b4ff) !important;
  color: #fff !important;
}
.DocCntCard{
  background: linear-gradient(45deg,#FF5370,#ff869a) !important;
  color: #fff !important;
}
.HomeCardIcon{
  margin-top: 6px;
}
.leftbox {   
    height: 100%;
    text-align: center;
    background: #214F77 !important;  
  }
.homePageText{
  /* color: #343a40; */
  font-size: 0.88rem;
  margin-top: 5px;
  min-height: auto;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  text-decoration: none;
} 
.homePageNum{
  /* color: #34ace0 !important; */
  cursor: pointer !important;
  font-size: 25px !important;
  margin: 0px;
}  
.HomeCardCnt{
  height: 90px !important;
}
.HomeTrendCard{
  margin: 0px !important;
}
.HomeCardIcon{
  /* position: absolute;
  right: 0px; */
  /* top: 0px;   */
}
.HomeCardIconClr{
  background: #34ace0 !important;
}
.DocTypeExpand img{
  position: absolute;
  right: 10px;
  /* z-index: 100; */
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.DocExpClsIcn{
  position: absolute;
  right: 0;
  color: #fff;
  cursor: pointer;
  z-index: 100;
} 
.HomePieCard{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
/* new home page css 11-04-2021 */
.carddiv{
    text-align: center;
    display: table;
    width: 100%;
    height: 5.5rem;
}
.alignCarddiv{
  display: table-cell;
    vertical-align: middle;
}
.dtable{
  display: table;
}
.dtablecell{
  display: table-cell;
  vertical-align: middle;
}
.homeCardtxt{
    color: #3C4858;
    font-size: 14px;
    margin-top: 0;
    min-height: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 0px;
    text-decoration: none;
}
.fontnumbers{
    font-weight: bold;
    font-size: 24px;
}
.margtop5{
  margin-top: 5px !important;
}
.margtop15{
  margin-top: 1.5rem !important;
}
.floatdiv{
  padding: 5px 20px 0px 0px;
  display: flow-root;
}
.rowleft{
  float: left;
  font-size: 12px;
  color: #141414;
}
.rowright{
float: right;
font-size: 12px;
  color: #141414;
}
.tableCard { 
  transition: 0.3s;
  display: grid;
  margin: 0px;
  z-index: 2;
  height: auto;
  width: 100%;
  padding: 0px;
  overflow: auto;
}

.tableCardDiagnosis { /*tableCardL1*/
  height: 70vh;
  padding-top: 0.5%;
}

.AccDetails{
  position: relative !important;
  z-index: 1 !important;
}

.tableHeaderItems{      
  padding-top: 4px;
}

a {
	 text-decoration: none;
	 transition: all 0.6s ease;
}
 a:hover {
	 transition: all 0.6s ease;
}
 .menuwid {
	 width: 95% !important;
}
 .app {
	 height: 100vh;
}
/* ------------- Sidebar ----------------*/
 .sidebar {
	 position: fixed;
	 width: 5%;
	 height: 100%;
	 top: 0%;
	 margin-top: 52px;
	 left: 0px;
	 overflow: hidden;
	 background-color: #251E8F;
	 -webkit-transform: translateZ(0);
	 visibility: visible;
	 -webkit-backface-visibility: hidden;
}
 .sidebar header {
	 background-color: #09f;
	 width: 100%;
	 display: block;
	 padding: 0.75em 1em;
}
 .sidebar ::-webkit-scrollbar-thumb {
	 background: #fff;
}
 .sidebar ::-webkit-scrollbar-thumb:hover {
	 background: #fff;
}
 .sidebar ::-webkit-scrollbar {
	 width: 0px;
}
 ::-webkit-scrollbar {
	 width: 2px;
}
/* ------------- Sidebar Nav ----------------*/
 .sidebar-nav:hover {
	 overflow: auto;
	 overflow-x: hidden;
}
 .sidebar-nav {
	 position: fixed;
	 background-color: #251E8F;
	 height: 90%;
	 width: 100%;
	 font-weight: 400;
	 font-size: 1.2em;
	 padding-bottom: 0;
	 z-index: 9;
	 overflow: hidden;
	 -webkit-overflow-scrolling: touch;
	/* --------------- active cls ------------------- */
	/* ------------- Chev elements ----------------*/
	/* ------------- Nav-Flyout ----------------*/
}
 .sidebar-nav ul {
	 list-style: none;
	 display: block;
	 padding: 0;
	 margin: 0;
}
 .sidebar-nav ul li {
	 margin-left: 3%;
	 padding-left: 0;
	 min-width: 13em;
	 display: inline-block;
	 width: 100%;
	/* ------------- Sidebar: icons ----------------*/
}
 .sidebar-nav ul li a {
	 color: #333;
	 font-size: 0.75em;
	 padding: 1.05em 1em;
	 position: relative;
	 display: block;
}
 .sidebar-nav ul li a:hover {
	 color: #444;
	 background: rgba(80, 80, 80, 0.05);
	 transition: all 0.6s ease;
}
 .sidebar-nav ul li i {
	 font-size: 1.8em;
	 padding-right: 0.5em;
	 width: 9em;
	 display: inline;
	 vertical-align: middle;
}
 .sidebar-nav .active {
	 background-color: #09f;
	 transition: all 0.6s ease;
}
 .sidebar-nav > ul > li > a:after {
	 content: '\f125';
	 font-family: ionicons;
	 font-size: 0.5em;
	 width: 10px;
	 color: #000;
	 position: absolute;
	 right: 0.75em;
	 top: 45%;
}
 .sidebar-nav .nav-flyout {
	 position: absolute;
	 background-color: #fff;
	 z-index: 9;
	 left: 3.1em;
	 top: 0;
	 height: 100vh;
	 transform: translateX(100%);
	 transition: all 0.5s ease;
	 /* box-shadow: 0 4px 11px 1px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.08); */
	 overflow: scroll;
	 border-left: 0px solid #e3e4e9;
}
@media screen and (max-width:1280px) {
	.sidebar-nav .nav-flyout{
		left: 2.05em;
	}
}
 /* .sidebar-nav .nav-flyout a:hover {
	 background-color: rgba(255, 255, 255, 0.05);
} */
 .ulist-padding {
	 width: 84%;
	 margin-left: 12px !important;
	 padding-left: 0px !important;
	 padding-right: 0px !important;
	 padding-bottom: 64px !important;
}
 .subitem-list {
	 color: #444;
	 display: block;
	 font-size: 0.75rem;
	 margin-top: 0px;
	 font-weight: 600;
	 line-height: 28px;
	 padding-left: 0;
	 font-family: Maven Pro, sans-serif;
	 text-transform: uppercase;
}
@media (max-width: 1200px){
	.subitem-list {
		font-size: 8px;
	}
}
 .MenuExpandicon {
	 color: #444;
}
 .Accmenucontent:hover {
	 background: #337ab7;
}
 .Accmenucontent:hover .MenuExpandicon {
	 color: #fff;
}
 .Accmenucontent:hover .subitem-list {
	 color: #fff;
}
 .Accmenucontent:hover .Acciconsm {
	 color: #fff;
}
 .Accmenucontent {
	 display: flex;
	 width: 100%;
	 padding: 8px;
}
 .clsactive {
	 color: #09f;
}
.tgactive {
	border-left: 5px solid #251E8F !important;
}
.tgactiveSmall {
	background: #fff;
	border-top-left-radius: 10px !important;
   border-bottom-left-radius: 10px !important;
}
.tgactiveSmall .iconpad {
	color: #251E8F;
}
 .ripple {
	 background-position: center;
	 transition: background 0.8s;
}
 .subfontSty {
	 font-size: 0.75rem;
	 font-weight: 400;
	 line-height: 1.83;
	 padding-left: 2%;
	 width: -webkit-fit-content;
	 width: -moz-fit-content;
	 width: fit-content;
	 max-width: 75%;
}
 .padd5 {
	 display: flex;
	 color: #337ab7;
	 margin: 0rem 0rem 0rem 0rem !important;
	 margin-left:-22px !important;  /*rayan*/
	 padding: 5% 5% 3% 5% !important;
	 border-radius: 0px;
	 height: -webkit-fit-content;
	 height: -moz-fit-content;
	 height: fit-content;
	 border-bottom: 1px solid #e3e4e9;
}
 .iconsm {
	 font-size: 14px !important;
	 width: 1em;
	 height: 1em;
	 margin: 3px 5px 0px 15px !important;
}
 .iconsmdrop {
	 font-size: 14px !important;
	 width: 1em;
	 height: 1em;
	 margin: 3px 8px 0px 8px !important;
	 color: #555;
}
 .btnDiv {
	 width: 100%;
	 display: flex;
	 padding: 8px;
	 text-align: center;
	 border-radius: 0px;
	 margin-bottom: 0px;
	 flex-direction: column;
	 cursor: pointer;
	 border-left: 5px solid #251E8F;
	 /* border-bottom: 1px solid #e4e4e4; */
}
 .btnDiv:hover {
	 border-left: 5px solid #251E8F;
	 background: rgba(80, 80, 80, 0.05) radial-gradient(circle, transparent 1%, rgba(80, 80, 80, 0.05) 1%) center/15000%;
	/* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	*/
}
 .btnDiv:hover .labeltxt, .btnDiv:hover .iconpad {
	 color: #251E8F;
}
 .Divbtnlvl {
	 width: 100%;
	 display: flex;
	 padding: 5px 10px 0px 3px;
	 text-align: center;
	 border-radius: 0px;
	 margin-bottom: 5px;
	 border-left: 0px solid #fff;
}
 .homeicon {
	 width: 30px !important;
	 height: 30px !important;
	 margin-top: -8px !important;
	 box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	 border: 1px solid #ebecf1;
	 border-radius: 50px;
	 padding: 6px;
	 margin-left: 0;
}
 .labeltxt {
	 width: 100%;
	 display: block;
	 overflow: hidden;
	 font-size: 0.65rem;
	 font-family: sans-serif;
	 -webkit-font-smoothing: antialiased;
	 line-height: 1.5;
	 color: #fff;
	 font-weight: 400;
}
 .iconspan {
	/*box-shadow: 10px -10px 5px rgba(0,0,0,0.6);
	 -moz-box-shadow: 10px -10px 5px rgba(0,0,0,0.6);
	 -webkit-box-shadow: -1px 2px 3px 0px rgba(0,0,0,0.6);
	 -o-box-shadow: 10px -10px 5px rgba(0,0,0,0.6);
	*/
	 border-radius: 25px;
	 /* border: solid 1px #e3e4e9; */
	 width: 40px !important;
	 height: 40px !important;
	 display: inline-block;
	 margin-bottom: 5px;
	 /* box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1); */
	/*box-shadow: 0px 0px 5px -5px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	*/
}
@media screen and (max-width:1280px) {
	.iconspan{
		width: 30px !important;
		height: 30px !important;
	}
}
 .iconpad {
	 color: #fff;
	/* display: block;
	 / / font-size: 1.7rem !important;
	 */
	 margin: 0;
	 margin-bottom: 0;
	 padding-top: 0;
	 line-height: 1.5;
	 height: 2.3rem !important;
}
/* Ripple effect */
 .ripple {
	 background-position: center;
	 transition: background 0.8s;
}
 /* .ripple:hover .iconspan {
	 border: solid 1px #337ab7;
} */
 .ripple:hover {
	background: #fff;
	border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
	/* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	*/
}
 .rippleSmall {
	 background-position: center;
	 transition: background 0.4s;
}
 .rippleSmall:hover {
	background-color: #fff;
	cursor: pointer;
	border-left: 5px solid #251E8F;
	border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
	/*background: rgba(80,80,80, 0.05) radial-gradient(circle, transparent 1%, rgba(80,80,80, 0.05) 1%) center/15000%;
	*/
	/* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	*/
}
/*.rippleSmall:active {
	 background-color: #bdbdbd;
	 background-size: 100%;
	 transition: background 0s;
}
*/
 .rippleSmall:hover .labeltxt, .rippleSmall:hover .iconpad {
	 color: #251E8F;
}
 /* .rippleSmall:hover .iconspan {
	 border: solid 1px #337ab7;
} */
/*.Divbtnlvl:before{
	 top: 0;
	 left: -8px;
	 width: 12px;
	 height: 35px;
	 z-index: 10;
	 content: "";
	 position: absolute;
	 background: #09f;
	 border-radius: 5px;
}
*/
/*.tagripple:hover {
	 background: rgba(80,80,80, 0.05) radial-gradient(circle, transparent 1%, rgba(80,80,80, 0.05) 1%) center/15000%;
}
*/
 .tagripple:active {
	 background-color: #bdbdbd;
	 background-size: 100%;
	 transition: background 0s;
}
 .sidebar-nav .nav-flyout a:hover {
	 color: #000;
	 background-color: #337ab7;
}
 li {
	 list-style-type: none;
}
 /* .menuSearch {
	 margin-top: 8px;
	 width: 50%;
	 border: 1px solid #ebecf1;
	 border-radius: 5px;
	 /* display: flex */
/* }
.menuSearch > div {
	display: flex
}
 .menuSearch div {
	 box-shadow: none;
	 background: #eee;
}  */
.ComSearchIcon{
	width: 35px !important;
	cursor: pointer;
   	color: #000 !important;
	/* padding-top: 4px; */
	border-right: 1px solid;
	height: 32px !important;
	padding: 5px;
}
 .MuiIconButton-edgeEnd {
	 margin-right: 8px !important;
}
 .Acciconsm {
	 font-size: 14px !important;
	 width: 1em;
	 height: 1em;
	 margin: 6px 8px 0px 8px !important;
	 color: #444;
}
 .Menuopenall {
	 font-size: 0.6rem;
	 color: #337ab7;
	 margin-top: 1%;
	 position: relative;
	 margin-left: auto;
	 margin-right: 10%;
}
@media (max-width: 1200px){
	.Menuopenall {
		margin-right: 10%;
	}
}
 
.dashcardb {
  padding: 1%;
  height: 85vh;
  display: flex;
  align-items: center;
}
.dgridcont {
            margin: 0 !important;
            width: 100% !important;
            padding: 0% 10% !important;
}
.dgridI {
            padding: '1% 2% 1% 0%' !important;
}
.dgridIsub1 {
            padding: '0% 1% 1%' !important;
}
.dgridIsub2 {
            padding: '1% 1% 0%' !important;
}
#ulanimate{
    position: relative;
    animation: changeBg 10s infinite; 
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;     
}

@keyframes changeBg{
    0% ,from { opacity: 1;left:0px
         }
    100%,to {opacity: 0;left:350px}
 } 

 .ant-steps-vertical > .ant-steps-item .ant-steps-item-content{
    min-height: 44px !important;
 }

 .ant-steps-item-title{
     font-size: 14px !important;
 }

 .loginicon{
   margin-left: 6%;
 }

 .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #33234E !important;
 }
.maintenance_container {
  background-color: #F8F4FB;
  padding: 10px 75px;
}

.maintenance_body_title img {
  height: 50px;
  width: 154px;
}

.logo_desc {
  display: initial;
}

.logo_mobile {
  display: none;
}

.maintenance_body_title {
  max-width: 522px;
  align-self: center;
}

.maintenance_body h1 {
  color: #340B54;
  line-height: 58px;
  letter-spacing: 0.4px !important;
  font-size: 56px;
  font-family: 'Lato';
  font-weight: 700;
  margin-bottom: 0px;
}

.maintenance_body h3 {
  color: #9A85A9;
  line-height: initial;
  letter-spacing: 0.2px !important;
  font-size: 28px;
  font-family: 'Lato';
  font-weight: 400;
}

.maintenance_body_title .MuiGrid-root {
  margin-bottom: 21px;
}

.maintenance_logo {
  max-width: 100%;
  max-height: 600px;
}

.maintenance_logo_link, .maintenance_logo_link a {
  color: #9A85A9;
  text-align: right;
  line-height: 12.1px;
  font-size: 10px;
  font-family: 'Inter';
  font-weight: 400;
}

@media (max-width: 600px) {
  .maintenance_container {
    background-color: #F8F4FB;
    padding: 42px 24px 0px;
  }

  .maintenance_body_title img {
    height: 57px;
    width: 174px;
  }

  .app_logo {
    display: flex;
    justify-content: center;
    margin-bottom: 51.5px;
  }

  .logo_desc {
    display: none;
  }

  .logo_mobile {
    display: initial;
  }

  .maintenance_body {
    max-height: 90%;
    max-width: 342px;
  }

  .maintenance_body_title {
    display: flex;
    justify-content: center;
  }

  .maintenance_body_title .MuiGrid-root {
    margin-bottom: 14px;
  }

  .maintenance_body h1 {
    text-align: center;
    line-height: 34px;
    letter-spacing: 0.2px !important;
    font-size: 32px;
  }

  .maintenance_body h3 {
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.1px !important;
    font-size: 14px;
    font-weight: 700;
  }

  .maintenance_logo {
    max-width: 328px;
    max-height: 328px;
    margin-top: 10px;
  }

  .maintenance_logo_link, .maintenance_logo_link a {
    text-align: center;
    margin-bottom: 6px !important;
  }
}
