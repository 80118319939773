.DragCheckbox{
    padding: 0px !important;
}
::placeholder {
    color: #8A99B5;

  }
.flexEnd{
    display: flex;
    align-items: flex-end;
}

/* .MuiFormControlLabel-root
{
    padding: 9px !important;
}
.PrivateSwitchBase-root{
    padding: 0px !important;
} */
  .AttachScroll{
    padding: 0px 10px 10px;
    border: 1px solid rgb(204, 204, 204);
    height: 93vh;
    overflow: hidden;
    overflow-y: scroll;
  }

/* card css */
.detailCard{
    /* box-shadow: 0 4px 20px 1px rgba(0,0,0,.0), 0 1px 4px rgba(0,0,0,.08); */
    /* border: 1px solid #eee; */
    transition: 0.3s;
    display: grid;
    /* border-radius: 6px; */
    /* margin: 0 0px 0 4px; */
    /* z-index: 2; */
    /* height: 77vh; */
}
.detailDiv{
    width: 100% !important;
    /* margin-left: 4px !important; */
    /* height: 100%; */
    /* padding: 2px 15px; */
    margin: 0;
}
.ListHeader{
    /* padding: 2px 0 !important; */
    cursor: pointer;
    width: 100%;
}
.ListHeader .ListP{ 
    padding: 6px 0px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #337ab7 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.EntryHeader .ListP{ 
    padding: 8px 0px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #337ab7 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.EntryHeader .ListPlevl1 {
     
    color: #337ab7 !important;
}
.EntryViewHeader .ListP{ 
    padding-top: 7px;
    padding-left: 0px;
    font-size: 0.88rem;/*0.8rem;*/
    font-weight: bold;
    color: #337ab7 !important;
}
.EntryHeader{
    margin: 2px 6px !important;
    /* width: 30%; */
}
.EntryHeaderBtn{
    /* width: 20%; */
    margin-left: auto !important;
}
.EntryViewHeader{
    margin: 2px 0 !important;
    width: 70%;
}
.EntryHeaderViewBtn{
    margin-left: auto !important;
}

.backgroundWhite{
    background: #fff;
    /* background: #F0F9FF; */
}
.backgroundDark{
    background: #fff;
    /* background: #F0F9FF; */
}
.backgroundLightLev1{
    background: #fff;
    height: 42px;
}

.backgroundLight{
    background: #fff;
    /* #3d4977; */
    height: 42px;
}
.backgroundYellow{
    background: #ffda79;
}
.backgroundGreen{
    background: #5BB35F;
}
.infoIcon{
    font-size: 15px !important;
    color: #cd6133 ;
    margin: 5px;
}
.backgroundPurple{
    background: #864C99;
}

.viewTypes{
    position: relative;
    top: 2px;
    left: 2%;  
    width: 100%;
}


/* Common search css */
.searchbar{
    height: 30px;
    margin-top: 0px !important;
    width: 100%;
    background-color: #fff;
    /* border-radius: 30px; */
    /* padding: 10px; */
    font-size: 0.8rem;
    border: 1px solid #ebecf1;
}

.search_input{
    color: black;
    border: 0;
    outline: 0;
    background: none;  
    /* line-height: 10px; */
    padding: 4px 15px 0 15px;
    width: 100%;    
}

.search_icon{
    float: right !important;
    padding: 0rem 0.5rem 1rem 0.5rem;
    cursor: pointer !important;
    margin-top: -24px;
    margin-right: 0px;
    border-radius: 0px !important;
    background: #337ab7 !important;
    color: #fff !important;
    text-decoration: none !important;
    position: relative;
    left: 0px;
    height: 29px;
}


/* Common search css */
.searchbard2{
    height: 30px;
    margin-top: 0px !important;
    width: 100%;
    background-color: #eeee;
    border-radius: 8px;
    /* padding: 10px; */
    font-size: 0.8rem;
    display:flex;
    /* border: 1px solid #ebecf1; */
}


.search_icond2{
    /* float: right !important; */
    padding: 0rem 0.5rem 1rem 0.5rem;
    cursor: pointer !important;
    /* margin-top: -22px; */
    margin-right: 0px;
    border-radius: 0px !important;
    /* background: #337ab7 !important; */
    color: #fff !important;
    text-decoration: none !important;
    position: relative;
    left: 0px;
    height: 29px;
    border-right: 1px solid #000;

}

.InputSearchIcond2{ 
    width: 20px !important;
    cursor: pointer;
    color: #000 !important;
    padding-top: 4px;
}

.CommonSearchInputDiv{
    position: relative;
    width: 100% !important;
    height: 0px;
}
.RoundedInput{
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #5C69E1;
    width: 100%;
    padding-left: 15px;
}
.InputSearchIcon{ 
    width: 20px !important;
    cursor: pointer;
    color: #fff !important;
    padding-top: 4px;
}
.SearchIconDiv{
    background: #5c69e1 !important;
    position: absolute;
    /* width: fit-content; */
    padding: 0 12px;
    left: 91%;
    border-radius: 20px;
    bottom: 15px;
    text-align: center;
}
.InputSearchClearIcon{
    width: 20px !important;
    cursor: pointer;
    color: #fff !important;
}
.borderRadiusTop{
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
.height85{
    height: 85vh;
}
.floatLeft{
    float: left;
    border-right: 1px solid #ccc;
}
.floatLeftOnly {
    float: left;
}
.displayNone{
    display: none !important;
}
.displayFlex{
    display: flex !important;
}
.displayGrid{
    display: grid !important;
}
.componentMainDiv{
    /* height: 80vh; */
    /* margin-top: 3%; */
    padding-bottom: 1%;
    border-bottom: 1px solid #e3e4e9;
}
.floatOrder{
    margin-left: auto !important;
    order: 2 !important;
}
.padding{
    padding: 5px !important;
}
.padding2{
    padding: 2px !important;
}
.padding0{
    padding: 0px !important;
}
.paddingLeft{
    padding-left: 15px !important;
}
.scrollYDefault{
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.scrollY{
    overflow-y: auto;
    overflow-x: auto;    
}
.scrollY:hover{
    overflow-y: auto;
    overflow-x: auto;
}

.PaginationDropDown{
    /* margin-top: -20px !important; */
    /* margin: 0px !important; */
    position: relative !important;
    top: -5px !important;
}
.tableFilterColumnIcon{
    color: #444 !important;
    cursor: pointer !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: 3px;
    transition: all 0.3s ease-out !important;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #e3e4e9;
    padding: 16%;
}
.tableFilterColumnIcon:hover{
    border: 1px solid #337ab7;
    color: #337ab7 !important;
    background: #fbfbfb;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
}
.tablExpicon{
    /* margin-left: 10px !important; */
    /* padding: 5% !important; */
}
.iconinfo{
    padding-left: 5px;
    font-size: 0.625rem;
}
.buttoncard{
    display: inline-block;
    height: 35px;
    width: 35px;
    float: left;
    margin: 0 5px;
    overflow: hidden;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    /* box-shadow: 0px 10px 10px rgba(0,0,0,0.1); */
    transition: all 0.3s ease-out;
    user-select: none;
  }
  /* .buttoncard:hover{
    width: 90px;
  } */

.tableExpandIcon{
    color: #337ab7 !important;     
    cursor: pointer !important; 
}  
/* Sort Css */
.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #34ace0 !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
    color: #34ace0 !important;
}
/* draft 
.badge{
    margin:0px 12px 0 10px !important;
}
.badge span{
    height: 15px;
    min-width: 12px;
    font-size: 0.563rem;
    font-weight: bold;
}*/

.boxShadowNone{
    box-shadow: none !important;
}

.ColumnApplyIcon{
    color: #fff;
    /* padding-bottom: 4px !important; */
    font-size: 12px !important;
}
.ColumnApplyTitle{
    color: #fff !important;
    font-size: 9px !important;
    font-weight: 600;
}
.ColumnApplyButton{
    background: #337ab7 !important;
    margin-left: 2px !important;
    padding: 0px !important;
    height: 20px;
    border: 1px solid #fff !important;
    margin-top: 2px !important;
}
.DragBackGround:hover {
    background: #ccc;
}

/* Column css */
.MUICheckbox .MuiSvgIcon-root {
height: 19px !important;
}
.columnDiv{
    /* border: 1px solid #ddd; */
    margin: 0 0px 0 0; 
}
.DropdownDiv{
    margin: 0 5px 0 0; 
}
.columnHeaderIcon{
    color: #337ab7 !important;
    font-size: 1rem !important;
    margin: 4px 5px;
}
.columnHeader{
    color: #337ab7;
    padding: 7px 5px;
    font-size: 0.75rem;
    background-color: #fff;
    border-bottom: 1px solid #e3e4e9;
}
.columnHeaderbtn{
    width: 35%; 
    /* padding: 2px;    */
    margin: 0px !important;
}
.columnFilterHeaderTitle{    
    width: 100%;
    display: flex;
    padding: 0 0px;
}
.columnHeaderTitle{    
    width: 65%;
    display: flex;
    padding: 0 0px;
}
.columnHeaderp{
    color: #337ab7;
    font-weight: 600;
    font-size: 0.75rem;
    margin-left: 0px !important;
    padding-top: 3px;
}
.columnHeaderdesc{
    font-size: 0.563rem;
    font-weight: bold;
    color:#cd6133;
    margin-top: 5px !important;
    margin-left: 2px !important;
}
.columnCheckboxDiv{
    display: grid;
    height: 64vh; 
    border: 2px solid #eee;
}
.dragCheckboxDiv{
    height: 53vh;
    overflow: auto;
    /* position: absolute !important; */
}
.dragCheckboxDivL1{
    height: 50vh;
    overflow: auto;
}
.DragDrop .dl-list{
    height: auto !important;
}
.dragItem{
    font-family: sans-serif; 
    /* background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 4px;
    padding: 0px;
    display: flex; */
    margin-bottom: 4px;    
}
.dragListItem{
    background: #EBEEF1;
} 
.activeDragListItem .check-list-item {
    background: #EBEEF1 !important;
}
.activeDragItem .check-item {
    /* background: #C7E7CC !important; */
    background: #ebfaeb !important;
}
.check-item{
    /* background:#fff; */
    /* border: 1px solid #ddd; */
    /* border-radius: 5px; */
    padding: 3px 15px; 
}
.check-list-item{
    margin: 2px;
    padding: 3px 15px; 
}
.backButtonList{
    color: #337ab7;
    margin-top: 1px;
    margin-right: 5px;
    font-size: 22px !important;
    margin-left: 10%;
}
.ViewBtn.ViewBtnAct{
    background: rgba(202, 202, 202, 0.34) radial-gradient(circle, transparent 1%, rgba(80, 80, 80, 0.05) 0) 50%/15000% !important;
    color: #337ab7 !important;
}
.ViewBtn{
    font-size: 10px !important;
    border: none;
    outline: none;
    padding: 2px 16px !important;
    background-color: #f1f1f1;
    color: #337ab7 !important;
    cursor: pointer; 
    border-radius: 0 !important;
} 
/* Customer Enq field css */
.ArrowIcons{
    cursor: pointer;
    color: #337ab7;
    margin: 5px 5px !important;
}
.ArrowIconsLight{
    cursor: pointer;
    color: #337ab7 !important;
    margin: 5px 5px !important;
}
.FullSizeIcon{
    cursor: pointer;
    color: #337ab7;
    margin: 10px !important;    
    font-size: 18px !important;
}

/* Floating Button */
.MuiFab-root {
    width: 30px !important;
    height: 30px !important;
}
.MuiFab-primary {
    background-color: #34ace0 !important;
}
.MuiSpeedDialIcon-icon {
    position: absolute;
    left: 3px;
    padding: 2px;
}

/* New Design CSS */
.MainDivStyles{
    padding-top: 1.5rem;
    margin-bottom: 1%;
    width: 100%;
}


/* Accordian Css */
.MuiAccordionSummary-root {
    padding: 0 !important;
    border-bottom: 1px solid #e3e4e9 !important;

}
.MuiAccordionDetails-root {
    padding: 0px !important;
}
.MuiAccordion-root.Mui-expanded{
    margin: 0px !important;
}
.MuiAccordion-root:before{
    content: none !important;
}
/* View Details */
.ViewDetailLabel{
    font-size: 0.688rem;
    color: black;
    font-style: italic;
}
.ViewDetailLabel span{
    float: right;
}
.ViewDetailText{
    font-size: 0.75rem;
    font-weight: 600;
    font-style: italic;
}
/* Filter Slide */
.level2DropDown{
    /* height: 30vh; */
    /* margin: 0 12px; */
}
.FilterParentDiv{
    background-color: initial;
    border: none;
    /* border-bottom: 1px solid #E6E6E6; */
    position: relative;
    width: 100%;
    min-height: 20px;
    max-height: 200px;
    /* overflow-y: auto; */
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 20px;
    outline: none;
    transition: border-color .2s ease-out;
}
.FilterParentDiv ul{
    padding: 5px 10px;
    margin-bottom: 0px;
}
.heightScrollY{
    overflow-y: auto;
    min-height: 20px;
    max-height: 120px;
}
div[data-list-scroll-container] {
    transform: translateZ(0);
}
.filterSelectedItems{
    /* margin-right: 6px; */
    margin: 3px;
    padding: 0;
    background: #EBFAEB;
    color: #222F3E;
    border-radius: 6px;
    cursor: default;

    display: inline-flex;
    height: auto;
    vertical-align: top;

    position: relative;
    /* display: flex; */
    flex-direction: row;
    /* height: 72px !important; */
    pointer-events: all;

    width: fit-content;
    /* min-width: 100px; */
}
.filterSelectedItemNames{
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;

    padding: 0;
    border-top: none!important;

    max-width: 250px;
}
.filterSelectedItemNames span{
    display: inherit;
    overflow: inherit;
    white-space: inherit;
    text-overflow: inherit;

    display: inline-block;

    position: relative;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    visibility: visible;
    margin-left: 15px;
    margin-top: 2px;
}
.filterSelectedItemClose{
    padding: 0;
    border-top: none!important;
    background: #ddd;
    font-weight: 400!important;
    font-size: 13.5px;

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    align-self: center;
    height: 100%;

    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;

    margin-left: 6px;
    margin: 4px;
    padding: 1px;
    border-radius: 50%;
    flex: none;
}
.filterSelectedItemCloseIcon{
    color: #8898aa;
    font-size: 13px !important;
    cursor: pointer;
}
.filterSelectedItemCloseIcon:hover{
    color: black;
}
.filterSearchInput{
    width: 366px;

    margin: 5px;
    padding: 2px 12px 2px 25px;
    background: #F3F6F9;
    border: 1px solid #ddd; 
    display: inline-block;
    width: 100%;
    height: auto;
    color: var(--primary-text);
    font-size: 0.625rem;
    line-height: 22px;
    vertical-align: top;
    outline: none;

    user-select: text;
}
.FilterItemDiv{
    padding: 5px;
    border-bottom: 1px solid #ebecf1;
    cursor: pointer;
    font-size: 0.75rem;
    cursor: pointer;    
}
li .FilterItemDiv{
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
}
.FilterItemDivBody{        
    padding: 0 0 0 0px;
    height: 30vh;    
}
.FilterItemDiv:hover{
    background: #F3F6F9;
    color: #337ab7;
    font-weight: 600;
    font-size: 0.813rem;
    width: 100%;
}
.FilterItemDivHeader{
    width: 100%;
    /* margin: 0 12px;     */
    padding: 2px 0 2px 10px;
    border-bottom: 1px solid #e6e6e6; 
    font-size: 0.75rem;
    font-weight: 500;
    background: #337ab7;
    color: #fff;    
}
.widthfit{
    width: fit-content;    
}
.width10{
    width: 10%;    
}
.width20{
    width:20%;
}
.width30{
    width: 30%;
}
.width40{
    width: 40%;
}
.width50{
    width: 50%;
}
.width60{
    width: 60%;
}
.width70{
    width: 70%;
}
.width80{
    width:80%;    
}
.width90{
    width: 90%;
}
.width100{
    width: 100% !important;
}
.circleRound{
    background: #02B152 !important;
    border-radius: 4px;
    margin: 5px;
    padding-top: 3px;
    padding-left: 6px;    
    color: #fff !important;
    box-shadow: 0 1px 4px 0 #eee;
    cursor: pointer;
    /* height: 20px; */
}
.circleRoundIcon{
    font-size: 18px !important;   
    padding-right: 3px;
}
.MuiTablePagination-actions button{
    padding: 4px !important;    
   }
.MuiTablePagination-actions{
    margin-left: 0 !important;
}
   #overlayimgAtt {
    cursor: pointer;
    height: 100%;
    width: 0px; 
    overflow-x: hidden;
    display: none;
   }
    /* position: fixed; */
    /* z-index: 20000;
    top: 20;
    right: 0;
    background-color: #F2F2F2;
     */
    /* transition: 0.2s; */
    /* padding-top: 60px; */
  /* }

/* .MuiButtonBase-root span{
margin: 4px !important;

} */

/* customise upload list */
.customise-upload{
    float: left;
    width: 100%;
    margin-right: 8px;
    position: relative;
    height: 52px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 2%;
}
.custom-item-info {
    padding: 0;
}
.imgOuter {
    position: absolute;
    top: 8px;
    left: 8px;
    /* width: 48px; */
    height: 48px;
    line-height: 54px;
    text-align: center;
    opacity: .8;
    display: flex;
    flex-direction: row;
}
.itemImage {
    display: block;
    width: 36px;
    height: 36px;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    margin-right: 5px;
}
.list-item-name {
    /* color: #777;
    padding-right: 18px;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 37px;
     white-space: nowrap;  
    text-overflow: ellipsis;
    -webkit-transition: all .3s;
    width: 80%;
    transition: all .3s; */
    font-size: 0.6rem;
}
.ViewHeadingcontent{
    background: #337ab7 !important;
    color: #fff !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    padding: 0px 20px;
    /* padding-left: 9px; */
    margin-top: 15px;
    margin-bottom: 14px;
    width: fit-content;

}
.card-actions{
    position: absolute;
    right: 0;
    top: 18px;
    line-height: 0;
    margin-right: 5px;
}
.actionBtn {
    opacity: 1;
    height: 20px;
    line-height: 1;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 0.88rem;
    border-radius: 2px;

}
.ViewClosebtn {
    z-index: 5;
    margin-left: auto;
}
.ImgPreview{
    background-color: hsla(0, 0%, 100%, 0.96);
    z-index: 20000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* pointer-events: none; */
}

.viewImgPreview{
    background-color: hsla(0, 0%, 100%, 0.96);
    z-index: 20000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    /* pointer-events: none; */
}
.container-outer { overflow: scroll; width: 100%; }
.container-inner {
     /* width: 10000px; */
     padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    width: 100%;
    overflow-x: scroll;
}

.E7GkP {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 0;
    height: 100%;
    cursor: default;
}
._19AnP, ._1AhJ7 {
    overflow: visible;
}

._19AnP {
    display: flex;
    align-items: center;
    justify-content: center;
}
._3Obxx {
    position: relative;
}
.H36t4 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
._8Yseo {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
._2kLly, ._1iHeu {
    width: 100%;
    height: 100%;
}
._1iHeu {
    position: relative;
}
._2kLly {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
._2kLly, ._1iHeu {
    width: 100%;
    height: 100%;
}
._1G9lM {
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
._3hYfA {
    width: 100%;
    height: 100%;
    /* -webkit-filter: blur(8px);
    filter: blur(8px); */
}
.closeBtncss{
    color: #fff !important;
    cursor: pointer !important;
    height: 20px !important;
    margin-left: -10px;
    margin-top: 10px;
}

._2iyX0 {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 168px;
    overflow: hidden;
}
._2kLly {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
._2kLly, ._1iHeu {
    width: 100%;
    height: 100%;
}

.uslS5 {
    position: absolute;
    z-index: 10;
    color: var(--inverse);
    font-size: 35px;
    pointer-events: none;
}
.YZNwM {
    color: #555;
    font-weight: 500;
    font-size: 0.94rem;
    line-height: 35px;
    margin-left: -30px;
}
.DotIcon{
    color: #ccc;
    font-size: 15px !important;
}
/* Range Date Picker Css */
/* .Cal__Header__root{
    padding: 0 15px !important;
    min-height: 0 !important;
}
.Cal__Header__date {
    position: relative !important;
}
.Cal__Header__wrapper{
    flex-direction: row !important;
}
.Cal__Header__dateWrapper.Cal__Header__year {
    height: 20px !important; 
    line-height: 27px !important;
    width: fit-content !important;
}
.Cal__Header__dateWrapper.Cal__Header__day {
    height: 24px !important;
    font-size: 17px !important;
    line-height: 25px !important;
    text-transform: capitalize !important;
    width: fit-content !important;
    padding-left: 5px !important;
}
.Cal__Header__wrapper.Cal__Header__blank {
    height: 24px !important;
      line-height: 0;  
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 15px !important; 
}
.Cal__Weekdays__day{
    padding: 0px !important;
}
.Cal__Years__year ol li {
    width: 35px !important;
    height: 35px !important;
} */
.FilterParentDiv ul {
    padding: 0px !important;
}
 
 
.Calendar {
    width: 100% !important;
    min-height: 30.7em !important;
}
.Calendar__sectionWrapper {
    min-height: 20.8em !important;
}
.btnDate{
    background:transparent;
    border: 0;
    /* border-right: 1px solid; */
    cursor: pointer;
}
.Datemrg{
    padding: 6px 6px 0px 6px;
    font-size: 0.625rem;
    color: black;
    /* margin: 10px;
    height: 25px;
    width: 70px; */
}
.activedate{
    color: blue !important;
}
.icondate{
    color: black
}
.iconswap{
    cursor: pointer;
}
.datelabel{
    font-size: 0.625rem;
    font-weight: 600;
    opacity: 0.5;
    line-height: 1;
}
.yearimg{
    height: 20px;
}
.MuiPickersStaticWrapper-staticWrapperRoot{
    min-width: 100% !important;
}
/* Bubble Charts Css */
.BubbleViewParent{
    width: 100%;
    text-align: center !important;
    border-right: 1px solid #decccc !important;
}
.GridDate{
    text-align:center;
  border-right: 1px solid rgb(241, 227, 227);
  border-bottom: 1px solid rgb(241, 227, 227);
  padding:8px 0px !important;
  cursor: pointer;
  
}
.GridDate:hover{
    box-shadow: 0 1px 2px -2px rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.12), 0 5px 12px 4px rgba(0,0,0,.09);
}
.gridclose{
    cursor: pointer;
    color:#fff; 
    font-size: 17px !important;
    width: 2em !important;
    padding-left: 8px;
    margin-top: 6px;
}
.gridclose2{
    cursor: pointer;
    color:#fff;
    /* margin: 4px; */
    font-size: 17px !important;
    width: 2em !important;
    padding-left: 8px;
    margin-top: 6px;
}








/* Drag CSS */
.Showcase__style__stylizedList {
    position: relative;
    z-index: 0;
    /* background-color: #f3f3f3; */
    /* border: 1px solid #efefef; */
    border-radius: 3px;
    outline: none;
}
.Showcase__style__list {
    width: 100%;
    /* height: 100%; */
    /* overflow: auto; */
    -webkit-overflow-scrolling: touch;
    /* border: 1px solid #999; */
}
.Showcase__style__stylizedItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    /* border: 1px solid #efefef; */
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 400;
    /* height: 40px; */
}
.Showcase__style__item {
    position: relative;
    padding: 5px 0px;
    cursor: pointer;
}
.DragBackGround{
    /* background: #ebfaeb !important; */
    border-bottom: 1px solid #ebecf1;
}



/* Drag & Drop CSS */
.CheckBoxLabel{
    font-size: 0.688rem;
}
.DragCheckbox .MuiSvgIcon-root{
    height: 19px !important;    
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #02B152 !important;
}
label + .MuiInput-formControl{
    /* margin-top: 12% !important; */
}
/* .MuiInput-underline:before{
    border-bottom: 2px solid #337ab7 !important;
} */

/* Card View CSS */
.CardViewDiv{
    height: 100%;
    overflow-y: auto;
}
.CardViewGrid{
    width: 100% !important;
    margin: 0 !important;    
}
.CardViewMain{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    border: 1px solid #e4e4e4;
    /* border-left: 3px solid #34495E;
    border-bottom: none; */
    border-radius: 5px;
    background: #fff;
}
.CardViewHeader{
    display: flex;
    margin-bottom: 3px;
    padding: 2px 2px 8px 3px;
    /* background : linear-gradient(0deg,#1e3c72 0,#1e3c72 1%,#2a5298)!important; */
    /* background: #FFF;     */
    width: 100%;
    height: auto;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    /* border-bottom: 1px solid #e6e6e6; */
    /* margin: 0 5px; */
}
.CardViewTitle{
    font-size: 0.75rem;
    font-weight: 600;
    color: #fff;
    width: 100%;
    background: #337ab7;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.CardViewAvatar{
    width: 20%;
    height: 52px;
    /* border-radius: 5px; */
    border: 1px solid #dadada;
    margin: 2% 3%;
}
.CardViewAvatar img{
    width: 100%;
    height: 52px;
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
    /* border: 1px solid #ddd; */
}
.CardTitle{
    background: #337ab7;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 3px 0px 2px 8px;
    flex: 1;
    height: 25px;
    display: flex; 
    margin-top: 3%;
} 
.CardSecTitle{
    display: flex;
    margin: 3px 0;    
}
.CardViewStatus{
    font-size: 10px !important;
    font-weight: 600;
    /* color: #337ab7; */
    height: 20px;
    padding: 2px 8px;
    border-radius: 0px;
    width: -moz-fit-content;
    width: fit-content;
}
.CardViewCreated{
    font-size: 10px !important;
    font-weight: 600;
    /* color: #337ab7; 
    font-style: italic;*/ 
    height: 20px;
    padding: 2px 8px;
    border-radius: 0px;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 10%;
}
.AvatarFirstLetter{
    width: 100%;
    height: 50px; 
    font-size: 24px;
    color: #337ab7;
    background: #f1f1f1; 
    font-weight: 600;
    text-align: center;
    padding: 10% 0%;
}
.CardViewCheckbox{
    margin-left: auto;
    position: relative;
    /* height: 19px; */
    bottom: 4px;
    
}
.ActiveCheckBox{
    margin-left: 4px !important;
}
.CardViewBody{
    width : 100%;
    display: grid;    
    padding: 0% 5% 5% 5%;

}
.CardViewBody span{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.43;
    color: #4a5568;
    /* font-style: italic; */
    margin: 0px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.CardViewBody p{
    font-size: .73rem;
}
.CardViewFooter{
    height: 25px;
    /* background : #fff; */
    border-top: 1px solid #d3d6d6;
    color: #4a5568;
    width: 100%;
    padding-left: 15px;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.CardViewFooter span{
    font-size: 0.75rem;
    font-weight: 600;
    font-style: italic;
}
/* Graph tab */
.graphhead{
    margin: 5px;
    text-align: center;
    background: #4892F9;
    margin-top: 10px;
}
.graphhead span{
    color: #fff;
    font-size: 0.625rem;
    font-weight: 600;
}
.graphtabs{
    margin: 5px;
}
.graphtabs button{
    width: 50%;
    font-size: 0.625rem;
    
    font-weight: 600;
    font-style: italic;
    background: #fff;
    opacity: 0.5;
}
.btnactgrp{
    border-bottom: 3px solid #4892F9;
    background: #eee !important;
    opacity: 1 !important;
}
.graphgrid{
    width: 97% !important;
    margin: 4px !important;
}
.buildtotal{
    text-align: center;
    background: #ef0e7d; 
    padding: 26px 0px !important;
}

.buildtotal h3{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
}
.buildtotal span{
    font-size: 0.75rem;
    font-weight: 600;
    color: #fff;
}
.buildBUA{
    text-align: center;
    background: #096dd9; 
    padding: 5px 0px;
}
.buildBUA h3{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
}
.buildBUA span{
    font-size: 0.88rem;
    font-weight: 600;
    color: #fff;
}
.seglist{
    margin: 0;
    padding: 0px;
    background: #ef79b9;
    border-radius: 5%;
    padding: 2px 6px;
    list-style: none;
}
.seglist li{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
}
.btnAlignpad{
    padding: 8px 8px;
    margin-right: 10px;
}
.marginTop5{
    margin-top: '5px';
}
/* /Popover */
.popover .MuiPopover-paper{
    /* width: 100% !important; */
    max-width: 100%;
}

.FilterSearchIcon{
    font-size: 18px !important;
    color: #555;
    position: absolute;
    top: 10px;
    left: 10px;
}
.ColFilterClearIcon{
    position: absolute;
    display: none !important;

}
.ColFilterSearchIcon{
    position: absolute !important;
    font-size: 18px !important;
    top: 10px;
}

.ColorViolet{
    /* color: #5C69E1; */
}
.DropdownLabelHeader{
    font-weight: 500;
    font-size: 0.75rem;
    /* width: 200px; */
    /* height: 20px; */
    /* padding-left: 12px; */
    /* width: 15% !important; */
    /* min-width: 15% !important; */
    /* max-width: 15% !important; */
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    text-align: left;
    overflow: hidden;
    margin: 0% 1%;
}
.DropdownLabelValues{    
    /* width: 200px;  */
    /* max-width: 200px; */
    /* min-width: 150px; */
    font-size: 0.7rem;
    /* padding-right: 10px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    margin: 0% 1.9%;
}
/* .DropdownLabelValues:hover {
    width: 200px;  
    padding-right: 10px;
    overflow: visible;
} */
.popOverFooter{
    background: #337ab7;
    height: 25px;
    position: relative;
    padding: 2px 4px;
}
.viewMoreLabel{
    cursor: pointer;    
    color: #fff;
    font-size: 0.75rem; 
}
.viewMoreLabel:hover{
    color: #ddd;
    text-decoration: underline;
}
.showingTitle{
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
    margin-right: 10px;
    margin-top: 1px;
    margin-left: 5px;
}
.DropDownIcons{
    cursor: pointer !important;
    font-size: 18px !important;
}
.FilterRefreshIcon{
    color: #fff ;
    font-size: 18px !important;
    margin-left: auto;
    cursor: pointer;
}
.FilterRefreshIcon:hover{
    color: #ccc;
}
.FilterActiveSelected{
    background: #F3F6F9;
    color: #337ab7;
    font-weight: 600; 
    transform: scaleX(1.01);    
    /* box-shadow: inset 0.1875rem 0 0 #8950FC; */
    padding-left: 10px;
    margin-left: -6px;
}
/* Image Upload */
.btnpadding {
    padding: 4px 8px !important;
    margin-right: 5px !important;
}
.Iconstyles{
    color: #337ab7 !important;
    cursor: pointer !important;
    height: 20px !important;
    margin-bottom: 10px;
}

.overlayPreview {
    cursor: pointer;
    height: -moz-fit-content;
    height: fit-content;
    width: 0px;
    overflow-x: hidden;
    position: absolute;
    display: none;
    z-index: 20000;
    top: 0%;
    right: 0;
    background-color: #efefef;
    transition: 0.2s;
    border: 1px solid #ebecf1;
    box-shadow: 0 1px 12px 2px #dbdbdb;
}

/* Filter Items */
.FilterItems{
    width: 100%;
    overflow-y: auto; 
}
 
.FilterItems .FilterItemDivTitle{
    border-bottom: 1px solid #ddd;    
    width: 100%;
    padding: 2%;
    /* color: #3D4977; */
    cursor: pointer;
    font-size: 0.75rem;
    display: grid;
}
.FilterItems .FilterItemDivTitle:hover{
    background: #EFF2F6;
    color: #3D4977;
    border-radius: 5px;
}
.FilterItemCount{
    color: #3D4977;
    height: 50px;
    background: #02B152;
}
.filterSearchInput::-webkit-input-placeholder {
    color: #888;
}
.FilterItemDivSelected{
    margin-right: 6px;
    margin: 3px;
    padding: 0 5px;
    /* background: #EBFAEB; */
    color: #222F3E;
    border-radius: 6px;
    cursor: default;
    display: inline-flex;
    height: auto;
    vertical-align: top;
    position: relative;
    flex-direction: row;    
    pointer-events: all;    
    width: fit-content;
    max-height: 100%;

}
.RoundedDivNumber{
    border-radius: 50%;
    background: cadetblue;
    padding: 2px 4px 0 4px;
    margin-left: 5px;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
}
.RoundedDivName{
    border-radius: 5px;
    background: cadetblue;
    padding: 2px 4px 0 4px;
    margin-left: 5px;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.FilterItemDivSmall{
    font-size: 0.563rem;
}
.FilterItemClose{
    float: right;
}
.FilterItemCloseIcon{
    width: 100%;
    margin-top: 2px !important;
    font-size: 18px !important;
    cursor: pointer;
    color: #ff4d4f;
}
.FiltercloseAlign{ 
    margin-right: 8px;
    float: right;
}
.ViewfontBold{
    font-weight: bold !important;
}

/* Image Preview */
#divDocumentRegistryEntry {
    position: relative;
 }
 ._3JuBL {
    display: flex;
    flex: none;
    align-items: center;
    box-sizing: border-box;
    height: 30px;
    color: white;
    background-color: #337ab7;
}
._1D7Lo {
    display: flex;
    flex: none;
    align-items: center;
    width: 100%;
}

._3JuBL ._3SrqU {
    width: 48px;
    opacity: 1;
}
._3SrqU {
    flex: none;
}
.Iconwhite{
    color: #ffffff !important;
    cursor: pointer !important;
    height: 20px !important;
    margin-left: 3px;
}
.btnpadding4 {
    padding: 6px 4px !important;
    /* margin-right: 5px !important; */
}
.naICS{
    flex: 0 0 130px;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    padding: 24px 20px 0;
    background-color: var(--panel-background-deep);
    justify-content: center;
}
._2_F5S {
    margin-right: 6px;
    padding-bottom: 5px;
    display: flex;
    overflow-x: auto;
}
/*  attachments priview bottom list */
._2K3Eo, ._2_F5S {
    display: flex;
    /* justify-content: center; */
}
._2aa7- {  
    margin-right: 6px;
}
._38JkY {
    position: relative;
    flex: none;
    box-sizing: border-box;
    width: 78px;
    height: 78px;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    display: block;
    transform-origin: 50% 50% 0px;
    transform: scaleX(1) scaleY(1);
}
.TZVqr {
    position: relative;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: transform 75ms ease-out;
 
    background-color: #e6e6e6;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transform: none; 
}

._20A_P {
    display: flex!important;
    flex: 0 0 78px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: 78px;
    height: 78px;
    color: var(--button-alternative);
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 13px;
    letter-spacing: -.2px;
    text-transform: uppercase;
    transition: transform 75ms ease-out;
    padding: 8px;
    background-color: #fff;
}
 ._2-TA4 {
    /* margin-top: 5px; */
    text-align: center;
}
/* image Add */

.ImageAddDiv {
    position: relative;
    height: 100%;
    width: 100%;
    color: #337ab7;
    background: #fff;
    text-align: center;
    transition: box-shadow 0.3s, border-color 0.3s;
    cursor: pointer;
}
.closeImgBtn{
    color: #777;
    position: absolute;
    z-index: 100;
    width: 18px !important;
    right: 5px;
    top: 2px;
    opacity: 1;
}
.ImageAddDiv:hover {
    opacity: 1;
}
.AttachDiv{
    display: flex;
    height: 42px;
    background: #337ab7;
    padding: 1px 8px;
}

.AttachDiv .ListP {
    
    padding-top: 8px;
    font-size: 0.88rem;
    font-weight: bold;
    color: #fff !important;
}

/* No Data Image */
.NoDataImage{
    width: 100%;
    text-align: center;
}
.NoDataImage img{
    height: 150px;
    width: 150px ;
}
.NoDataDesc{
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
    color: #698194;
}
/* Tooltip Css */
.Level2Tooltip {
    /* position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; */
  }
  
  .Level2Tooltip .Level2TooltipText {
    visibility: hidden;
    width: fit-content;
    background-color: #333;
    color: #ddd;
    font-size:10px;
    text-align: center;
    border-radius: 6px;
    padding: 3px; 
    margin: 0 2px;
    position: absolute;
    z-index: 1000 !important;
    top: -2px;
    /* left: 105%; */
  }
  
  .Level2Tooltip:hover .Level2TooltipText {
    visibility: visible;
  }



  /* Toggle button */
.toggle-button-cover
{
    display: table-cell;
    position: relative;
    width: 200px;
    height: 140px;
    box-sizing: border-box;
}

.button-cover
{
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
}

.button-cover:before
{
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 0.75rem;
    line-height: 1;
    padding: 5px;
}

.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button
{
    position: relative;
    top: 50%;
    width: 75px;
    height: 28px;
    margin: 0px auto 0 auto;
    overflow: hidden;
    border: 1px solid #ebecf1;
}

.button.r, .button.r .layer
{
    border-radius: 100px;
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: #fff;
    transition: 0.3s ease all;
    z-index: 1;
}
#button-11
{
    overflow: visible;
}

#button-11 .knobs
{
    perspective: 70px;
}

#button-11 .knobs:before, #button-11 .knobs:after, #button-11 .knobs span
{
    position: absolute;
    top: 4px;
    border-radius: 2px;
}

#button-11 .knobs:before, #button-11 .knobs:after
{
    width: 30px;
    height: 20px;
    color: #444;
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 5px 0px;
}

#button-11 .knobs:before
{
    content: 'List';
    left: 4px;
}

#button-11 .knobs:after
{
    content: 'Card';
    right: 4px;
}

#button-11 .knobs span
{
    right: 1px;
    width: 35px;
    height: 18px;
    background-color: #337ab7;
    transform: rotateY(0);
    transform-origin: 0% 50%;
    transition: 0.6s ease all;
    z-index: 1;
}

#button-11 .checkbox:checked + .knobs span
{
    transform: rotateY(-180deg);
    background-color: #337ab7;
}

/* default card open */

.button-cover, .cardknobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cardknobs
{
    z-index: 2;
}


#button-11 .cardknobs
{
    perspective: 70px;
}

#button-11 .cardknobs:before, #button-11 .cardknobs:after, #button-11 .cardknobs span
{
    position: absolute;
    top: 4px;
    border-radius: 2px;
}

#button-11 .cardknobs:before, #button-11 .cardknobs:after
{
    width: 30px;
    height: 20px;
    color: #444;
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 5px 0px;
}

#button-11 .cardknobs:before
{
    content: 'Card';
    right: 4px;
}

#button-11 .cardknobs:after
{
    content: 'List';
    left: 4px;
}

#button-11 .cardknobs span
{
    right: 1px;
    width: 35px;
    height: 18px;
    background-color: #337ab7;
    transform: rotateY(-180deg);
    transform-origin: 0% 50%;
    transition: 0.6s ease all;
    z-index: 1;
}

#button-11 .checkbox:checked + .cardknobs span
{
    transform: rotateY(0);
    background-color: #337ab7;
}


/* default card exit */

#button-11 .checkbox:checked ~ .layer
{
    /* background-color: #fcebeb; */
}
.Maincontent{
    padding: 1%;
    border-top: 2px solid #337ab7;
    margin: 0.3rem 0rem;
    background: #fff;
    box-shadow: 0px 6px 8px rgba(4, 4, 7, 0.1);
    border: 1px solid #dbe2eb;
    border-radius: 10px;
}
/* .MuiCheckbox-root {
    color: #fff !important
} */

/* .MuiFormControlLabel-root {
    margin-left: -10% !important;
    margin-right: 1% !important;
} */

.MuiTableSortLabel-icon {
    opacity: 1 !important;
}

.LockIcon{
    font-size: 15px !important;
    margin: 0 3%;
    color: #ff4d4f;
}
.Entry-Checkbox{
    /* position: relative;  */
    bottom: 5%;
}

/* Table header font */
.MuiTableSortLabel-root{
    font-size: 12px !important;
}
.MuiTableSortLabel-icon {
    font-size: 12px !important;
}
/* FIle preview Icon */
.FilePreviewIcon-lg{
    font-size: 100px !important;
    position: relative;
    left: 42%;
    top: 30%;
    color: #777 !important;
}

.EntryView-Checkbox {
    position: relative;
    right: 1%;
    top: 46%;
}

/* FIle preview Icon */
.FilePreviewIcon-xs{
    position: relative;
    left: 42%;
    top: 30%;
    color: #777 !important;
}

.FilePreviewIcon-sm{
    font-size: 50px !important;
    margin-top: 1%;
    margin-right: 1%;
    color: #777 !important;
}

/* Common search css */
.searchBar{
    height: 40px !important;
    margin-top: 0px !important;
    width: 100%;
    background-color: #fff;
    border-radius: 5px !important;
    font-size: 0.8rem;
    border: 1px solid #ebecf1;
}
.searchbar1 {

    /* position: fixed; */
    /* top: 10px;
    left: 3%;
    right: 3%; */
    margin-top:-6px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 65px;
    height: 6vh !important;
}

.providerDropdown label {
    font-weight: bold !important;
    color:#000 !important;
    font-size: 12px !important;
    margin-top:-9px !important;
    
    
}

.entryimg img {
    border-radius: 5px;
    width: 60px;
    /* height:16vh; */
}

.entrydes {
    margin-left:10px;
}
.entrydes span {
    font-weight:bold !important;
    font-size:0.75rem !important;
}
.pointer{
    cursor: pointer;
}
.entrybk {
    text-align: center;
}
.entrybk span {
    font-size:11px;
    font-weight: bold;
}
.entrybk p {
    font-size:24px;
    font-weight: bold;
    text-align: center;
}
@media screen and (max-width: 1280px) {
    .entrybk span {
        font-size: 9px;
        font-weight: bold;
    }
  }
.entrycon {
    font-size:12px;
    color:grey;


}
.entryphy {
    font-size: 12px;


}

.con{
    font-size: 0.75rem;
    padding:0px 6px;
    color:grey;

}
.tabphy {
    line-height: 2 !important;
  }
  .tabCon {
    text-transform: capitalize !important;
    font-size: 14px !important;
  }
  .tabqueue {
    margin-left: auto !important;
    font-size: 14px !important;
  }
  
  .tabImage {
    width: 80px;
    height: 70px;
    border-radius: 5px !important;
  }
  .docname {
    font-size: 14px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold !important;
    line-height: 1.334 !important;
    letter-spacing: 0em !important;
}
.docname2{
    font-size: 13px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.334 !important;
}
  @media screen and (max-width: 1280px) {
    .tabImage {
        width: 50px;
        height: 50px;
    }
    .docname {
        font-size: 12px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: bold !important;
        line-height: 1.334 !important;
        letter-spacing: 0em !important;
    }
    .docname2{
        font-size: 12px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1.334 !important;
    }
    .cardbtn {
        height: 4rem !important;
        border-bottom: 1px solid rgb(238, 238, 238) !important;
        justify-content: flex-start !important;
    }
    
  }
  .tabbtn > .MuiButtonBase-root > .MuiButton-label{
    justify-content: normal !important;
  }
  .aligncunt{
    text-align: right;
    width: 20%;
  }
  .tabCon{
      width: 100%;
  }
  .MuiPaper-rounded {
    
    border-radius: 5px !important;
}

.proqueue {
    font-size: 16px !important;
    color:#337ab7 !important;
}



.quecnt {
    margin-left:70px !important;
}
.conqueue {
    font-size: 20px;
}

.patientDetails {
    border-bottom: 1px solid;
    width: fit-content;
    font-size: 15px;
    font-weight: bold;
    margin-left: 20px !important; 
}
.modalHeaderDetails {
    border-bottom: 1px solid;
    width: fit-content;
    font-size: 15px;
    font-weight: bold;
    margin-left: 15px !important; 
    margin-bottom: 0px !important;
}

.Register {
    font-size: 12px !important;
    border-bottom: 1px solid !important;
    width: fit-content !important;
    color: #251E8F;
}
.dialogTitle {
    font-weight: bold !important;
    color:rgb(103,149,237) !important;
    font-size: 1rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    margin: 0
}

.consultim{
    margin: 0;
    font-size: 10px;
    margin-top: 5px !important;
    font-weight: 600;
}
 
.consultDetails {
    border-bottom: 1px solid !important;
    width: fit-content !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-top: 4% !important;
}
.patientValue {
    font-weight: bold !important;
    margin-left: 7px !important;
    color: #251E8F;
    font-size: 12px;
}
.patientKey {
    margin-top: 6px !important;
}
.patientKey1 {
    margin-top: 13px !important;
}

.ListP{
    font-weight: bold;
    color: #4da7fc;
    font-size: 14px;
}