@import './scroll.css';
@import './select.css';
@import './tablePagination.css';
@import './printLandscape.css';
/* @import './HomePage.css'; */
@import './Slide.css';
@import './Image.css';
@import './Menu.css';
@import './Document.css';
@import './Button.css';
@import './Themes.css';
@import './Level2Styles.css';
/* @import './menuNew.scss'; */
@import './Level4.css';
@import './L2DropDown.css';
@import './UserRightsTable.css';
@import './TopAccordian.css';
@import './SpreedSheet.css';
@import './EditVwAutoSlide.css';
@import './calendar.css';
@import './userPermission.css';

/* @import './../..//pages/HomePage/assets/css/smartSafari.css'; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Roboto",sans-serif !important; 
  background: #ebeef1 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 ul li { display: flex;justify-content: space-around; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiInputLabel-shrink{
  color: #337ab7 !important;
}

.passwordStyle{
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
.TextWeight400{
  color: #33234E;
  font-size: 13px;
  font-weight: 600;
  user-select: none;
}


.carousel{
  height:79vh
}
.MuiTypography-body1 {
  font-size: 14px !important; 
  font-family: "Roboto", sans-serif !important;
} 

  .MuiPaper-rounded{
    border-radius : 0px !important; 
  }
  
  .MuiAccordion-rounded{
    /* border-bottom: 1px solid #ebecf1; */
    box-shadow: none !important;

  }
  .MuiBox-root {
    margin: 0px 8px !important; 
  }
/* .MuiPaper-elevation1 {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14) !important;
} */
  .styles_simpleReactWeather__3uy4l {
    color: #000 !important;
    padding:15px 0px !important;
    text-align: left !important;
  }
  .styles_simpleReactWeatherTemp__2PnUn {
    color:#000 !important;
    font-weight: 400 !important;
  }
  .styles_simpleReactWeatherIcon__3sqXV {
    margin-bottom: 10px !important;
  }
  .styles_simpleReactWeatherCity__zyECF {
    font-weight: 400 !important;
  }

  /* .MuiPaper-elevation1{
    box-shadow: none !important;
  } */
  .rbc-event-content{
    text-align: center;
  }
  #viewoverlay{
    cursor: pointer;
    height: 100%;
    width: 0;
    margin-top: 47px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: whitesmoke;
    overflow-x: hidden;
    transition: 0.5s;
}

.MuiDialog-paper .MuiGrid-item {
  padding: 0 15px !important;
}

 .MuiDialog-paper .viewModal .MuiGrid-item {
  padding: 0px !important;
}

.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.reasonForReassign_call_center .errorinput {
  border-bottom: 2px solid #337ab7 !important;
}

.dropdownMargin{
  margin-top: 8px;
}
.Checkboxmargin{
  margin-top: 15px !important;
}
/* Asset side menu */
.sideMenuIcon{
  margin-left: 7px;
  width: 20px !important;
  color: #fff;
  }
.Main{
  cursor: pointer;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 98;
  top:52px;
  right: 41px;
  background: hsla(0,0%,51%,.5);
  color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  }

.tabClose{
  top: 48px;
  background: #34ace0!important;
  color: #fff;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  position: absolute;
  }
.child{
  position: absolute;
  background-color: #f5f5f5;
  right: 0;
  }
.tabHead{
background-color: #214F77 !important ;
color: #FFF; 
}
.slideHeading {
  color: #fff;
  }
.tabBody{ 
  padding: 12px;
  /* margin-top: 30px; */
  /* padding-bottom: 10%; */

  }
.helpdeskSide{
width: 4%;
}
.removebtn{
  margin-top:'-20 !important';
  margin-left:'8px';
}

/* Page 404 Css */
.c{
  text-align: center;
  display: block;
  position: relative;
  width:80%;
  margin: auto;
}
._404{
  font-size: 5rem;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 240px;
  padding-top: 120px;
  letter-spacing: 15px;
}
._1{
  text-align: center;
    display: block;
    position: relative;
    letter-spacing: 12px;
    font-size: 1.5em;
    line-height: 80%;
    /* padding-top: 20%; */
}
._1::first-letter {
  font-size: 2.5rem;
}
._2{
  text-align:center;
  display:block;
  position: relative;
  font-size: 1.25rem;
  padding: 13px 0px;
  color: #7a827f;
}
.text{
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}


.btn{
  background-color: rgb( 255, 255, 255 );
  position: relative;
  display: inline-block;
  width: fit-content;
  padding: 5px;
  z-index: 5;
  font-size: 25px;
  margin:0 auto;
  color:#33cc99;
  text-decoration: none;
  margin-right: 10px;
  border-radius: 5px;
}
.right{
  float:right;
  width:60%;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #5f5d5de0;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
  position: absolute;
  padding: 3px 8px;
  bottom: -2.5em;
  /* left: 4.5rem;
  white-space: nowrap;
  /* box-shadow: 1px 1px 3px #222222; */
  opacity: 0;
  /* border: 1px solid #111111; */
  z-index: 99999;
  line-height: 1.5;
  visibility: hidden;
  border-radius: 3px;
}
[data-title] {
  position: relative;
}
 
h3 {
  font-size: 1.825em;
  margin: 20px 0 10px;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal!important;
} 
h6 {
  font-size: .8em;
  text-transform: uppercase;
  font-weight: 500;
} 
h1 {
  line-height: 1.15em;
}
.displayflex{
  display: flex;
}
/* consult registry */
.display-sm-hide{
  display: none ;
}
.display-sm-show{
  display: block;
}
.with-100 {
width: 100%;
}
 
@media only screen and (min-width: 600px) {
  .display-sm-hide{
    display: block;
  }
  .display-sm-show{
    display: none;
  }
 
}
  
.Calendar__sectionWrapper{
  min-height: 250px !important;
}

.height88{
  height: 88vh;
}
.height71{
  min-height: 71vh;
}

/* Call Center  */
.cardbtn{
    height: 5rem;
    border-bottom: 1px solid rgb(238, 238, 238) !important;
    justify-content: flex-start !important;
}
.cardTab {
  border-radius: 5px !important;
  box-shadow: 0px 6px 8px rgba(4, 4, 7, 0.1) !important;
  border: 1px solid #eee;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
}
.callApplyButton {
  background: #337ab7 !important;
  margin-left: 10px !important;
  padding: 0px !important;
  height: 25px;
  border: 1px solid #fff !important;
  margin-top: 2px !important;
}
.AccHtext{
  color: #6495ED;
  font-weight: bold
}
@media screen and (max-width: 1280px) {
  .AccHtext{
    color: #6495ED;
    font-weight: bold;
    font-size: 12px;
  
  }
}
.flexEnd{
  display: flex;
    align-items: flex-end;
}

div.MuiModal-root:has(> div[style*="opacity: 0"]) {
  pointer-events: none;
}

/* datepicker */
.datePickerItemError .MuiInput-underline::before {
  border-bottom: 2px solid red !important;
}

#reasonForMeeting, #reasonForReassign {
  border-left: 1px solid #337ab7;
  border-right: 1px solid #337ab7;
  border-bottom: 1px solid #337ab7;
  padding-top: 5px;
  min-height: 95px;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 900px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 7%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.MuiDialog-paper .MuiGrid-item.tooltipPassRules {
  padding: 0px !important;
}

.tooltipPassRules .tooltipbottom .tooltiptext {
  bottom: 51%;

  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

}

.tooltip-bottom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ccc;
  color: #006080;
}

.tooltip-bottom:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-bottom .tooltiptext {
  visibility: hidden;
  position: absolute;
  top: 51px;
  right: 0px;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.change-password .tooltiptext {
  right: -266px;
}

.tooltiptext-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 95%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.change-password .tooltiptext-bottom::after {
  left: 6%;
}

.tooltip-bottom:hover .tooltiptext {
  visibility: visible;
}