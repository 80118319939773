.AddBtndiv{
    display: flex;
}
.MuiButton-startIcon {
    margin-right: 4px !important;
    margin-bottom: 2px;
}
.BrowseBtn{
    padding: 7px 12px !important;
}
.borderstyle {
    padding: 0% 1% !important;
    border: 1px solid #ebecf1;
    margin: 0.5% 0% 0.5% 0% !important
}
.font8rem{
    font-size: 0.8rem !important;
}
/* Close Button Icon */
.CloseBtnCommon{ 
    background: #337ab7 !important;
    color: #fff !important;
    min-width: 30px !important;
    width: 30px;
    height: 30px;
    margin: 0px 6px!important;
    float: left;
    padding: 0!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    border: 1px solid #337ab7!important;
}

.CloseBtnIcon{
    left: 4px;
    height: 20px!important;
    width: 20px!important;
    margin-right: 0!important;
    top: 0.04rem !important;
}
.CloseBtndiv{
    position: absolute;
    top: -25px;
    right: 15px;
}
.GenBtnCommon{
    width: 120px !important;
}
/* Save Button Icon */
.SaveBtnCommon{
    background: #337ab7 !important;
    color: white !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
/*browser button*/
.browsebtnCommon{
    background: #337ab7 !important;
    width: 120px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
.ApplyButton{
    padding: 5px !important;
    background: brown !important;
}
/* Add Button Icon */
.AddBtnCommon{
    background: #337ab7 !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
.L1AddBtnCommon{
    background: #337ab7 !important;
    color: white !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
}
.L2AddBtnCommon{
    background: #337ab7 !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 10px !important;
    margin-left: 2% !important;
}
.AddBtnIcon{
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}

.PrintBtnIcon{ 
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}
.SubmitBtnIcon{
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}
.add-btn-outer-div{
    display: flex;
}
.add-export-btn{
    position: relative; 
    top: 20px;
    left: 86%;
}


/* New Styles */
.custButtons{
    background: #34ace0 !important;
    padding: 5px 10px !important;
}
.ViewHeadpTag{
    color: black;
    line-height: 1.5;
}
p{
    margin: 0px !important;
}
.ViewHeadpTag span{
    float: right;
    padding-right: 3px;
}
.ViewHeadpTagB{
        width: 80%;
}
.ViewBodypTag{
    /* font-weight: bold; */
    color: black;
   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;      /* IE */
}
.viewgridpad{
    padding: 1% 0px !important;
}
.ListViewHead{
    font-size: 0.625rem;
    color: #2F2725;
}
.ListViewDiv{
    padding-top: 14px;
}
.ListViewBody{
    font-size: 0.563rem;
    font-style: italic !important;
    color: black;
}
.ViewHightLight{
    font-size: 0.75rem;
    font-weight: 100;
    padding-left: 5%;
}
.ViewHightLightBlue{
    color: #34ace0 !important;
    font-weight: bold;
}
.ViewBodyForeignTag{    
    color: orange;
    cursor: pointer;
}
.ViewBodyForeignTag:hover{
    text-decoration: underline;
}
.ViewHeadingTitle{    
    background: #fff !important;
    color: #337ab7 !important;
    font-size: 13px !important; /*0.8rem*/
    /* border-radius: 5px !important; */
    padding: 0px;
    padding-left: 0px;
    margin-top: 15px;
    margin-bottom: 14px;
    font-weight: 600;
    /* border-bottom: 1px solid #ebecf1; */
}
.GrpViewHeadingTitle{
    background: #337ab7 !important;
    color: #fff !important; 
    border-radius: 5px !important;
    padding: 3px 10px;
    margin-top: -10px;
    margin-bottom: 14px;
    font-size: 0.75rem !important;
    font-weight: bold;
}
.GrpViewHeadingTitle p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.cardheadvh{
    height: 0px;
}
.ViewHeading{
    font-weight: bold;
}
.ViewHeading span{
    float: right;
}


.ViewTableCardBody{
    padding: 2px !important;
}
.ExportBtndiv{
    position: relative;
    top: 5px;
    left: 5px;
 
}
.GridViewChild{
    height:54vh;
    overflow-y:hidden;
    overflow-x: hidden;
    background: #eee;
    border-left: 1px solid #ccc; 
}
.GridViewChild:hover{
    overflow-y:auto !important;
}
.GridViewChildCard{
    /* height:calc(100vh - 170px); */
    overflow-y:auto;
    overflow-x: hidden;
}
.GridViewHeader{
    height: 32px;
    background: #EDEDED;
    margin-bottom: 2px;
    background: #337ab7;
    border-left: 1px solid #fff;
    /* display:flex */
}
.BubbleViewChild{
    text-align: center;
    border-left: 1px solid #e3e4e9;
    padding-left: 5px;
}
.inputborder{
    padding: 0px 11px
}
.borderInp{
    border: 1px solid #fff !important;
    margin: 6px 2px !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    line-height: 15px !important;
    background: #c4cbf5 !important;
}
::placeholder { /* Internet Explorer 10-11 */
    color: #fff;
   }
.GridViewHeaderTitle{
    padding-top: 6px;
    padding-left: 10px !important;
    /* padding: 2px; */
    font-size: 0.75rem;
    color: #fff;
    width: 80%;
}
.GridViewCard{
    width: 70% !important;
    height: 70% !important;
    text-align: center;
    margin: 15px 0px 15px 10px !important;
}
.LeftBorderBlue{
    border: 1px solid #ddd  ;
    border-left: 4px solid #337ab7 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; 
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    cursor: pointer;
    background: #fff;
    margin: 3px 5px;
}
.LeftBorderGreen{
    border: 1px solid #ddd  ;
    border-left: 4px solid #337ab7 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; 
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: grid;
    cursor: pointer;
    margin: 3px 5px;
}
.LeftBorderBlue p,h6{
    padding-left: 20px;
}
/* .TableCommonSearch{
    height: 10px !important;
} */
.homeButton{
    color: #A9A9A9;
    margin-top: 16px;
    cursor: pointer !important;
    margin-left: 5px;
}
.ViewCard{
    color: #fff !important;
} 
/*.RoundedInput::-webkit-input-placeholder { 
    padding-left: 10px;
    font-size: 0.625rem;
    color: #e4e4e4 !important;
 } */

 /* Pagination CSS */
 .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a {
    display: block;
    font-size: 0.75rem;
    margin: -9px -13px;
    padding: 3px 12px;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 0.625rem;
    padding: 0px !important;
    font-size: 10px !important;
    height: 20px;
}
.ant-pagination{
    margin-top: 5px !important;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;    
    width: 50%;
    font-size: 0.625rem;
    margin-right: 0px !important;
    padding: 0px 2px !important;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
}
.ant-pagination{
    font-size: 10px !important;
}
.ant-pagination-prev{
    margin-right: 0 !important;
} 
.ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 0px;
}
.ant-dropdown-trigger{
    font-size: 12px !important;
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.65);
}
.ant-select-item-option-content{
    font-size: 0.625rem;
}
.ant-select-selector{
    margin-top: 4px;
}
.ant-select-selection-item{
    font-size: 0.75rem;
}

.GridViewDiv{
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    border-bottom: none;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
}
.gridactive{
    background: #666666
}
#GridViewCard{
    width: 100%;
    padding: 0px 10px;
    transition: 0.5s;
    height: 60vh;
}
#GridViewCardChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s; 
    border-right: 1px solid #e0d3d3;
}
#GridViewCardSubChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s; 
}
.MoreDataDiv{
    height: 30px;
    width: 100%;
    /* background: #ddd; */
    color: blue;
    cursor: pointer;
    padding-top: 5px;
    padding-left: 5px;
}
.MoreDataDiv p {
    text-decoration: underline;
}
/* .BubbleViewChart{
} */
div.tooltip {	
    display:inline-block;
    position:relative;
    text-align:left;
    margin:12px 0; 
    min-width:200px;
    max-width:400px;
    top:-20px;
    left:50%;
    transform:translate(-30%,-100%);
    padding:10px 20px;
    color:black;
    background-color:#fff;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);  
    display:block;
} 
.svg-p{
    width: 40px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    border: 1px solid #ddd;
    margin: 0;
}
.svg-html{
    background-color: transparent;
} 
.svg-body{
    color: #fff;
    background: transparent !important;
    width: auto !important;
}
ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    /* background-color: #eee; */
  }
  
  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
    font-size: 0.75rem;
  }
  
  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  
/* Add a color to all links inside the list */
ul.breadcrumb li  {
    color: #0275d8;
    text-decoration: none;
}
ul.breadcrumb span{
    font-size: 0.75rem;
    font-weight: bold;
}  
#ShareViewCard{
    width: 100%;
    padding: 0px 10px;
    transition: 0.5s;
}
#ShareViewCardChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s;
}
#ShareViewCardChild{
    width: 0;
    display: none;
    padding: 0px 10px;
    transition: 0.5s;
}
#ProjectRegistryTree{
    transition: 0.5s;
    position: relative;
    width: 100%;
}
.CustomizeDiv{
    width: 50%;
    padding: 8px;
    border-radius: 5px;
    background:linear-gradient(45deg,#ff4757,#ff7675) !important;
    color: #fff;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
    display: flex;
}
.CustomizeIcon{
    font-size: 18px !important;
    margin-right: 8px;
}
.ApplyDiv{
    width: 50%;
    padding: 8px;
    border-radius: 5px;
    background: linear-gradient(45deg,#00b894,#55efc4) !important;
    color: #fff;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
    display: flex;
}
.CustomizeDiv:hover
.ApplyDiv:hover{
    box-shadow: 0 14px 26px -12px rgba(2, 177, 82, 0.42), 0 4px 23px 0px rgba(2, 177, 82, 0.12), 0 8px 10px -5px rgba(2, 177, 82, 0.2);
}
.ClearButton{
    background: #ef4b4b !important;
    color: white !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    margin-right: 0% !important;
}
@media screen and (max-width:1280px) {
    .ClearButton{
        width: 65px;
    }
}
.FilterSave{
    background: #fff !important;
    color: #0275d8 !important;
}

#BubbleViewChild{
    width: 0%;
    display: none;
}
#BubbleViewSubChild{
    width: 0%;
    display: none;
}

/* Draggable */
.drag-checkbox{
    margin-left: 15px !important;
}
.DragColumnAccordian{
    background-color: #ffffff !important;
}
.floating .item {
    background-color: #ffffff !important;
    box-shadow: 0 4px 20px #666666 !important;
}

.dl-item.floating {
    width: 85%;
}
.table-row-active{
    background-color: red !important; 
    color: #fff !important;
}
.MuiAccordionSummary-root {
    min-height: 30px !important;
}
.MuiAccordionSummary-content {
    margin: 0 !important;
}
.MuiPaginationItem-root {
    color: #666666 !important;
    height: 30px !important;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 12px !important;
    min-width: 22px !important;
    border-radius: 3px !important;
}
.MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff !important;
    background-color: #337ab7 !important;
    height: 24px !important;
}
.MuiPaginationItem-ellipsis {
    height: auto !important;
}
/* .MuiSelect-select.MuiSelect-select {
    padding-right: 23px !important;
    padding-bottom: 0px !important;
    padding-top: 1px !important;
} */
.per-page{    
    margin-top: 5px !important;
    margin-left: 5px !important;
    font-size: 0.75rem;
}
.AppointmentBtnIcon{
    left: 0px;
    height: 15px !important;
    width: 15px !important;
}

/*   My Styles */

.L1AppointmentBtnCommon{
    background: #191970 !important;
    width:40%;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    margin-left: 2% !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}
.L1AppointmentSaveBtnCommon {
    background: #191970 !important;
    height: 30px !important;
    margin-top: 0px !important; 
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: bold; 
    text-transform: capitalize !important; 
    text-align: center !important;
}
.width100{
    width: 100% !important;
}
.width135px{
    width: 135px;
}
/* .ClearButton{
    background: #ef4b4b !important;
    width: 95px;
    height: 30px;
    margin-top: 0px !important;
    float: left;
    padding: 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    top:100% !important;
    margin-left:10px !important;
    margin-right: 0% !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
} */

.sweetAlertBtn {
    box-shadow: none !important;
    color: white !important;
    cursor: pointer;
    font-size: 12px !important;
    text-transform: uppercase;
    padding: 12px 30px !important;
    font-weight: 400 !important;
    margin-top: 10px !important;
}

.sweetAlertBtnSuccess {
    background-color: #4caf50 !important;
    margin-right: 100px !important;
    margin-left: 0px !important;
}

.sweetAlertBtnSuccessInfo {
    background-color: #4caf50 !important;
    margin-right: 8px !important;
    margin-left: 0px !important;
}

.sweetAlertBtnCancel {
    background-color: #f44336 !important;
}

.SaveBtnCommon, .ClearButton {
    min-width: 104px !important;
    margin-left: 15px !important;
}

.disabledCheckboxBlue .MuiFormControlLabel-label.Mui-disabled {
    color: #337ab7 !important;
}

.disabledCheckboxBlue .Mui-disabled {
    opacity: 1 !important;
}

.CloseBtnCommon {
    padding-left: 8px !important;
}

.VerifyBtn {
    margin-left: 0px !important;
}

.Mui-disabled {
    opacity: 0.5;
}