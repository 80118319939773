.tableCard { 
  transition: 0.3s;
  display: grid;
  margin: 0px;
  z-index: 2;
  height: auto;
  width: 100%;
  padding: 0px;
  overflow: auto;
}

.tableCardDiagnosis { /*tableCardL1*/
  height: 70vh;
  padding-top: 0.5%;
}

.AccDetails{
  position: relative !important;
  z-index: 1 !important;
}

.tableHeaderItems{      
  padding-top: 4px;
}
