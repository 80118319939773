#ulanimate{
    position: relative;
    animation: changeBg 10s infinite; 
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;     
}

@keyframes changeBg{
    0% ,from { opacity: 1;left:0px
         }
    100%,to {opacity: 0;left:350px}
 } 

 .ant-steps-vertical > .ant-steps-item .ant-steps-item-content{
    min-height: 44px !important;
 }

 .ant-steps-item-title{
     font-size: 14px !important;
 }

 .loginicon{
   margin-left: 6%;
 }

 .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #33234E !important;
 }