.browsebtnCommon{
    padding: 6px 5px 5px 14px !important;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #FFF;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    /* padding: 12px 30px; */
    position: relative;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400; 
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
    background-color: #999;
}
.AddRowBtn{
    padding: 6px 5px 5px 14px !important;
    padding: 0.40625rem 1.25rem;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgb(53 113 147 / 14%), 0 3px 1px -2px rgb(53 113 147 / 20%), 0 1px 5px 0 rgb(53 113 147 / 12%);
    background-color: rgb(52, 179, 68) !important;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    position: relative;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
}
.ClearRowBtn{
    padding: 6px 5px 5px 14px !important;
    padding: 0.40625rem 1.25rem;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgb(53 113 147 / 14%), 0 3px 1px -2px rgb(53 113 147 / 20%), 0 1px 5px 0 rgb(53 113 147 / 12%);
    background-color: #ef4b4b !important;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    position: relative;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
}
/* SpreedSheet */
.spreadSheetheader{
    font-family: sans-serif;
    font-weight: 500;
    color: #337ab7 !important;
    font-weight: 600;
}
.Spreadsheet__cell, .Spreadsheet__header {
    min-width: 4em !important;
}