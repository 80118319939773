
/*

Created by Matej Kovac
http://moodydev.io

*/

/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #dadce0;
  --bg-color: #F6F6F6;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

wbody {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
}

header {
  display: block;
  width: 100%;
  /* padding: 1.75em 0; */
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}
.daynames{
    color: #70757a;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: center;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.wcol-center {
  justify-content: center;
  text-align: center;
  /* border-right: 1px solid var(--border-color); */
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


.odcol {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 15%;
    text-align: center;
  }
  
.odcol-start {
    justify-content: flex-start;
    text-align: left;
  }

.odcol-end {
    justify-content: flex-end;
    text-align: right;
  }

/* Calendar */

.wcalendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.wcalendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.wcalendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.wcalendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.wcalendar .header .icon:first-of-type {
  margin-left: 1em;
}

.wcalendar .header .icon:last-of-type {
  margin-right: 1em;
}

.wcalendar .wdays {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  /* padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color); */
}

.wcalendar .wbody .wcell {
  position: relative;
  height: 2.5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}


.wcalendar .wbody .weecell {
  position: relative;
  height: 2.5em;
  /* border-right: 1px solid var(--border-color); */
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}


.wcalendar .wbody .wcell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.wcalendar .wbody .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.wcalendar .wbody .row {
  border-bottom: 1px solid var(--border-color);
}

.wcalendar .wbody .row:last-child {
  border-bottom: none;
}

.wcalendar .wbody .wcell:last-child {
  border-right: none;
}

.wcalendar .wbody .wcell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  /* right: 0.75em; */
  font-weight: 700;
}

.wcalendar .wbody .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.wcalendar .wbody .wcell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.wcalendar .wbody .wcell:hover .bg,
.wcalendar .wbody .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.wcalendar .wbody .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.dcalendar .dbody .dcol {
  flex-grow: 0;
  flex-basis: calc(100%);
  width: calc(100%);
}
/* manual css  */

.dayhours{
    width: 6%;
    /* height: 100%; */
    display: block;
    text-align: right;
    position: absolute;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    padding-top: 3rem
}
.rowpadd {
  padding: 1.1em 0.5rem 0rem 0.54rem;
}
.wnumber{
  font-size: 12px;
  font-weight: 400  
}

.dselectdt{
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 3px 8px;
    margin: auto;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    color: #185abc;
    background-color: #d2e3fc;
    margin-top: -10px;
    margin-left: 10px;
}