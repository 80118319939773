.font12{
    font-family: sans-serif !important;
    font-size: 12px !important;
}
.tableLabelHeader{
    font-weight: 500;
    font-size: 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    text-align: left;
    overflow: hidden;
    margin: 0.2rem 1%;
}
.tableItemDivHeader {
    width: 100%;
    padding: 6px 0px;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    /* border-bottom: 1px solid #e6e6e6; */
    font-size: 12px;
    font-weight: 500;
    background: #367AB7;
    color: #fff;
}
.MenutablemDivHeader {
    width: 100%;
    padding: 6px 0px 6px 2.9rem;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    /* border-bottom: 1px solid #e6e6e6; */
    font-size: 12px;
    font-weight: 500;
    background: #367AB7;
    color: #fff;
}
.tableItemDiv {
    width: 100%;
    /* padding: 6px 12px; */
    cursor: pointer;
    font-size: 12px;
    cursor: pointer;
}
tableItemDivChild {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    cursor: pointer;
}
.tableArrowIcons {
    cursor: pointer;
    color: #777;
    margin: 1px 5px !important;
}

.FilterTableDivBody {
    padding: 0 0 0 0px;
    height: 52vh;
    border: 1px solid #e3e4e9;
}
.userDivBody {
    padding: 0 0 0 0px;
    height: 69vh;
    border: 1px solid #e3e4e9;
}
.AccondianTable{
    box-shadow: 0 0px 5px rgba(32,33,36,.28) !important;
    border-bottom: 0px !important;
}

.tabelListDiv {
    background: #fff;
    /* box-shadow: 0 4px 6px rgba(32,33,36,.28); */
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 4px;
    overflow: hidden;
}
.tableLabelValues {
    /* width: 200px; */
    /* max-width: 200px; */
    /* min-width: 150px; */
    font-size: 0.75rem;
    /* padding-right: 10px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    margin: 0;
}
.tableLabelSecVal {
    /* width: 200px; */
    /* max-width: 200px; */
    /* min-width: 150px; */
    font-size: 0.75rem;
    /* padding-right: 10px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    margin: 0% 1% 0% 5%;
}
.imgviewpage{
    height: 120px !important;
    padding: 5px !important;
    margin: 5px;
    box-shadow: 0 4px 6px rgba(32,33,36,.28) !important;
    cursor: pointer;
}
.cusorPointer{
    cursor: pointer;
}
.lastImg{
    display: flex;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.imgtotCount{
    position: absolute;
    left: 36%;
    top: 39%;
    font-size: 17px;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
}

.Imgbgmstyle{
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.previewFile{
    color: rgb(119, 119, 119);
    width: 100% !important;
    height: 30% !important;
    margin-top: 1em;
}
.homeActive{
    color: #227093;
}

.tableItembtn {
    width: 100%;
    padding: 5px 0 5px 5%;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    font-size: 12px;
    font-weight: 500;
    background: #367AB7	;
    color: #fff;
    margin-bottom: 0.50rem;
    border-radius: 4px;
    cursor: pointer;

}


.tableItemtab {
    width: 100%;
    padding: 5px 0 5px 5%;
    box-shadow: 0 4px 6px rgba(32,33,36,.28);
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    color: #000;
}

.legendcheck span{
    padding: 1px 1px 0px 6px!important;
}

.savediv{
    margin-left: auto;
}
.rightsheader{
    display: flex;
    padding: 5px 5px;
}
.searchbarDiv {
    height: 33px;
    margin-top: 2px;
    width: 100%;
    background-color: #fff;
    /* border-radius: 30px; */
    /* padding: 10px; */
    font-size: 0.8rem;
    /* box-shadow: 0 4px 6px rgba(32,33,36,.28); */
    border-radius: 4px;
    display: flex;
    border: 1px solid #e3e4e9;
    line-height: 2;

}
/* .isearch {
    color: #777;
    padding: 3px 0px 0px 4px;
} */
.s_input {
    color: black;
    border: 0;
    outline: 0;
    background: none;
    /* line-height: 10px; */
    padding: 2px 10px 0 3px;
    width: 100%;
}
.tableDivGroup {
    width: 100%;
    padding: 1% 1%;
    /* box-shadow: 0 2px 2px rgba(101, 102, 107, 0.28); */
    border-bottom: 1px solid #e3e4e9;
    font-size: 12px;
    font-weight: 500;
    /* background: #367AB7; */
    /* color: #fff; */
}
.uSearchicon{
    width: 15px !important;
    color: #777;
    margin: 2px 2px 0px 8px;

}
/* Ripple effect */
.btnripple {
    background-position: center;
    transition: background 0.8s;
  }
  .btnripple:hover {
    background: #367AB7 radial-gradient(circle,transparent 1%,rgba(80,80,80,.05) 0) 50%/15000%;
    /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;*/
  }
  .btnripple:active {
    background-color: #fff;
    background-size: 100%;
    color: #777;
    transition: background 0s;
    user-select: none;
  }
  .tableItemDivHeader span {
      padding: 0px !important;
  }
  .MenutablemDivHeader span {
    padding: 0px !important;
}
  .Level2Tooltip span {
    padding: 0px !important;
  }
  .custTabActive{
    background: #367AB7 !important;
    color: #fff !important;
  }
.groupActive{
    background: #e4e6e9;
}
.divdisable{
    position: absolute;
    width: 100%;
    /* background: #ccc; */
    height: 85%;
    z-index: 1;
    cursor: not-allowed;
}
.UserRightsNoData{
    margin-top: 5rem;
}
.icondata{
    width: 30%;
    color: #367AB7;
    padding: 1%;
    cursor: pointer;
}
/* user Data permission */
.datadropdiv{
    padding: 0px 0;
    height: 100%;
    border: 1px solid #e3e4e9;
    margin: 10px;
}