.top-acc-title{
    background: #283C4F !important;
    color: #fff !important;
    padding-left: 2% !important;    
}
.top-acc-title p{
    text-decoration: underline;
    font-weight: 600;
}
.top-acc-body{
    /* background: #34495e !important;
    color: #fff !important; */
}
.top-acc-card{
    display: flex;
    overflow: hidden;
    width: 100%;
    background-image: linear-gradient(#283C4F, #4B6482);    
}
.top-acc-card-body{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    /* border-left: 3px solid #34495E; */
    border-bottom: none;
    border-radius: 0; 
    /* width: 250px; */    
}
.top-acc-card-body-items{
    width : 100%;
    display: grid;    
    padding: 0% 5% 3% 5%;
}
.top-acc-card-body-items p{
    font-size: 0.688rem;
    font-weight: 700;
}
.width25{
    width: 25%;
}
.acc-viewHeadpTag{
    font-size: 0.625rem;
    color: #fff;
}
.acc-viewValuepTag{
    font-size: 0.625rem !important; 
    color: #fff;
    font-style: italic;
    margin-left: 6px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
/* icons */
.acc-forward-icon{
    position: absolute;
    top: 45%;
    color: #fff;
    left: 4px;
    cursor: pointer;
}
.acc-back-icon{
    position: absolute;
    top: 45%;
    color: #fff;
    right: 0px;
    cursor: pointer;
}
.acc-heading{
    padding-top: 5px;
    font-size: 0.88rem;
    font-weight: bold;
    color: #fff !important;
}